import React from 'react';
import { Provider, connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import store, { history } from './store';
import client from './api/client';
import Layout from './layouts/Layout';
import './App.scss';
import FormRateCollect from './scenes/FormRateCollect/FormRateCollect';
import FormFeeCollect from './scenes/FormFeeCollect/FormFeeCollect';
import CollectionList from './scenes/CollectionList/CollectionList';
import CollectionEdit from './scenes/CollectionEdit/CollectionEdit';
import FormRateEdit from './scenes/FormRateEdit/FormRateEdit';
import FormFeeEdit from './scenes/FormFeeEdit/FormFeeEdit';
import CryptoFormEdit from './scenes/CryptoFormEdit/CryptoFormEdit';
import CryptoFormCollect from './scenes/CryptoFormCollect/CryptoFormCollect';
import UsersList from './scenes/UsersList/UsersList';
import Page404 from './components/Page404';
import ErrorHandler from './ErrorHandler';

import authSlice from './store/auth/slice';
import PrivateRoute from './components/PrivateRoute';
import Login from './scenes/Login/Login';
import UserEdit from './scenes/UserEdit/UserEdit';

const Routing = connect(
  ({ [authSlice.name]: { user } }) => ({
    user,
  }),
  {
    logout: authSlice.actions.logout,
  },
)(({ user, logout }) => (
  <Layout user={user} logout={logout}>
    <ErrorHandler>
      <Switch>
        <Route
          path="/collect-rates/:formSlug"
          component={FormRateCollect}
          exact
        />
        <Route
          path="/collect-fees/:formSlug"
          component={FormFeeCollect}
          exact
        />
        <Route
          path="/collect-crypto/:formSlug"
          component={CryptoFormCollect}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections"
          component={CollectionList}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/new"
          component={CollectionEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/:collectionId"
          component={CollectionEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/:collectionId/clone"
          component={CollectionEdit}
          componentProps={{ clone: true }}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/:collectionId/new-rates-form"
          component={FormRateEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/rates-forms/:formId"
          component={FormRateEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/:collectionId/new-fees-form"
          component={FormFeeEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/fees-forms/:formId"
          component={FormFeeEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/collections/:collectionId/new-crypto-form"
          component={CryptoFormEdit}
          exact
        />
        <PrivateRoute
          auth={user}
          path="/crypto-forms/:formId"
          component={CryptoFormEdit}
          exact
        />
        {user && user.role === 'SUPER_ADMIN' ? (
          <Route path="/users">
            <Switch>
              <Route path="/users" component={UsersList} exact />
              <Route path="/users/new" component={UserEdit} exact />
              <Route
                auth={user}
                path="/users/:userId"
                component={UserEdit}
                exact
              />
            </Switch>
          </Route>
        ) : null}
        {!user ? (
          <Route path="/" component={Login} exact />
        ) : (
          <Redirect to="/collections" />
        )}
        <Route path="*" component={Page404} />
      </Switch>
    </ErrorHandler>
  </Layout>
));

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ApolloProvider client={client}>
          <Routing />
        </ApolloProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
