import React from 'react';
import { Popup } from 'semantic-ui-react';

export interface ListToStringProps {
  list: Array<any>;
  mappedName?: string;
}

export default function ListToString({
  list,
  mappedName,
}: ListToStringProps): JSX.Element {
  const arr = mappedName ? list.map((c: any) => c[mappedName]) : list;
  return arr.length > 1 ? (
    <Popup
      content={arr.join(', ')}
      trigger={<span>{arr[0]}, ...</span>}
      position="bottom left"
    />
  ) : (
    arr.length === 1 && arr[0]
  );
}
