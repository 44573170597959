import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { FormType } from '../types/enums/FormType';
import { enumToSelectOptions } from '../utils/components';

interface FormTypeSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof FormType | (keyof typeof FormType)[];
}

const displayName = 'FormTypeSelect';
const options = enumToSelectOptions(FormType);
export default Object.assign(
  React.memo((props: FormTypeSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
