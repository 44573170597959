import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import trim from 'lodash/trim';
import LocationSearchInput from '../../../components/LocationSearchInput';
import './SubmissionForm.scss';
import ImageAttachments from '../../../components/ImageAttachments';
import { enumToSelectOptions } from '../../../utils/components';
import AccountTypeSelect from '../../../components/AccountTypeSelect';
import { FeeSubmissionCollectionType } from '../../../types/enums/FeeSubmissionCollectionType';
import OnlineUrls from '../../../components/OnlineUrls';

const collectionTypesOptions = enumToSelectOptions(FeeSubmissionCollectionType);

export default class SubmissionForm extends React.PureComponent {
  static defaultProps = {
    editable: true,
  };

  render() {
    const {
      researcherName,
      clientType,
      submission,
      editable,
      downloadUrl,
      uploadImage,
      deleteImage,
      images,
      validationErrors,
    } = this.props;

    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Researcher Name</label>
            <div className="submission_form__researcher_name_value">
              {researcherName}
            </div>
          </Form.Field>
          <AccountTypeSelect
            label="Account Type"
            name="accountType"
            clientType={clientType}
            value={submission.accountType}
            onChange={this.onChange}
            required
            open={!editable ? false : undefined}
            error={validationErrors.accountType}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field required error={validationErrors.locationAddress}>
            <label>Researcher Location (closest city)</label>
            <LocationSearchInput
              name="locationAddress"
              value={submission.locationAddress}
              onChange={this.onChangeLocation}
              required
              readOnly={!editable}
            />
            {validationErrors.locationAddress && (
              <Label
                pointing="above"
                content={validationErrors.locationAddress}
                role="alert"
                prompt
              />
            )}
          </Form.Field>
          <Form.Input
            label="Account Name"
            name="accountName"
            value={submission.accountName}
            onChange={this.onChange}
            onBlur={this.onChangeWithTrim}
            readOnly={!editable}
            error={validationErrors.accountName}
          />
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field>
            <Form.Group widths="equal">
              <Form.Select
                label="Collection Type"
                options={collectionTypesOptions}
                name="collectionType"
                value={submission.collectionType}
                onChange={this.onChange}
                required
                open={!editable ? false : undefined}
                error={validationErrors.collectionType}
              />
            </Form.Group>
            {submission.collectionType ===
              FeeSubmissionCollectionType.ONLINE && (
              <Form.Group widths="equal">
                <Form.Field>
                  <OnlineUrls
                    urls={submission.onlineUrls}
                    setUrls={this.setUrls}
                    readOnly={!editable}
                    validationErrors={validationErrors.onlineUrlsErrors}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Form.Field>
          <Form.Field>
            <label>Screenshots of the fee information</label>
            <ImageAttachments
              images={images}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              downloadUrl={downloadUrl}
              readOnly={!editable}
            />
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  onChangeWithTrim = (event) => {
    const { name, value } = event.target;
    this.props.updateSubmission({ [name]: trim(value) });
  };

  onChange = (event, inputProps) => {
    const { name, value } = inputProps;
    this.props.updateSubmission({ [name]: value });
  };

  onChangeLocation = (event, inputProps) => {
    const { name, value, lat, lng } = inputProps;
    this.props.updateSubmission({
      [name]: value,
      locationLat: lat,
      locationLng: lng,
    });
  };

  setUrls = (func) => {
    const onlineUrls = func(this.props.submission.onlineUrls);
    this.props.updateSubmission({ onlineUrls });
  };
}
