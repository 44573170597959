import React, { useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import FeeValueTypeSelect from '../../../components/FeeValueTypeSelect';
import { FeeValue } from '../../../types/models/FeeValue';
import FeeFixedValueItem from './FeeFixedValueItem';
import FeePercentageValueItem from './FeePercentageValueItem';
import { FeeValueType } from '../../../types/enums/FeeValueType';
import { FeeFixedValue } from '../../../types/models/FeeFixedValue';
import { FeePercentageValue } from '../../../types/models/FeePercentageValue';
import { ValidationErrors } from '../../../types/models/ValidationErrors';

interface FeeValueItemProps {
  value: FeeValue;
  onChange: (id: FeeValue['id'], change: Partial<FeeValue>) => void;
  onDelete: (id: FeeValue['id']) => void;
  readOnly?: boolean;
  feeTypes?: FeeValueType[];
  validationErrors?: ValidationErrors;
}

export default function FeeValueItem({
  value,
  onChange,
  onDelete,
  readOnly = false,
  feeTypes,
  validationErrors = {},
}: FeeValueItemProps) {
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value: inputValue } = inputProps;
      onChange(value.id, { [name]: inputValue });
    },
    [value.id, onChange],
  );

  const onClickDelete = useCallback(() => {
    onDelete(value.id);
  }, [value.id, onDelete]);

  const valueProps = {
    validationErrors,
    readOnly,
    onChange: onChangeField,
  };
  return (
    <div className="crypto_form_collect__fee_value_container">
      <div className="crypto_form_collect__fee_value_type">
        <FeeValueTypeSelect
          options={feeTypes}
          name="type"
          value={value.type}
          label="Type"
          onChange={onChangeField}
          readOnly={readOnly}
        />
      </div>
      {value.type === FeeValueType.FIXED && (
        <FeeFixedValueItem {...valueProps} value={value as FeeFixedValue} />
      )}
      {value.type === FeeValueType.PERCENTAGE && (
        <FeePercentageValueItem
          {...valueProps}
          value={value as FeePercentageValue}
        />
      )}
      <div className="crypto_form_collect__fee_value_controls">
        {!readOnly && (
          <Button icon="trash" onClick={onClickDelete} type="button" />
        )}
      </div>
    </div>
  );
}
