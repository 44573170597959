import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { PayoutMethod } from '../types/enums/PayoutMethod';
import { enumToSelectOptions } from '../utils/components';

interface PayoutMethodSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof PayoutMethod | (keyof typeof PayoutMethod)[];
}

const options = enumToSelectOptions(PayoutMethod);

export default React.memo((props: PayoutMethodSelectProps) => (
  <Form.Select options={options} {...props} />
));
