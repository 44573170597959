import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import { gql, useLazyQuery } from '@apollo/client';

const FIELDS_FRAGMENT = gql`
  fragment OrganizationSelectFields on Organization {
    id
    name
    homeCountryId
  }
`;

export const GET_SELECTED = gql`
  query OrganizationSelectSelected($id: Int) {
    selected: organization(where: { id: $id }) {
      ...OrganizationSelectFields
    }
  }
  ${FIELDS_FRAGMENT}
`;

export const GET_OPTIONS = gql`
  query OrganizationSelectOptions($limit: Int, $search: String = "") {
    options: organizations(
      take: $limit
      where: { name: { contains: $search, mode: insensitive } }
      orderBy: [{ id: desc }]
    ) {
      ...OrganizationSelectFields
    }
  }
  ${FIELDS_FRAGMENT}
`;

const displayName = 'OrganizationSelect';
export default Object.assign(
  React.memo((props) => {
    const { value, readOnly, onChange, ...restProps } = props;
    const [search, setSearch] = useState(null);
    const [fetchSelected, { data: selectedData, loading: selectedLoading }] =
      useLazyQuery(GET_SELECTED, { fetchPolicy: 'cache-first' });
    const [fetchOptions, { data: optionsData, loading: optionsLoading }] =
      useLazyQuery(GET_OPTIONS, { fetchPolicy: 'cache-first' });

    const options = useMemo(() => {
      const fetchedOptions = optionsData ? optionsData.options : [];
      const selectedOption = selectedData ? selectedData.selected : undefined;
      const options = fetchedOptions.map((o) => ({
        key: o.id,
        value: o.id,
        text: `#${o.id} - ${o.name}`,
        homecountryid: o.homeCountryId,
      }));
      if (selectedOption && !find(options, { value: selectedOption.id })) {
        options.push({
          key: selectedOption.id,
          value: selectedOption.id,
          text: `#${selectedOption.id} - ${selectedOption.name}`,
          homecountryid: selectedOption.homeCountryId,
        });
      }
      return options;
    }, [selectedData, optionsData]);

    const onChangeWithHomeCountry = useCallback(
      (event, inputProps) => {
        const { value, options } = inputProps;
        const newInputProps = { ...inputProps };
        const homeCountryIds = options
          .filter((o) => value === o.value)
          .map((o) => o.homecountryid);
        // we need a value which use in all selected options
        newInputProps.homeCountryId =
          homeCountryIds.length === 1 ? homeCountryIds[0] : null;
        onChange(event, newInputProps);
      },
      [onChange],
    );

    const delayedQuery = useCallback(
      debounce((search) => fetchOptions({ variables: { search } }), 500),
      [],
    );

    useEffect(() => {
      if (search !== null) {
        delayedQuery(search);
      }
    }, [search, delayedQuery]);

    useEffect(() => {
      if (value && isFinite(value)) {
        fetchSelected({ variables: { id: value } });
        setSearch('');
      }
    }, [value, fetchSelected, setSearch]);

    return (
      <Form.Select
        options={options}
        loading={selectedLoading || optionsLoading}
        value={value}
        onOpen={(e) => setSearch('')}
        search={!readOnly}
        searchQuery={search || ''}
        onSearchChange={(e) => setSearch(e.target.value)}
        placeholder="Start to write name..."
        open={readOnly ? false : undefined}
        onChange={onChangeWithHomeCountry}
        {...restProps}
      />
    );
  }),
  { displayName },
);
