import React, { useCallback, useEffect, useState } from 'react';
import { Button, Header, Icon, List } from 'semantic-ui-react';
import FileUploader from 'src/components/FileUploader';
import '../../../components/ImageAttachments.scss';

const FileAttachment = ({ file, onRemoveFile }) => (
  <List.Item key={file.name}>
    <span>{file.name}</span>
    <Button
      type="button"
      className="remove"
      title="Remove form"
      data-qa="remove"
      icon
      onClick={() => onRemoveFile(file)}
    >
      <Icon name="trash" />
    </Button>
  </List.Item>
);

const FileAttachments = ({ files, onRemoveFile }) =>
  files &&
  files.length > 0 && (
    <>
      <Header size="tiny">Will be uploaded:</Header>
      <List className='image-attachments'>
        {files.map((file) => (
          <FileAttachment
            key={file.name}
            file={file}
            onRemoveFile={onRemoveFile}
          />
        ))}
      </List>
    </>
  );

const UploadedAttachments = ({ files, downloadUrl }) =>
  files &&
  files.length > 0 && (
    <>
      {false && <Header size="tiny">Uploaded Attachments:</Header>}
      <List className='image-attachments'>
        {files.map((file) => (
          <List.Item key={file}>
            {downloadUrl && (
              <a
                href={downloadUrl.replace('{filename}', file)}
                target="_blank"
                rel="nofollow noreferrer"
                title="Click to see this attachment"
              >
                {file}
              </a>
            )}
            {!downloadUrl && file}
          </List.Item>
        ))}
      </List>
    </>
  );

export default function ImageAttachments({
  images,
  uploadImage,
  readOnly = false,
  downloadUrl = undefined,
  setImages
}) {
  const extensions = ['png', 'jpg', 'jpeg'];
  const [uploadingImages, setUploadingImages] = useState([]);

  const handleFiles = useCallback(
    (files) => {
      const images = [...files].filter((file) =>
        extensions.includes(file.name.split('.').pop().toLowerCase()),
      );
      setUploadingImages((prev) => [...prev, ...images]);
    },
    [setUploadingImages, extensions, uploadImage],
  );

  const onRemoveFile = useCallback((file) => {
    const newUploadingImages = uploadingImages.filter((f) => f.name !== file.name);
    setUploadingImages(newUploadingImages);
  }, [uploadingImages]);

  useEffect(() => {
    if (uploadingImages.length > 0) {
      setImages(uploadingImages);
    }
  }, [uploadingImages, setImages]);

  if (readOnly) {
    return <UploadedAttachments files={images} downloadUrl={downloadUrl} />;
  }

  return (
    <>
      <FileAttachments files={uploadingImages} onRemoveFile={onRemoveFile} />
      <FileUploader
        accept=".png,.jpg,.jpeg"
        multiple
        handleFiles={handleFiles}
        data-qa="attach-images"
      >
        Attach Image(s)
      </FileUploader>
    </>
  );
}
