import { gql } from '@apollo/client';

const FORM_FEE_FIELDS = gql`
  fragment FormFeeEditFields on FormFee {
    id
    slug
    countryId
    collectionId
    organizationId
    researcherName
    clientType
    submittedAt
    transferDirection
    organization {
      homeCountryId
    }
  }
`;

export const GET_FORM = gql`
  query GetFormFee($formId: Int) {
    form: formFee(where: { id: $formId }) {
      ...FormFeeEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;

export const CREATE_FORM = gql`
  mutation CreateFormFee(
    $slug: String!
    $countryId: Int
    $collectionId: Int!
    $organizationId: Int!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
  ) {
    form: createFormFee(
      data: {
        slug: $slug
        countryId: $countryId
        collection: { connect: { id: $collectionId } }
        organizationId: $organizationId
        researcherName: $researcherName
        clientType: $clientType
        transferDirection: $transferDirection
      }
    ) {
      ...FormFeeEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;

export const UPDATE_FORM = gql`
  mutation UpdateFormFee(
    $id: Int!
    $slug: String!
    $countryId: Int
    $collectionId: Int!
    $organizationId: Int!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
  ) {
    form: updateFormFee(
      data: {
        slug: { set: $slug }
        countryId: { set: $countryId }
        collection: { connect: { id: $collectionId } }
        organizationId: { set: $organizationId }
        researcherName: { set: $researcherName }
        clientType: { set: $clientType }
        transferDirection: { set: $transferDirection }
      }
      where: { id: $id }
    ) {
      ...FormFeeEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;
