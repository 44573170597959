import { gql, useQuery } from '@apollo/client';
import { ALL_OTHERS_ID, ALL_OTHERS_LABEL } from '../constants/countryGroups';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

const GET_COUNTRIES = gql`
  query CountrySelectOptions {
    countries {
      id
      name
      iso2Code
      iso3Code
      defaultCurrencyId
    }
    countryGroups {
      id
      name
      defaultCurrencyId
    }
  }
`;

type CountryItem = {
  id: number;
  label: string;
  defaultCurrencyId?: number;
};

type UseCountriesOptions = {
  withGroups?: boolean;
  labelField?: 'name' | 'iso2Code' | 'iso3Code';
};

export default function useCountries({
  withGroups = false,
  labelField = 'name',
}: UseCountriesOptions = {}) {
  const { data, loading } = useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
  });
  const countries = useMemo<CountryItem[]>(() => {
    if (!data) {
      return [];
    }
    let countries: CountryItem[] = [];
    if (withGroups) {
      countries.push({
        id: ALL_OTHERS_ID,
        label: ALL_OTHERS_LABEL,
      });
      countries = countries.concat(
        sortBy(
          data.countryGroups.map((o: any) => ({
            id: -o.id,
            label: o.name,
            defaultCurrencyId: o.defaultCurrencyId,
          })),
          'label',
        ),
      );
    }
    countries = countries.concat(
      sortBy(
        data.countries.map((o: any) => ({
          id: o.id,
          label: o[labelField],
          defaultCurrencyId: o.defaultCurrencyId,
        })),
        'label',
      ),
    );
    return countries;
  }, [data, withGroups, labelField]);

  return { countries, loading };
}
