import React, { useCallback } from 'react';
import { Button, Form, Table } from 'semantic-ui-react';
import set from 'lodash/set';
import { createOnlineUrl, OnlineUrl } from '../types/models/OnlineUrl';
import { SetState } from '../types/utility';
import { ValidationErrors } from '../types/models/ValidationErrors';

interface OnlineUrlsProps {
  urls: OnlineUrl[];
  setUrls: SetState<OnlineUrl>;
  readOnly?: boolean;
  validationErrors?: ValidationErrors;
}

export default function OnlineUrls({
  urls,
  setUrls,
  readOnly = false,
  validationErrors = {},
}: OnlineUrlsProps) {
  const onAddUrl = useCallback(() => {
    const feeValue = createOnlineUrl();
    setUrls((prev) => [...prev, feeValue]);
  }, [setUrls]);
  const onDeleteUrl = useCallback(
    (event, { value }) => {
      setUrls((prev) => {
        const urls = [...prev];
        urls.splice(value, 1);
        if (urls.length === 0) {
          urls.push(createOnlineUrl());
        }
        return urls;
      });
    },
    [setUrls],
  );
  const onChange = useCallback(
    (event, { name, value }) => {
      setUrls((prev) => set([...prev], name, value));
    },
    [setUrls],
  );
  return (
    <Table className="form_fee_collect__online_urls_container">
      <Table.Header>
        <Table.Row>
          <Table.Cell>
            <Form.Field
              label="Link to online fee doc"
              data-qa="link-to-doc"
              required
            />
          </Table.Cell>
          <Table.Cell>
            <Form.Field label="Notes" />
          </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {urls &&
          urls.map((url, index) => (
            <OnlineUrlRow
              key={index}
              index={index}
              url={url}
              validationErrors={
                validationErrors[index] as ValidationErrors | undefined
              }
              readOnly={readOnly}
              onChange={onChange}
              onDeleteUrl={onDeleteUrl}
            />
          ))}
      </Table.Body>
      {!readOnly && urls.length < 10 && (
        <Table.Footer>
          <Table.Row>
            <Table.Cell>
              <div>
                <a
                  className="form_fee_collect__add_online_url"
                  onClick={onAddUrl}
                >
                  + Add additional link
                </a>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}

interface OnlineUrlRowProps {
  index: number;
  url: OnlineUrl;
  readOnly?: boolean;
  validationErrors?: ValidationErrors;
  onChange: (event: any, inputProps: any) => void;
  onDeleteUrl: (event: any, inputProps: any) => void;
}

function OnlineUrlRow({
  index,
  url,
  readOnly = false,
  validationErrors = {},
  onChange,
  onDeleteUrl,
}: OnlineUrlRowProps) {
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <Form.Input
          name={`${index}.url`}
          value={url.url}
          readOnly={readOnly}
          onChange={onChange}
          required
          error={validationErrors.url}
        />
      </Table.Cell>
      <Table.Cell>
        <Form.Input
          name={`${index}.note`}
          value={url.note}
          maxLength="256"
          readOnly={readOnly}
          onChange={onChange}
          error={validationErrors.note}
        />
      </Table.Cell>
      {!readOnly && (
        <Table.Cell>
          <Button
            type="button"
            icon="trash"
            value={`${index}`}
            onClick={onDeleteUrl}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
}
