import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import { PaymentMethod } from '../enums/PaymentMethod';
import { PayoutMethod } from '../enums/PayoutMethod';
import { TransferMethod } from '../enums/TransferMethod';
import { TransactionType } from '../enums/TransactionType';
import { CustomerType } from '../enums/CustomerType';
import { WithNullable } from '../utility';
import { ValidationErrors } from './ValidationErrors';

export interface CryptoBuySellCorridorConfiguration {
  countryId: number;
  currencyFromIds: number[];
  currencyToIds: number[];
  paymentMethods: (keyof typeof PaymentMethod)[];
  payoutMethods: (keyof typeof PayoutMethod)[];
  paymentDetails: string;
  payoutDetails: string;
  customerType: keyof typeof CustomerType;
  transactionType: keyof typeof TransactionType;
}

export type StateCryptoBuySellCorridorConfiguration = WithNullable<
  CryptoBuySellCorridorConfiguration,
  'countryId' | 'transactionType'
>;

export const configurationFields: Array<
  keyof CryptoBuySellCorridorConfiguration
> = [
  'countryId',
  'currencyFromIds',
  'currencyToIds',
  'paymentMethods',
  'payoutMethods',
  'customerType',
  'transactionType',
  'paymentDetails',
  'payoutDetails',
];

export function validateCryptoBuySellCorridorConfiguration(
  state: StateCryptoBuySellCorridorConfiguration,
  existsConfigurations: CryptoBuySellCorridorConfiguration[],
): ValidationErrors {
  const errors: ValidationErrors =
    validateCryptoBuySellCorridorConfigurationRequiredFields(state);
  if (!isEmpty(errors)) {
    return errors;
  }
  const config = state as CryptoBuySellCorridorConfiguration;
  if (includesSameConfiguration(existsConfigurations, config)) {
    errors.sameConfiguration =
      'These field are overlapping in another table - please use unique field combination';
    configurationFields.forEach((fieldName) => {
      errors[fieldName] = 'Overlapping field';
    });
  }

  if (hasIntersect(config.currencyFromIds, config.currencyToIds)) {
    errors['currencyFromIds'] = 'Should not match Sell Currency';
    errors['currencyToIds'] = 'Should not match Buy Currency';
  }
  return errors;
}

export function validateCryptoBuySellCorridorConfigurationRequiredFields(
  config: StateCryptoBuySellCorridorConfiguration,
): ValidationErrors {
  const errors: ValidationErrors = {};
  const requiredFields = [...configurationFields];

  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName of requiredFields) {
    const fieldValue = config[fieldName];
    if (fieldValue === undefined || fieldValue === null) {
      errors[fieldName] = 'Please fill in this field';
    } else if (
      fieldValue &&
      typeof fieldValue === 'object' &&
      fieldValue.length === 0
    ) {
      errors[fieldName] = 'Please choose options for this field';
    }
  }
  return errors;
}

export function findSameConfigurations(
  existsConfigurations: CryptoBuySellCorridorConfiguration[],
  requiredConfig: CryptoBuySellCorridorConfiguration,
  excludeFields: Array<keyof CryptoBuySellCorridorConfiguration> = [],
): CryptoBuySellCorridorConfiguration[] {
  return existsConfigurations.filter((c) =>
    isSameConfiguration(c, requiredConfig, excludeFields),
  );
}

export function includesSameConfiguration(
  existsConfigurations: CryptoBuySellCorridorConfiguration[],
  requiredConfig: CryptoBuySellCorridorConfiguration,
  excludeFields: Array<keyof CryptoBuySellCorridorConfiguration> = [],
): boolean {
  return existsConfigurations.some((c) =>
    isSameConfiguration(c, requiredConfig, excludeFields),
  );
}

export function isSameConfiguration(
  a: CryptoBuySellCorridorConfiguration,
  b: CryptoBuySellCorridorConfiguration,
  excludeFields: Array<keyof CryptoBuySellCorridorConfiguration> = [],
): boolean {
  const fields = difference(configurationFields, excludeFields);
  return fields.every(
    (fieldName) =>
      a[fieldName] === b[fieldName] ||
      (Array.isArray(a[fieldName]) &&
        hasIntersect(
          a[fieldName] as Array<string | number>,
          b[fieldName] as Array<string | number>,
        )),
  );
}

export function hasIntersect(
  a: ArrayLike<string | number>,
  b: ArrayLike<string | number>,
): boolean {
  return intersection(a, b).length > 0;
}
