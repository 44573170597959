import isEmpty from 'lodash/isEmpty';
import { FeeCorridorConfiguration } from './FeeCorridorConfiguration';
import { FeeRange, validateFeeRange } from './FeeRange';
import { ValidationErrors } from './ValidationErrors';
import { toNumber } from '../../utils/number';

export interface FeeCorridor extends FeeCorridorConfiguration {
  id: number;
  ranges: FeeRange[];
}

export interface CreateFeeCorridorParams extends FeeCorridorConfiguration {
  ranges?: FeeRange[];
}

let ID_SEQUENCE = 0;
export function createFeeCorridor({
  ranges = [],
  ...params
}: CreateFeeCorridorParams): FeeCorridor {
  ID_SEQUENCE -= 1;
  return {
    ...params,
    ranges,
    id: ID_SEQUENCE,
  };
}

const maxRangeGap = 0.01;

export function validateFeeCorridor(data: FeeCorridor): ValidationErrors {
  const errors: ValidationErrors = {};
  const rangesErrors: ValidationErrors = {};
  const sortedRanges = data.ranges
    .slice()
    .sort((a, b) => Number(a.amountFrom) - Number(b.amountFrom));
  sortedRanges.forEach((range, index) => {
    const err = validateFeeRange(range, data.amountCurrencyId);
    const rangeHaveOverlapping = sortedRanges.some(
      (r, i) => i !== index && haveOverlappingRanges(range, r),
    );
    if (rangeHaveOverlapping) {
      err.overlapping =
        'Amount Ranges cannot intersect - You need to change Amount From or Amount To';
    }
    const nextRange = sortedRanges[index + 1];
    const rangeHaveBigGap =
      nextRange && haveBigGapBetweenRanges(range, nextRange);
    if (rangeHaveBigGap) {
      err.bigGap = `The gap between amount rages must ${maxRangeGap}. For example (Amount From 0, Amount To 100), (Amount From 100.𝟎𝟏, Amount To: 200).`;
    }
    if (!isEmpty(err)) {
      rangesErrors[index] = err;
    }
  });
  if (!isEmpty(rangesErrors)) {
    errors.rangesErrors = rangesErrors;
  }
  return errors;
}

function haveOverlappingRanges(a: FeeRange, b: FeeRange): boolean {
  const a1 = toNumber(a.amountFrom, 0);
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  const b2 = toNumber(b.amountTo, Infinity);
  if (b1 <= a1 && a1 <= b2) {
    return true;
  }
  if (b1 <= a2 && a2 <= b2) {
    return true;
  }
  return false;
}

function haveBigGapBetweenRanges(a: FeeRange, b: FeeRange) {
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  return b1 - a2 > maxRangeGap + maxRangeGap / 10 ** 6;
}
