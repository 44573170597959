import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { ClientType } from '../types/enums/ClientType';
import { enumToSelectOptions } from '../utils/components';

interface ClientTypeSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof ClientType | (keyof typeof ClientType)[];
}

const displayName = 'ClientTypeSelect';
const options = enumToSelectOptions(ClientType);
export default Object.assign(
  React.memo((props: ClientTypeSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
