import React from 'react';

export default function Page404(props) {
  return (
    <div className="ui grid middle aligned">
      <div className="ui column center aligned">
        <div className="ui statistic">
          <div className="value" data-qa="error-value">
            404
          </div>
          <div className="label" data-qa="error-label">
            Error
          </div>
        </div>

        <div className="ui message">
          <div className="header">Description</div>
          <p>Not found</p>
        </div>
      </div>
    </div>
  );
}
