export enum IndividualAccountType {
  'Standard Consumer Account' = 'Standard Consumer Account',
  'Premier Consumer Account' = 'Premier Consumer Account',
  'Standard Multi-currency Account' = 'Standard Multi-currency Account',
  'Premier Multi-currency Account' = 'Premier Multi-currency Account',
}

export enum BusinessAccountType {
  'Standard Business Account' = 'Standard Business Account',
  'Premier Business Account' = 'Premier Business Account',
  'Business Multi-currency Account' = 'Business Multi-currency Account',
}

export const AccountType = { ...IndividualAccountType, ...BusinessAccountType };
export type AccountType = IndividualAccountType | BusinessAccountType;
