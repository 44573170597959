import React, { useCallback } from 'react';
import { Form } from 'semantic-ui-react';

export default function DecimalInput(props) {
  const { onChange, ...inputProps } = props;
  const onChangeAmount = useCallback(
    (event) => {
      // eslint-disable-next-line prefer-const
      let { value, name } = event.target;
      const match = value.match(/([0-9]+(\.[0-9]*)?)/);
      value = match ? match[0] : '';
      onChange(event, { name, value });
    },
    [onChange],
  );

  return <Form.Input {...inputProps} onChange={onChangeAmount} />;
}

DecimalInput.defaultProps = {
  onChange: () => {},
};
