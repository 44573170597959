import isEmpty from 'lodash/isEmpty';
import { OnlineUrl, validateOnlineUrl } from './OnlineUrl';
import { ValidationErrors } from './ValidationErrors';
import { FeeSubmissionCollectionType } from '../enums/FeeSubmissionCollectionType';

export interface CryptoFormSubmission {
  locationAddress: string;
  collectionType: string;
  accountType: string;
  accountName: string;
  onlineUrls: OnlineUrl[];
}

export function validateCryptoFormSubmission(
  data: CryptoFormSubmission,
): ValidationErrors {
  const errors: ValidationErrors = {};
  const requiredFields: Array<keyof CryptoFormSubmission> = [
    'locationAddress',
    'collectionType',
    'accountType',
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName of requiredFields) {
    if (!data[fieldName]) {
      errors[fieldName] = 'Please fill in this field';
    }
  }
  if (data.collectionType === FeeSubmissionCollectionType.ONLINE) {
    if (data.onlineUrls.length === 0) {
      errors.onlineUrls = 'There should be one or more links';
    }
    const onlineUrlsErrors: ValidationErrors = {};
    data.onlineUrls.forEach((data, index) => {
      const err = validateOnlineUrl(data);
      if (!isEmpty(err)) {
        onlineUrlsErrors[index] = err;
      }
    });
    if (!isEmpty(onlineUrlsErrors)) {
      errors.onlineUrlsErrors = onlineUrlsErrors;
    }
  }
  return errors;
}
