import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import CryptoBuySellCorridorConfigurationForm from './CryptoBuySellCorridorConfigurationForm';
import {
  CryptoBuySellCorridorConfiguration,
  StateCryptoBuySellCorridorConfiguration,
  validateCryptoBuySellCorridorConfiguration,
} from '../../../../types/models/CryptoBuySellCorridorConfiguration';
import {
  CryptoBuySellCorridor,
  getDefaultFeeCurrencyId,
} from '../../../../types/models/CryptoBuySellCorridor';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import { TransactionType } from 'src/types/enums/TransactionType';
import { CryptoBuySellRange } from 'src/types/models/CryptoBuySellRange';
import { FeeValueType } from 'src/types/enums/FeeValueType';

interface CryptoBuySellCorridorEditFormProps {
  corridor: CryptoBuySellCorridor;
  existsConfigurations?: CryptoBuySellCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (
    corridorId: number,
    config: CryptoBuySellCorridorConfiguration,
  ) => void;
}

export default function CryptoBuySellCorridorEditForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: CryptoBuySellCorridorEditFormProps): JSX.Element {
  const [state, setState] =
    useState<StateCryptoBuySellCorridorConfiguration>(corridor);
  const validationErrors = useMemo(
    () =>
      validateCryptoBuySellCorridorConfiguration(
        state as CryptoBuySellCorridorConfiguration,
        existsConfigurations,
      ),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev: any) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    const defaultFeeCurrencyId = getDefaultFeeCurrencyId(
      state as CryptoBuySellCorridorConfiguration,
    );
    const isExchangeTrade =
      state.transactionType === TransactionType.EXCHANGE_TRADE;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedRanges = state.ranges.map((range: CryptoBuySellRange) => ({
      ...range,
      fees: range.fees.map((fee) => ({
        ...(fee.type === FeeValueType.FIXED && isExchangeTrade
          ? {
              ...fee,
              type: FeeValueType.PERCENTAGE,
              value: '',
            }
          : fee),
        currencyId: defaultFeeCurrencyId,
      })),
    }));
    const updatedState = { ...state, ...{ ranges: updatedRanges } };
    setState(updatedState);

    onSubmit(corridor.id, updatedState as CryptoBuySellCorridorConfiguration);
    onClose();
  }, [onSubmit, onClose, state, corridor.id]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Edit Table Settings" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <CryptoBuySellCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
                data-qa="save-settings"
              >
                Save Settings
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
