import { useCallback } from 'react';
import findIndex from 'lodash/findIndex';
import { ListItem, SetState } from '../types/utility';

export default function useUpdate<T extends ListItem>(setState: SetState<T>) {
  return useCallback(
    (id: T['id'], changed: Partial<Omit<T, 'id'>>) => {
      setState((prevState: ListItem[]) => {
        const index = findIndex(prevState, { id });
        const fee = prevState[index];
        const updated = { ...fee, ...changed };
        return [
          ...prevState.slice(0, index),
          updated,
          ...prevState.slice(index + 1),
        ] as T[];
      });
    },
    [setState],
  );
}
