import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { PaymentMethod } from '../types/enums/PaymentMethod';
import { enumToSelectOptions } from '../utils/components';

interface PaymentMethodSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof PaymentMethod | (keyof typeof PaymentMethod)[];
}

const options = enumToSelectOptions(PaymentMethod);

export default React.memo((props: PaymentMethodSelectProps) => (
  <Form.Select options={options} {...props} />
));
