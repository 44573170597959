import { FeeFixedValue } from './FeeFixedValue';
import { FeePercentageValue } from './FeePercentageValue';
import { FeeValueType } from '../enums/FeeValueType';
import { ValidationErrors } from './ValidationErrors';
import { toNumber } from '../../utils/number';

export type FeeValue = FeeFixedValue | FeePercentageValue;

let FEE_VALUE_ID_SEQUENCE = 0;
export function createFeeValue(
  data: Partial<FeeValue>,
): FeeValue {
  const type: keyof typeof FeeValueType = data.type || FeeValueType.FIXED
  FEE_VALUE_ID_SEQUENCE -= 1;
  return {
    value: '0',
    priority: 0,
    minValue: '',
    maxValue: '',
    ...data,
    type,
    id: FEE_VALUE_ID_SEQUENCE,
  } as FeeValue;
}

export function validateFeeValue(
  data: FeeValue,
  canHaveZeroValue = true,
  amountCurrencyId?: number,
  maxFixedValue?: number,
): ValidationErrors {
  const value = toNumber(data.value, 0);
  const errors: ValidationErrors = {};
  if (!canHaveZeroValue && value === 0) {
    errors.value = 'The value cannot be zero';
  }
  if (data.type === FeeValueType.FIXED) {
    if (!data.currencyId) {
      errors.currencyId = 'Choose currency';
    }
    if (
      amountCurrencyId === data.currencyId &&
      maxFixedValue &&
      value >= maxFixedValue
    ) {
      errors.value = 'The value must be less than Amount To';
    }
  } else if (data.type === FeeValueType.PERCENTAGE) {
    if (value < 0) {
      errors.value = 'The value must be positive';
    }
    if (value > 20) {
      errors.value = 'The value must be less than or equal to 20';
    }
    const a1 = toNumber(data.minValue, 0);
    const a2 = toNumber(data.maxValue, Infinity);
    if (a1 < 0) {
      errors.minValue = 'The value must be a positive number';
    }
    if (a2 <= 0) {
      errors.maxValue = 'The value must be a greater than zero';
    }
    if (a1 >= a2) {
      errors.maxValue = 'The value must be a greater than max value';
    }
  }
  return errors;
}
