import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { FeeValueType } from '../types/enums/FeeValueType';

const defaultOptions = [
  {
    'data-qa': FeeValueType.FIXED,
    key: FeeValueType.FIXED,
    value: FeeValueType.FIXED,
    text: 'Fixed Amount',
  },
  {
    'data-qa': FeeValueType.PERCENTAGE,
    key: FeeValueType.PERCENTAGE,
    value: FeeValueType.PERCENTAGE,
    text: 'Percentage',
  },
];

export interface FeeValueTypeSelectProps extends Omit<SelectProps, 'options'> {
  readOnly?: boolean;
  value?: keyof typeof FeeValueType;
  options?: FeeValueType[];
}

const displayName = 'FeeValueTypeSelect';
export default Object.assign(
  React.memo(
    ({ readOnly = false, options, ...others }: FeeValueTypeSelectProps) => (
      <Form.Select
        options={
          options
            ? defaultOptions.filter((o) => options.includes(o.value))
            : defaultOptions
        }
        open={readOnly ? false : undefined}
        data-qa="fee-type"
        {...others}
      />
    ),
  ),
  { displayName },
);
