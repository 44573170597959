import { takeLatest, call, put } from 'redux-saga/effects';
import authSlice from './slice';
import storage from '../../utils/storage';

const { actions } = authSlice;

function getUserFromToken(currentToken) {
  const token = currentToken || storage.getToken();
  return JSON.parse(atob(token.split('.')[1]));
}

function* loginSaga({ payload }) {
  try {
    const { token } = payload;
    yield call(storage.setToken, token);
    const user = yield call(getUserFromToken, token);
    yield put(actions.loginSuccess({ user }));
  } catch (e) {
    yield put(actions.loginFailure());
  }
}

function* logoutSaga() {
  yield call(storage.removeToken);
}

function* ensureLoginSaga() {
  try {
    const user = yield call(getUserFromToken);
    yield put(actions.setUser({ user }));
  } catch (e) {
    yield call(storage.removeToken);
  }
}

function reloadPageSaga() {
  window.location.reload();
}

export default function* authSaga() {
  yield call(ensureLoginSaga);

  yield takeLatest(actions.login, loginSaga);
  yield takeLatest(actions.loginSuccess, reloadPageSaga);
  yield takeLatest(actions.logout, logoutSaga);
}
