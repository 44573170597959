import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import { PaymentMethod } from '../enums/PaymentMethod';
import { PayoutMethod } from '../enums/PayoutMethod';
import { CustomerType } from '../enums/CustomerType';
import { InOutDirection } from '../enums/InOutDirection';
import { WithNullable } from '../utility';
import { ValidationErrors } from './ValidationErrors';

export interface CryptoInOutCorridorConfiguration {
  countryId: number;
  currencyIds: number[];
  paymentMethods: (keyof typeof PaymentMethod)[];
  payoutMethods: (keyof typeof PayoutMethod)[];
  paymentDetails: string;
  payoutDetails: string;
  customerType: keyof typeof CustomerType;
  direction: (keyof typeof InOutDirection)[];
}

export type StateCryptoInOutCorridorConfiguration = WithNullable<
  CryptoInOutCorridorConfiguration,
  'countryId'
>;

export const configurationFields: Array<
  keyof CryptoInOutCorridorConfiguration
> = [
  'countryId',
  'currencyIds',
  'paymentMethods',
  'payoutMethods',
  'direction',
  'customerType',
  'paymentDetails',
  'payoutDetails',
];

export function validateCryptoInOutCorridorConfiguration(
  state: StateCryptoInOutCorridorConfiguration,
  existsConfigurations: CryptoInOutCorridorConfiguration[],
): ValidationErrors {
  const errors: ValidationErrors =
    validateCryptoInOutCorridorConfigurationRequiredFields(state);
  if (!isEmpty(errors)) {
    return errors;
  }
  const config = state as CryptoInOutCorridorConfiguration;
  if (includesSameConfiguration(existsConfigurations, config)) {
    errors.sameConfiguration =
      'These field are overlapping in another table - please use unique field combination';
    configurationFields.forEach((fieldName) => {
      errors[fieldName] = 'Overlapping field';
    });
  }
  return errors;
}

export function validateCryptoInOutCorridorConfigurationRequiredFields(
  config: StateCryptoInOutCorridorConfiguration,
): ValidationErrors {
  const errors: ValidationErrors = {};
  const requiredFields = [...configurationFields];

  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName of requiredFields) {
    const fieldValue = config[fieldName];
    if (fieldValue === undefined || fieldValue === null) {
      errors[fieldName] = 'Please fill in this field';
    } else if (
      fieldValue &&
      typeof fieldValue === 'object' &&
      fieldValue.length === 0
    ) {
      errors[fieldName] = 'Please choose options for this field';
    }
  }
  return errors;
}

export function findSameConfigurations(
  existsConfigurations: CryptoInOutCorridorConfiguration[],
  requiredConfig: CryptoInOutCorridorConfiguration,
  excludeFields: Array<keyof CryptoInOutCorridorConfiguration> = [],
): CryptoInOutCorridorConfiguration[] {
  return existsConfigurations.filter((c) =>
    isSameConfiguration(c, requiredConfig, excludeFields),
  );
}

export function includesSameConfiguration(
  existsConfigurations: CryptoInOutCorridorConfiguration[],
  requiredConfig: CryptoInOutCorridorConfiguration,
  excludeFields: Array<keyof CryptoInOutCorridorConfiguration> = [],
): boolean {
  return existsConfigurations.some((c) =>
    isSameConfiguration(c, requiredConfig, excludeFields),
  );
}

export function isSameConfiguration(
  a: CryptoInOutCorridorConfiguration,
  b: CryptoInOutCorridorConfiguration,
  excludeFields: Array<keyof CryptoInOutCorridorConfiguration> = [],
): boolean {
  const fields = difference(configurationFields, excludeFields);
  return fields.every(
    (fieldName) =>
      a[fieldName] === b[fieldName] ||
      (Array.isArray(a[fieldName]) &&
        hasIntersect(
          a[fieldName] as Array<string | number>,
          b[fieldName] as Array<string | number>,
        )),
  );
}

export function hasIntersect(
  a: ArrayLike<string | number>,
  b: ArrayLike<string | number>,
): boolean {
  return intersection(a, b).length > 0;
}
