import React, { useCallback, useState } from 'react';
import { Button, Form, Message, Popup, Table } from 'semantic-ui-react';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { FeeValue } from '../../../../types/models/FeeValue';
import { AmountType } from '../../../../types/enums/AmountType';
import MoneyInput from '../../../../components/MoneyInput';
import FeeValuesCell from '../FeeValuesCell';
import { CryptoBuySellRange } from '../../../../types/models/CryptoBuySellRange';
import { SetStateCallback } from '../../../../types/utility';
import { ValidationErrors } from '../../../../types/models/ValidationErrors';
import { FeeValueType } from 'src/types/enums/FeeValueType';

export type ChangedCryptoBuySellRange = Partial<Omit<CryptoBuySellRange, 'id'>>;

interface CryptoBuySellRangeViewProps {
  defaultCurrencyId?: number;
  typeAmounts: keyof typeof AmountType;
  feeTypes?: FeeValueType[];
  range: CryptoBuySellRange;
  readOnly?: boolean;
  onChange: (
    id: CryptoBuySellRange['id'],
    changed: ChangedCryptoBuySellRange,
  ) => void;
  onDelete: (id: CryptoBuySellRange['id']) => void;
  validationErrors?: ValidationErrors;
}

export default function CryptoBuySellRangeView({
  defaultCurrencyId,
  typeAmounts,
  feeTypes,
  range,
  readOnly = false,
  onChange,
  onDelete,
  validationErrors = {},
}: CryptoBuySellRangeViewProps): JSX.Element {
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed: ChangedCryptoBuySellRange = { [name]: value };
      changed.editedAt = new Date();
      onChange(range.id, changed);
    },
    [range.id, onChange],
  );

  const onClickDelete = useCallback(() => {
    onDelete(range.id);
  }, [range.id, onDelete]);

  const setFees = useCallback(
    (func: SetStateCallback<FeeValue>) => {
      const fees = func(range.fees);
      const changed: ChangedCryptoBuySellRange = { fees };
      changed.editedAt = new Date();
      onChange(range.id, changed);
    },
    [range, onChange],
  );

  const errors = [
    validationErrors.overlapping as string | undefined,
    validationErrors.bigGap as string | undefined,
  ].filter((e: string | undefined) => !!e);

  const rowClassName = classNames('crypto_form_collect__row', {
    'crypto_form_collect__row--cant_be_submit': errors.length > 0,
  });

  return (
    <>
      <Table.Row className={rowClassName}>
        <Table.Cell
          className={`crypto_form_collect__amount_cell--${typeAmounts}`}
        >
          {typeAmounts === AmountType.SINGLE && (
            <MoneyInput
              className="crypto_form_collect__input_field--width_popup_error"
              label="Value"
              name="amountFrom"
              value={range.amountFrom}
              onChange={onChangeField}
              readOnly={readOnly}
              placeholder="0"
              error={validationErrors.amountFrom}
            />
          )}
          {typeAmounts === AmountType.RANGE && (
            <div className="crypto_form_collect__fee_amount_range">
              <MoneyInput
                className="crypto_form_collect__input_field--width_popup_error"
                label="From"
                name="amountFrom"
                value={range.amountFrom}
                onChange={onChangeField}
                readOnly={readOnly}
                placeholder="0"
                error={validationErrors.amountFrom}
              />
              <MoneyInput
                className="crypto_form_collect__input_field--width_popup_error"
                label="To"
                name="amountTo"
                value={range.amountTo}
                onChange={onChangeField}
                readOnly={readOnly}
                placeholder="Inf"
                error={validationErrors.amountTo}
              />
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          <MoneyInput
            className="crypto_form_collect__rate_value_amount"
            label="Value"
            name="rate"
            value={range.rate}
            onChange={onChangeField}
            readOnly={readOnly}
            error={validationErrors.rate}
          />
        </Table.Cell>
        <FeeValuesCell
          values={range.fees}
          setValues={setFees}
          readOnly={readOnly}
          defaultCurrencyId={defaultCurrencyId}
          feeTypes={feeTypes}
          validationErrors={
            validationErrors.feesErrors as ValidationErrors | undefined
          }
        />
        {!readOnly && (
          <Table.Cell className="crypto_form_collect__controls_cell">
            <Button onClick={onClickDelete} type="button" data-qa="remove">
              Remove
            </Button>
          </Table.Cell>
        )}
      </Table.Row>
      {errors.length > 0 && (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <Message
              visible
              attached="bottom"
              error
              header="There are errors in the record above"
              list={errors}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}
