function parse(str) {
  return [...new URLSearchParams(str).entries()].reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value,
    }),
    {},
  );
}

function stringify(params) {
  const stringified = Object.entries(params)
    .reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new URLSearchParams())
    .toString();

  return stringified ? `?${stringified}` : '';
}

export default {
  parse,
  stringify,
};
