import { gql, useQuery } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

const GET_COUNTRIES = gql`
  query CurrencySelectOptions {
    currencies {
      id
      iso3Code
    }
  }
`;

type CurrencyItem = {
  id: number;
  label: string;
};

export default function useCurrencies() {
  const { data, loading } = useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-first',
  });
  const currencies: CurrencyItem[] = useMemo<CurrencyItem[]>(() => {
    if (!data) {
      return [];
    }
    return sortBy(
      data.currencies.map((o: any) => ({
        id: o.id,
        label: o.iso3Code,
        defaultCurrencyId: o.defaultCurrencyId,
      })),
      'label',
    );
  }, [data]);

  return { currencies, loading };
}
