import React, { useCallback, useMemo } from 'react';
import { Button, Grid, Header, Icon, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import Loader from '../../components/Loader';
import SortableTable from '../../components/SortableTable';
import QueryComposer from '../../components/QueryComposer';
import HeaderButton from '../../components/HeaderButton';
import './FormRateList.scss';

const COLUMNS = {
  ID: 'ID',
  ORGANIZATION_NAME: 'ORGANIZATION_NAME',
  COUNTRY: 'COUNTRY',
  CURRENCY_FROM: 'CURRENCY_FROM',
  CLIENT_TYPE: 'CLIENT_TYPE',
  RESEARCHER_NAME: 'RESEARCHER_NAME',
  STATUS: 'STATUS',
  ACTIONS: 'ACTIONS',
};

const columnsConfig = {
  keyAttribute: 'id',
  defaultText: '-',
  columns: [
    COLUMNS.ID,
    COLUMNS.ORGANIZATION_NAME,
    COLUMNS.CLIENT_TYPE,
    COLUMNS.COUNTRY,
    COLUMNS.CURRENCY_FROM,
    COLUMNS.RESEARCHER_NAME,
    COLUMNS.STATUS,
    COLUMNS.ACTIONS,
  ],
  config: {
    [COLUMNS.ID]: {
      label: 'Form Id',
      sortable: true,
      dataAttribute: 'id',
    },
    [COLUMNS.ORGANIZATION_NAME]: {
      label: 'Organization Name',
      // sortable: true,
      dataAttribute: 'organization.name',
      customRender: true,
    },
    [COLUMNS.CURRENCY_FROM]: {
      label: 'Currency from',
      // sortable: true,
      dataAttribute: 'currencyFrom.iso3Code',
    },
    [COLUMNS.COUNTRY]: {
      label: 'Country',
      // sortable: true,
      dataAttribute: 'country.name',
    },
    [COLUMNS.CLIENT_TYPE]: {
      label: 'Client Type',
      sortable: true,
      dataAttribute: 'clientType',
      customRender: true,
    },
    [COLUMNS.RESEARCHER_NAME]: {
      label: 'Researcher',
      sortable: true,
      dataAttribute: 'researcherName',
    },
    [COLUMNS.STATUS]: {
      label: 'Status',
      dataAttribute: 'submission',
      customRender: true,
    },
    [COLUMNS.ACTIONS]: {
      label: '',
      customRender: true,
    },
  },
};

function renderNameColumn(column, item) {
  const { config } = columnsConfig;
  const idAttribute = config[COLUMNS.ID].dataAttribute;
  const currentAttribute = config[column].dataAttribute;

  return (
    <Link to={`/rates-forms/${item[idAttribute]}`}>
      {get(item, currentAttribute, 'No Name')}
    </Link>
  );
}

function renderStatusColumn(column, item) {
  const { config } = columnsConfig;
  const currentAttribute = config[column].dataAttribute;
  return item[currentAttribute] ? 'Submitted' : '--No rates--';
}

function renderClientTypeColumn(column, item) {
  const { config } = columnsConfig;
  const currentAttribute = config[column].dataAttribute;
  return capitalize(item[currentAttribute]);
}

function renderActionsColumn(column, item) {
  return <Actions item={item} />;
}

function renderItem(column, item) {
  switch (column) {
    case COLUMNS.ORGANIZATION_NAME:
      return renderNameColumn(column, item);
    case COLUMNS.CLIENT_TYPE:
      return renderClientTypeColumn(column, item);
    case COLUMNS.STATUS:
      return renderStatusColumn(column, item);
    case COLUMNS.ACTIONS:
      return renderActionsColumn(column, item);
    default:
      return null;
  }
}

const DELETE_FORM = gql`
  mutation DeleteFormRate($id: Int) {
    deleted: deleteFormRate(where: { id: $id }) {
      id
    }
  }
`;

function Actions(props) {
  const { item } = props;
  const { id, slug } = item;
  const editable = !item.submission;

  const [deleteForm] = useMutation(DELETE_FORM, {
    update(cache, { data: mutationData }) {
      if (mutationData) {
        const idToRemove = mutationData.deleted.id;
        cache.modify({
          fields: {
            formRates(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => idToRemove !== readField('id', ref),
              );
            },
          },
        });
      }
    },
  });

  const onDelete = useCallback(() => {
    if (!window.confirm('Do you want to remove this form?')) {
      return;
    }
    deleteForm({
      variables: { id },
    });
  }, [id]);

  return (
    <>
      <Button
        title="Open form to fill rates"
        data-qa="open"
        icon
        as={Link}
        to={`/collect-rates/${slug}`}
      >
        <Icon name="eye" />
      </Button>
      {editable && (
        <Button title="Remove form" data-qa="remove" icon onClick={onDelete}>
          <Icon name="trash" />
        </Button>
      )}
    </>
  );
}

function getHeaderCellClassName(column) {
  switch (column) {
    case COLUMNS.ID:
      return 'form_rate_list__header_cell_id';
    case COLUMNS.CURRENCY_FROM:
      return 'form_rate_list__header_cell_currency_from';
    case COLUMNS.COUNTRY:
      return 'form_rate_list__header_cell_country';
    case COLUMNS.RESEARCHER_NAME:
      return 'form_rate_list__header_researcher_name';
    case COLUMNS.CLIENT_TYPE:
      return 'form_rate_list__header_client_type';
    case COLUMNS.STATUS:
      return 'form_rate_list__header_status';
    case COLUMNS.ACTIONS:
      return 'form_rate_list__header_cell_actions';
    default:
      return '';
  }
}

const GET_FORM_LIST = gql`
  query GetFormRateList(
    $collectionId: Int
    $offset: Int
    $perPage: Int
    $orderBy: FormRateOrderByWithRelationInput!
  ) {
    items: formRates(
      skip: $offset
      take: $perPage
      where: { collectionId: { equals: $collectionId } }
      orderBy: [$orderBy]
    ) {
      id
      researcherName
      clientType
      slug
      currencyFrom {
        iso3Code
      }
      country {
        name
      }
      organization {
        name
      }
      submission {
        id
      }
    }
    total: aggregateFormRate(
      where: { collectionId: { equals: $collectionId } }
    ) {
      _count {
        _all
      }
    }
  }
`;

function FormRateListTable(props) {
  const collectionId = parseInt(props.collectionId);
  const page = props.page || 1;
  const perPage = props.perPage || 10;
  const offset = (page - 1) * perPage;
  const { sortColumn, sortOrder, columnsConfig } = props;
  const orderBy = useMemo(() => {
    if (!sortColumn || !sortOrder) {
      return { id: 'desc' };
    }
    const { config } = props.columnsConfig;
    const attribute = config[sortColumn].dataAttribute || 'id';
    let order = sortOrder.toLowerCase();
    if (!['asc', 'desc'].includes(order)) {
      order = 'asc';
    }
    return {
      [attribute]: order,
    };
  }, [sortColumn, sortOrder]);

  const { loading, error, data } = useQuery(GET_FORM_LIST, {
    variables: {
      collectionId,
      offset,
      perPage,
      orderBy,
    },
  });

  if (loading) return <Loader isLoading />;
  if (error) return `Error! ${error.message}`;

  const pages = Math.ceil(data.total._count._all / perPage);

  return (
    <>
      <SortableTable
        items={data.items}
        columnsConfig={columnsConfig}
        renderItem={renderItem}
        setHeaderCellClassName={getHeaderCellClassName}
        isExternalSort
        onSortChange={props.onSortChange}
        sortColumn={props.sortColumn}
        sortOrder={props.sortOrder}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Pagination
              activePage={page}
              totalPages={pages || 1}
              onPageChange={(_, data) => props.setPage(data.activePage)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default function FormRateList(props) {
  const { collectionId, editable } = props;
  const createLink = collectionId
    ? `/collections/${collectionId}/new-rates-form`
    : '/rates-forms/new';

  return (
    <div className="forms_list">
      <QueryComposer>
        {(query, setQuery) => (
          <>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column className="flex align-items-center">
                  <Header size="large">Forms</Header>
                </Grid.Column>
                <Grid.Column className="flex justify-content-end align-items-center spaced-items">
                  {editable && (
                    <HeaderButton
                      as={Link}
                      to={createLink}
                      data-qa="create-form"
                    >
                      Create Form
                    </HeaderButton>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <FormRateListTable
              columnsConfig={columnsConfig}
              collectionId={collectionId}
              page={query.page || 1}
              perPage={10}
              setPage={(page) => setQuery({ page })}
              onSortChange={({ sortColumn, sortOrder }) =>
                setQuery({
                  sortBy: sortColumn,
                  sortOrder,
                })
              }
              sortColumn={query.sortBy}
              sortOrder={query.sortOrder}
            />
          </>
        )}
      </QueryComposer>
    </div>
  );
}
