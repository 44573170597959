import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import DecimalInput from '../../../components/DecimalInput';

export default class AddAmountModal extends React.PureComponent {
  state = {
    amount: '',
  };

  handleSubmit = (event) => {
    const { onSubmit, onClose } = this.props;
    const { amount } = this.state;
    onSubmit(event, { amount });
    onClose();
    this.setState({ amount: '' });
  };

  onChangeAmount = (event, { value }) => {
    this.setState({ amount: value });
  };

  render() {
    const { open, onClose } = this.props;
    const { amount } = this.state;
    return (
      <Modal onClose={onClose} open={open} size="mini">
        <Modal.Header>Add amount</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>What amount do you want to add?</p>
            <Form>
              <Form.Field>
                <DecimalInput
                  value={amount}
                  data-qa="add-amount"
                  onChange={this.onChangeAmount}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            negative
            content="Cancel"
            onClick={onClose}
            data-qa="cancel"
          />
          <Button
            type="button"
            positive
            onClick={this.handleSubmit}
            content="OK"
            data-qa="ok"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
