import React, { useCallback, useState } from 'react';
import { Button, Form, Message, Popup, Table } from 'semantic-ui-react';
import addToDate from 'date-fns/add';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { FeeValue } from '../../../types/models/FeeValue';
import MoneyInput from '../../../components/MoneyInput';
import DateTimePicker from '../../../components/DateTimePicker';
import FeeValuesCell from './FeeValuesCell';
import { FeeRange } from '../../../types/models/FeeRange';
import { SetStateCallback } from '../../../types/utility';
import { ValidationErrors } from '../../../types/models/ValidationErrors';

export type ChangedFeeRange = Partial<Omit<FeeRange, 'id'>>;

interface FeeCorridorTableRowProps {
  range: FeeRange;
  amountCurrencyId: number;
  readOnly?: boolean;
  onChange: (id: FeeRange['id'], changed: ChangedFeeRange) => void;
  onDelete: (id: FeeRange['id']) => void;
  validationErrors?: ValidationErrors;
}

const GET_CURRENCY = gql`
  query GetCurrency($id: Int!) {
    currency: currency(where: { id: $id }) {
      iso3Code
    }
  }
`;

export default function FeeCorridorTableRow({
  range,
  readOnly = false,
  onChange,
  onDelete,
  amountCurrencyId,
  validationErrors = {},
}: FeeCorridorTableRowProps): JSX.Element {
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed: ChangedFeeRange = { [name]: value };
      changed.editedAt = new Date();
      onChange(range.id, changed);
    },
    [range.id, onChange],
  );

  const onClickDelete = useCallback(() => {
    onDelete(range.id);
  }, [range.id, onDelete]);

  const [collectedAt, setCollectedAt] = useState(() => {
    if (!range.collectedAtLocal) {
      return '';
    }
    if (typeof range.collectedAtLocal !== 'object') {
      // display time as it is in the location
      return addToDate(new Date(range.collectedAtLocal), {
        minutes: new Date().getTimezoneOffset(),
      });
    }
    return range.collectedAtLocal;
  });

  const onChangeCollectedAt = useCallback(
    ({ date }) => {
      setCollectedAt(date || '');
      onChange(range.id, { collectedAtLocal: date });
    },
    [setCollectedAt, onChange, range.id],
  );

  const setValues = useCallback(
    (func: SetStateCallback<FeeValue>) => {
      const values = func(range.values);
      const changed: ChangedFeeRange = { values };
      changed.editedAt = new Date();
      onChange(range.id, changed);
    },
    [range, onChange],
  );

  const { data } = useQuery(GET_CURRENCY, {
    fetchPolicy: 'cache-first',
    variables: {
      id: amountCurrencyId,
    },
  });
  const errors = [
    validationErrors.overlapping as string | undefined,
    validationErrors.bigGap as string | undefined,
  ].filter((e: string | undefined) => !!e);

  const rowClassName = classNames('form_fee_collect__row', {
    'form_fee_collect__row--cant_be_submit': errors.length > 0,
  });

  return (
    <>
      <Table.Row className={rowClassName}>
        <Table.Cell className="form_fee_collect__amount_cell">
          <div className="form_fee_collect__fee_amount_range">
            <MoneyInput
              className="form_fee_collect__input_field--width_popup_error"
              label="From"
              name="amountFrom"
              value={range.amountFrom}
              onChange={onChangeField}
              readOnly={readOnly}
              placeholder="0"
              error={validationErrors.amountFrom}
            />
            <MoneyInput
              className="form_fee_collect__input_field--width_popup_error"
              label="To"
              name="amountTo"
              value={range.amountTo}
              onChange={onChangeField}
              readOnly={readOnly}
              placeholder="Inf"
              error={validationErrors.amountTo}
            />
            <Form.Field className="form_fee_collect__fee_amount_range_currency">
              <label>&nbsp;</label>
              <Popup
                content="Amount Currency"
                trigger={<div>{data?.currency?.iso3Code}</div>}
                position="bottom left"
              />
            </Form.Field>
          </div>
        </Table.Cell>
        <FeeValuesCell
          values={range.values}
          setValues={setValues}
          readOnly={readOnly}
          defaultCurrencyId={amountCurrencyId}
          validationErrors={
            validationErrors.valuesErrors as ValidationErrors | undefined
          }
        />
        <Table.Cell className="form_fee_collect__collected_at_cell">
          <DateTimePicker
            className="form_fee_collect__input_field--width_popup_error"
            value={collectedAt}
            onChange={onChangeCollectedAt}
            readOnly={readOnly}
            error={validationErrors.collectedAtLocal}
          />
        </Table.Cell>
        {!readOnly && (
          <Table.Cell className="form_fee_collect__controls_cell">
            <Button onClick={onClickDelete} type="button" data-qa="remove">
              Remove
            </Button>
          </Table.Cell>
        )}
      </Table.Row>
      {errors.length > 0 && (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <Message
              visible
              attached="bottom"
              error
              header="There are errors in the record above"
              list={errors}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}
