import React, { useCallback } from 'react';
import trim from 'lodash/trim';
import { useHistory, useParams } from 'react-router-dom';
import saveTypes from '../../constants/saveTypes';
import Loader from '../../components/Loader';
import Page404 from '../../components/Page404';
import FormFeeEditForm from './FormFeeEditForm';
import useFormFee from './useFormFee';

export default function FormFeeEdit(props) {
  const { formId, collectionId } = useParams();
  const history = useHistory();

  const initState = {};
  if (collectionId && isFinite(collectionId)) {
    initState.collectionId = parseInt(collectionId);
  }

  const { form, setForm, loading, saveForm, changeSlug } = useFormFee(
    formId,
    initState,
  );

  const onChange = useCallback(
    (updated) => {
      setForm((prev) => ({ ...prev, ...updated }));
    },
    [setForm],
  );

  const onSubmit = useCallback(
    async (saveType) => {
      const {
        data: {
          form: { id, collectionId },
        },
      } = await saveForm({
        variables: {
          ...form,
          researcherName: trim(form.researcherName),
          countryId: form.countryId !== '' ? form.countryId : null,
        },
      });
      if (saveType === saveTypes.SAVE) {
        history.push(`/collections/${collectionId}`);
      } else {
        history.replace(`/fees-forms/${id}`);
      }
    },
    [form, saveForm, history],
  );

  if (loading) return <Loader isLoading={loading} />;
  if (!form) return <Page404 />;

  const header = form.id ? `Form Configuration #${form.id}` : 'New Form';

  return (
    <div>
      <FormFeeEditForm
        header={header}
        data={form}
        onChange={onChange}
        onSubmit={onSubmit}
        changeSlug={changeSlug}
      />
    </div>
  );
}
