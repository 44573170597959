import React, { useCallback, useState } from 'react';
import { Button, Card, Grid, Form, Radio, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import addToDate from 'date-fns/add';
import DateTimePicker from '../../../../components/DateTimePicker';
import {
  CryptoInOutCorridor,
  getDefaultFeeCurrencyId,
} from '../../../../types/models/CryptoInOutCorridor';
import CryptoInOutRanges from './CryptoInOutRanges';
import { SetStateCallback } from '../../../../types/utility';
import { CryptoInOutRange } from '../../../../types/models/CryptoInOutRange';
import CryptoInOutCorridorConfigurationHeader from './CryptoInOutCorridorConfigurationHeader';
import { ValidationErrors } from '../../../../types/models/ValidationErrors';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import { AmountType } from 'src/types/enums/AmountType';

export type ChangedCryptoInOutCorridor = Partial<
  Omit<CryptoInOutCorridor, 'id' | 'configuration'>
>;

interface CryptoInOutCorridorViewProps {
  corridor: CryptoInOutCorridor;
  transferDirection: keyof typeof TransferDirection;
  onChange: (
    id: CryptoInOutCorridor['id'],
    changed: Partial<CryptoInOutCorridor>,
  ) => void;
  onEditConfiguration: (corridor: CryptoInOutCorridor) => void;
  onCopy: (corridor: CryptoInOutCorridor) => void;
  onDelete: (id: CryptoInOutCorridor['id']) => void;
  readOnly: boolean;
  validationErrors?: ValidationErrors;
}

export default function CryptoInOutCorridorView({
  corridor,
  onChange,
  onEditConfiguration,
  onCopy,
  onDelete,
  transferDirection,
  readOnly = false,
  validationErrors = {},
}: CryptoInOutCorridorViewProps): JSX.Element {
  const setRanges = useCallback(
    (func: SetStateCallback<CryptoInOutRange>) => {
      const ranges = func(corridor.ranges);
      const changed: ChangedCryptoInOutCorridor = { ranges };
      onChange(corridor.id, changed);
    },
    [corridor, onChange],
  );
  const onClickCopy = useCallback(() => {
    onCopy(corridor);
  }, [corridor, onCopy]);
  const onClickDelete = useCallback(() => {
    onDelete(corridor.id);
  }, [corridor.id, onDelete]);
  const onClickEdit = useCallback(() => {
    onEditConfiguration(corridor);
  }, [corridor, onEditConfiguration]);

  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  const classNameExpanded = classNames({
    crypto_form_collect__corridor_expand: true,
    'crypto_form_collect__corridor_expand--expanded': expanded,
  });

  const [collectedAt, setCollectedAt] = useState(() => {
    if (!corridor.collectedAtLocal) {
      return '';
    }
    if (typeof corridor.collectedAtLocal !== 'object') {
      // display time as it is in the location
      return addToDate(new Date(corridor.collectedAtLocal), {
        minutes: new Date().getTimezoneOffset(),
      });
    }
    return corridor.collectedAtLocal;
  });

  const onChangeCollectedAt = useCallback(
    ({ date }) => {
      setCollectedAt(date || '');
      onChange(corridor.id, { collectedAtLocal: date });
    },
    [setCollectedAt, onChange, corridor.id],
  );

  const onChangeMode = useCallback(
    (e, { name, value }) => {
      const fieldName = name.split('_')[0];
      onChange(corridor.id, { [fieldName]: value });
    },
    [onChange, corridor.id],
  );

  return (
    <Card fluid>
      <div className="crypto_form_collect__corridor_header">
        <div className={classNameExpanded} onClick={toggleExpanded}>
          <Icon name="dropdown" />
        </div>
        <CryptoInOutCorridorConfigurationHeader
          configuration={corridor}
          transferDirection={transferDirection}
        />
      </div>
      {expanded && (
        <>
          <Grid style={{ margin: 0 }}>
            <Grid.Column width={5}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field label="I am entering" />
                    <Form.Field>
                      <Radio
                        label="Ranges"
                        data-qa="ranges"
                        name={`typeAmounts_inOut_${corridor.id}`}
                        value={AmountType.RANGE}
                        checked={corridor.typeAmounts === AmountType.RANGE}
                        onChange={onChangeMode}
                        readOnly={readOnly}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Single amount"
                        data-qa="single"
                        name={`typeAmounts_inOut_${corridor.id}`}
                        value={AmountType.SINGLE}
                        checked={corridor.typeAmounts === AmountType.SINGLE}
                        onChange={onChangeMode}
                        readOnly={readOnly}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={4}>
              <Form.Field label="Collected at (location time)" />
              <DateTimePicker
                className="crypto_form_collect__input_field--width_popup_error"
                value={collectedAt}
                onChange={onChangeCollectedAt}
                readOnly={readOnly}
                error={validationErrors.collectedAtLocal}
                popperClassName="crypto_form_collect--zIndex3"
              />
            </Grid.Column>
          </Grid>
          <CryptoInOutRanges
            defaultCurrencyId={getDefaultFeeCurrencyId(corridor)}
            typeAmounts={corridor.typeAmounts}
            ranges={corridor.ranges}
            setRanges={setRanges}
            readOnly={readOnly}
            validationErrors={
              validationErrors.rangesErrors as ValidationErrors | undefined
            }
          />
          {!readOnly && (
            <div className="crypto_form_collect__corridor_footer">
              <Grid>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column floated="left" width="6">
                    <Button
                      data-qa="clone-table"
                      onClick={onClickCopy}
                      type="button"
                    >
                      Clone Table
                    </Button>
                  </Grid.Column>
                  <Grid.Column floated="right" width="6" textAlign="right">
                    <div style={{ marginBottom: '1em' }}>
                      <Button
                        data-qa="edit-table-settings"
                        onClick={onClickEdit}
                        type="button"
                      >
                        Edit Table Settings
                      </Button>
                    </div>
                    <div>
                      <Button
                        data-qa="delete-table"
                        onClick={onClickDelete}
                        type="button"
                      >
                        Delete Table
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </>
      )}
    </Card>
  );
}
