import React, { useCallback } from 'react';
import { Button, Table } from 'semantic-ui-react';
import * as Big from 'big-ts';
import {
  createCryptoInOutRange,
  CryptoInOutRange,
} from '../../../../types/models/CryptoInOutRange';
import CryptoInOutRangeView from './CryptoInOutRangeView';
import { createFeeValue } from '../../../../types/models/FeeValue';
import { AmountType } from '../../../../types/enums/AmountType';
import useUpdate from '../../../../hooks/useUpdate';
import { SetState } from '../../../../types/utility';
import useDelete from '../../../../hooks/useDelete';
import useCreate from '../../../../hooks/useCreate';
import { ValidationErrors } from '../../../../types/models/ValidationErrors';
import { toNumber } from '../../../../utils/number';

interface CryptoInOutRangesProps {
  ranges: CryptoInOutRange[];
  typeAmounts: keyof typeof AmountType;
  setRanges: SetState<CryptoInOutRange>;
  defaultCurrencyId?: number;
  readOnly: boolean;
  validationErrors?: ValidationErrors;
}

export default function CryptoInOutRanges({
  defaultCurrencyId,
  typeAmounts,
  ranges,
  setRanges,
  readOnly = false,
  validationErrors = {},
}: CryptoInOutRangesProps): JSX.Element {
  const sortedRanges = ranges
    .slice()
    .sort((a, b) => Number(a.amountFrom) - Number(b.amountFrom));
  const addRange = useCreate<CryptoInOutRange>(setRanges);
  const updateRange = useUpdate<CryptoInOutRange>(setRanges);
  const deleteRange = useDelete<CryptoInOutRange>(setRanges);

  const onAddRange = useCallback(() => {
    let amountFrom;
    if (typeAmounts === AmountType.SINGLE) {
      const greatestAmountFrom = ranges.reduce((acc, range) => {
        const amountFrom = toNumber(range.amountFrom, 0);
        return acc > amountFrom ? acc : amountFrom;
      }, 0);
      amountFrom = Big.toString(Big.parse(greatestAmountFrom));
    } else if (typeAmounts === AmountType.RANGE) {
      const greatestAmountTo = ranges.reduce((acc, range) => {
        const amountTo = toNumber(range.amountTo, 0);
        return acc > amountTo ? acc : amountTo;
      }, 0);
      amountFrom =
        greatestAmountTo < Infinity
          ? Big.toString(
              Big.add(Big.parse(greatestAmountTo))(Big.parse('0.01')),
            )
          : '';
    }
    addRange(
      createCryptoInOutRange({
        amountFrom,
        fees: [
          createFeeValue({
            currencyId: defaultCurrencyId,
          }),
        ],
      }),
    );
  }, [addRange, ranges, typeAmounts]);
  return (
    <div className="crypto_form_collect__table_container">
      <Table celled className="crypto_form_collect__table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Fee</Table.HeaderCell>
            {!readOnly && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedRanges.map((range, index) => (
            <CryptoInOutRangeView
              key={range.id}
              defaultCurrencyId={defaultCurrencyId}
              typeAmounts={typeAmounts}
              range={range}
              readOnly={readOnly}
              onChange={updateRange}
              onDelete={deleteRange}
              validationErrors={
                validationErrors[index] as ValidationErrors | undefined
              }
            />
          ))}
        </Table.Body>
      </Table>
      {!readOnly && (
        <div className="crypto_form_collect__table_actions">
          <Button
            data-qa="add-range"
            primary
            onClick={onAddRange}
            type="button"
          >
            Add Range
          </Button>
        </div>
      )}
    </div>
  );
}
