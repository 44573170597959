import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import FeeCorridorConfigurationForm from './FeeCorridorConfigurationForm';
import {
  FeeCorridorConfiguration,
  StateFeeCorridorConfiguration,
  validateFeeCorridorConfiguration,
} from '../../../types/models/FeeCorridorConfiguration';
import { FeeCorridor } from '../../../types/models/FeeCorridor';
import { TransferDirection } from '../../../types/enums/TransferDirection';

interface FeeCorridorEditFormProps {
  corridor: FeeCorridor;
  existsConfigurations?: FeeCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (corridorId: number, config: FeeCorridorConfiguration) => void;
}

export default function FeeCorridorEditForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: FeeCorridorEditFormProps): JSX.Element {
  const [state, setState] = useState<StateFeeCorridorConfiguration>(corridor);
  const validationErrors = useMemo(
    () =>
      validateFeeCorridorConfiguration(
        state as FeeCorridorConfiguration,
        existsConfigurations,
      ),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedRanges = state.ranges.map((range) => ({
      ...range,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      values: range.values.map((rangeValue) => ({
        ...rangeValue,
        currencyId: state.amountCurrencyId,
      })),
    }));
    const updatedState = { ...state, ...{ ranges: updatedRanges } };
    setState(updatedState);

    onSubmit(corridor.id, updatedState as FeeCorridorConfiguration);
    onClose();
  }, [onSubmit, onClose, state, corridor.id]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Edit Table Settings" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <FeeCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
                data-qa="save-settings"
              >
                Save Settings
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
