import React, { useCallback } from 'react';
import { Button, Table } from 'semantic-ui-react';
import * as Big from 'big-ts';
import { createFeeRange, FeeRange } from '../../../types/models/FeeRange';
import FeeCorridorTableRow from './FeeCorridorTableRow';
import useUpdate from '../../../hooks/useUpdate';
import { SetState } from '../../../types/utility';
import useDelete from '../../../hooks/useDelete';
import useCreate from '../../../hooks/useCreate';
import { createFeeValue } from '../../../types/models/FeeValue';
import { ValidationErrors } from '../../../types/models/ValidationErrors';
import { toNumber } from '../../../utils/number';

interface FeeRangesTableProps {
  ranges: FeeRange[];
  setRanges: SetState<FeeRange>;
  amountCurrencyId: number;
  readOnly: boolean;
  validationErrors?: ValidationErrors;
}

export default function FeeRangesTable({
  amountCurrencyId,
  ranges,
  setRanges,
  readOnly = false,
  validationErrors = {},
}: FeeRangesTableProps): JSX.Element {
  const sortedRanges = ranges
    .slice()
    .sort((a, b) => Number(a.amountFrom) - Number(b.amountFrom));
  const addRange = useCreate<FeeRange>(setRanges);
  const updateRange = useUpdate<FeeRange>(setRanges);
  const deleteRange = useDelete<FeeRange>(setRanges);

  const greatestAmountTo = ranges.reduce((acc, range) => {
    const amountTo = toNumber(range.amountTo, 0);
    return acc > amountTo ? acc : amountTo;
  }, 0);
  const onAddRange = useCallback(() => {
    const amountFrom =
      greatestAmountTo < Infinity
        ? Big.toString(Big.add(Big.parse(greatestAmountTo))(Big.parse('0.01')))
        : '';
    addRange(
      createFeeRange({
        amountFrom,
        values: [
          createFeeValue({
            currencyId: amountCurrencyId,
          }),
        ],
      }),
    );
  }, [addRange, greatestAmountTo, amountCurrencyId]);
  return (
    <div className="form_fee_collect__table_container">
      <Table celled className="form_fee_collect__table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Fee</Table.HeaderCell>
            <Table.HeaderCell>Collected At (location time)</Table.HeaderCell>
            {!readOnly && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedRanges.map((range, index) => (
            <FeeCorridorTableRow
              key={range.id}
              range={range}
              amountCurrencyId={amountCurrencyId}
              readOnly={readOnly}
              onChange={updateRange}
              onDelete={deleteRange}
              validationErrors={
                validationErrors[index] as ValidationErrors | undefined
              }
            />
          ))}
        </Table.Body>
      </Table>
      {!readOnly && (
        <div className="form_fee_collect__table_actions">
          <Button
            data-qa="add-range"
            primary
            onClick={onAddRange}
            type="button"
          >
            Add Range
          </Button>
        </div>
      )}
    </div>
  );
}
