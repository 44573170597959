import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import { gql, useLazyQuery } from '@apollo/client';

const FIELDS_FRAGMENT = gql`
  fragment CollectionSelectFields on Collection {
    id
    name
  }
`;

const GET_SELECTED = gql`
  query CollectionSelectSelected($id: Int) {
    selected: collection(where: { id: $id }) {
      ...CollectionSelectFields
    }
  }
  ${FIELDS_FRAGMENT}
`;

const GET_OPTIONS = gql`
  query CollectionSelectOptions(
    $limit: Int
    $search: String = ""
    $formType: FormType
  ) {
    options: collections(
      take: $limit
      where: {
        name: { contains: $search, mode: insensitive }
        formType: { equals: $formType }
      }
      orderBy: [{ id: desc }]
    ) {
      ...CollectionSelectFields
    }
  }
  ${FIELDS_FRAGMENT}
`;

export default React.memo((props) => {
  const { value, formType, ...restProps } = props;
  const [search, setSearch] = useState(null);
  const [fetchSelected, { data: selectedData, loading: selectedLoading }] =
    useLazyQuery(GET_SELECTED, {
      fetchPolicy: 'cache-first',
    });
  const [fetchOptions, { data: optionsData, loading: optionsLoading }] =
    useLazyQuery(GET_OPTIONS, {
      fetchPolicy: 'cache-first',
    });

  const options = useMemo(() => {
    const fetchedOptions = optionsData ? optionsData.options : [];
    const selectedOption = selectedData ? selectedData.selected : undefined;
    const options = fetchedOptions.map((o) => ({
      key: o.id,
      value: o.id,
      text: `#${o.id} - ${o.name}`,
    }));
    if (selectedOption && !find(options, { value: selectedOption.id })) {
      options.push({
        key: selectedOption.id,
        value: selectedOption.id,
        text: `#${selectedOption.id} - ${selectedOption.name}`,
      });
    }
    return options;
  }, [selectedData, optionsData]);

  const delayedQuery = useCallback(
    debounce(
      (search, formType) => fetchOptions({ variables: { search, formType } }),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (search !== null) {
      delayedQuery(search, formType);
    }
  }, [search, formType, delayedQuery]);

  useEffect(() => {
    if (value && isFinite(value)) {
      fetchSelected({ variables: { id: value } });
    }
  }, [value]);

  return (
    <Form.Select
      options={options}
      loading={selectedLoading || optionsLoading}
      value={value}
      onOpen={(e) => setSearch('')}
      search
      searchQuery={search || ''}
      onSearchChange={(e) => setSearch(e.target.value)}
      placeholder="Start to write name..."
      {...restProps}
    />
  );
});
