import React, { useCallback, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { CryptoBuySellCorridorConfiguration } from '../../../../types/models/CryptoBuySellCorridorConfiguration';
import { titleCase } from '../../../../utils/string';
import ListToString from '../../../../components/ListToString';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import useCountries from '../../../../hooks/useCountries';
import useCurrencies from '../../../../hooks/useCurrencies';

export interface CryptoBuySellCorridorViewProps {
  configuration: CryptoBuySellCorridorConfiguration;
  transferDirection: keyof typeof TransferDirection;
}

export default function CryptoBuySellCorridorConfigurationHeader({
  configuration,
  transferDirection,
}: CryptoBuySellCorridorViewProps): JSX.Element {
  const { countries } = useCountries({ withGroups: true });
  const { currencies } = useCurrencies();

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  const country = countries.find((c) => c.id === configuration.countryId);

  const currencyFromTitles = configuration.currencyFromIds.map(
    (currencyId) => currencies.find((c) => c.id === currencyId)?.label || '',
  );
  const currencyToTitles = configuration.currencyToIds.map(
    (currencyId) => currencies.find((c) => c.id === currencyId)?.label || '',
  );

  const paymentMethods = configuration.paymentMethods.map(titleCase);
  const payoutMethods = configuration.payoutMethods.map(titleCase);
  const { paymentDetails, payoutDetails } = configuration;
  return (
    <Grid
      divided
      className="crypto_form_collect__configuration_header"
      onClick={toggleExpanded}
    >
      <Grid.Row columns={7}>
        <div />
        <Grid.Column data-qa="country">
          <b>Country</b> <br />
          {country?.label}
        </Grid.Column>
        <Grid.Column data-qa="currency-from">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Buy Currency'
              : 'Sell Currency'}
          </b>{' '}
          <br />
          {expanded ? (
            currencyFromTitles.join(', ')
          ) : (
            <ListToString list={currencyFromTitles} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="currency-to">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Sell Currency'
              : 'Buy Currency'}
          </b>{' '}
          <br />
          {expanded ? (
            currencyToTitles.join(', ')
          ) : (
            <ListToString list={currencyToTitles} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="payment-method">
          <b>Payment Method</b> <br />
          {expanded ? (
            paymentMethods.join(', ')
          ) : (
            <ListToString list={paymentMethods} />
          )}
          {paymentDetails.length ? ` (${paymentDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="payout-method">
          <b>Payout Method</b> <br />
          {expanded ? (
            payoutMethods.join(', ')
          ) : (
            <ListToString list={payoutMethods} />
          )}
          {payoutDetails.length ? ` (${payoutDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="customer-type">
          <b>Customer Type</b> <br />
          {titleCase(configuration.customerType)}
        </Grid.Column>
        <Grid.Column data-qa="transaction-type">
          <b>Transaction Type</b> <br />
          {titleCase(configuration.transactionType)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
