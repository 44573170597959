import React, { useCallback, useState } from 'react';
import { Button, Card, Grid, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { FeeCorridor } from '../../../types/models/FeeCorridor';
import FeeRangesTable from './FeeRangesTable';
import { SetStateCallback } from '../../../types/utility';
import { FeeRange } from '../../../types/models/FeeRange';
import FeeCorridorConfigurationHeader from './FeeCorridorConfigurationHeader';
import { ValidationErrors } from '../../../types/models/ValidationErrors';
import { TransferDirection } from '../../../types/enums/TransferDirection';

export type ChangedFeeCorridor = Partial<
  Omit<FeeCorridor, 'id' | 'configuration'>
>;

interface FeeCorridorViewProps {
  corridor: FeeCorridor;
  transferDirection: keyof typeof TransferDirection;
  onChange: (id: FeeCorridor['id'], changed: Partial<FeeCorridor>) => void;
  onEditConfiguration: (corridor: FeeCorridor) => void;
  onCopy: (corridor: FeeCorridor) => void;
  onDelete: (id: FeeCorridor['id']) => void;
  readOnly: boolean;
  validationErrors?: ValidationErrors;
}

export default function FeeCorridorView({
  corridor,
  onChange,
  onEditConfiguration,
  onCopy,
  onDelete,
  transferDirection,
  readOnly = false,
  validationErrors = {},
}: FeeCorridorViewProps): JSX.Element {
  const setRanges = useCallback(
    (func: SetStateCallback<FeeRange>) => {
      const ranges = func(corridor.ranges);
      const changed: ChangedFeeCorridor = { ranges };
      onChange(corridor.id, changed);
    },
    [corridor, onChange],
  );
  const onClickCopy = useCallback(() => {
    onCopy(corridor);
  }, [corridor, onCopy]);
  const onClickDelete = useCallback(() => {
    onDelete(corridor.id);
  }, [corridor.id, onDelete]);
  const onClickEdit = useCallback(() => {
    onEditConfiguration(corridor);
  }, [corridor, onEditConfiguration]);

  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  const classNameExpanded = classNames({
    form_fee_collect__corridor_expand: true,
    'form_fee_collect__corridor_expand--expanded': expanded,
  });
  return (
    <Card fluid>
      <div className="form_fee_collect__corridor_header">
        <div className={classNameExpanded} onClick={toggleExpanded}>
          <Icon name="dropdown" />
        </div>
        <FeeCorridorConfigurationHeader
          configuration={corridor}
          transferDirection={transferDirection}
        />
      </div>
      {expanded && (
        <>
          <FeeRangesTable
            ranges={corridor.ranges}
            setRanges={setRanges}
            amountCurrencyId={corridor.amountCurrencyId}
            readOnly={readOnly}
            validationErrors={
              validationErrors.rangesErrors as ValidationErrors | undefined
            }
          />
          {!readOnly && (
            <div className="form_fee_collect__corridor_footer">
              <Grid>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column floated="left" width="6">
                    <Button
                      data-qa="clone-table"
                      onClick={onClickCopy}
                      type="button"
                    >
                      Clone Table
                    </Button>
                  </Grid.Column>
                  <Grid.Column floated="right" width="6" textAlign="right">
                    <div style={{ marginBottom: '1em' }}>
                      <Button
                        data-qa="edit-table-settings"
                        onClick={onClickEdit}
                        type="button"
                      >
                        Edit Table Settings
                      </Button>
                    </div>
                    <div>
                      <Button
                        data-qa="delete-table"
                        onClick={onClickDelete}
                        type="button"
                      >
                        Delete Table
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </>
      )}
    </Card>
  );
}
