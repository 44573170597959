import isEmpty from 'lodash/isEmpty';
import { CryptoInOutCorridorConfiguration } from './CryptoInOutCorridorConfiguration';
import { CryptoInOutRange, validateCryptoInOutRange } from './CryptoInOutRange';
import { AmountType } from '../enums/AmountType';
import { ValidationErrors } from './ValidationErrors';
import { toNumber } from '../../utils/number';

export interface CryptoInOutCorridor extends CryptoInOutCorridorConfiguration {
  id: number;
  typeAmounts: keyof typeof AmountType;
  collectedAtLocal: Date;
  ranges: CryptoInOutRange[];
}

export interface CreateCryptoInOutCorridorParams
  extends CryptoInOutCorridorConfiguration {
  typeAmounts?: keyof typeof AmountType;
  collectedAtLocal?: Date;
  ranges?: CryptoInOutRange[];
}

let ID_SEQUENCE = 0;
export function createCryptoInOutCorridor({
  ranges = [],
  collectedAtLocal = new Date(),
  typeAmounts = AmountType.RANGE,
  ...params
}: CreateCryptoInOutCorridorParams): CryptoInOutCorridor {
  ID_SEQUENCE -= 1;
  return {
    ...params,
    typeAmounts,
    collectedAtLocal,
    ranges,
    id: ID_SEQUENCE,
  };
}

const maxRangeGap = 0.01;

export function validateCryptoInOutCorridor(
  data: CryptoInOutCorridor,
): ValidationErrors {
  const errors: ValidationErrors = {};

  if (!data.collectedAtLocal) {
    errors.collectedAtLocal = 'Please fill in this field';
  }

  const defaultFeeCurrencyId = getDefaultFeeCurrencyId(data);
  const rangesErrors: ValidationErrors = {};
  const sortedRanges = data.ranges
    .slice()
    .sort((a, b) => Number(a.amountFrom) - Number(b.amountFrom));
  if (data.typeAmounts === AmountType.SINGLE) {
    sortedRanges.forEach((range, index) => {
      const err = validateCryptoInOutRange(
        range,
        defaultFeeCurrencyId,
        data.typeAmounts,
      );
      const rangeHaveOverlapping = sortedRanges.some(
        (r, i) => i !== index && range.amountFrom == r.amountFrom,
      );
      if (rangeHaveOverlapping) {
        err.overlapping = 'Duplicate amount';
      }
      if (!isEmpty(err)) {
        rangesErrors[index] = err;
      }
    });
  } else if (data.typeAmounts === AmountType.RANGE) {
    sortedRanges.forEach((range, index) => {
      const err = validateCryptoInOutRange(
        range,
        defaultFeeCurrencyId,
        data.typeAmounts,
      );
      const rangeHaveOverlapping = sortedRanges.some(
        (r, i) => i !== index && haveOverlappingRanges(range, r),
      );
      if (rangeHaveOverlapping) {
        err.overlapping =
          'Amount Ranges cannot intersect - You need to change Amount From or Amount To';
      }
      const nextRange = sortedRanges[index + 1];
      const rangeHaveBigGap =
        nextRange && haveBigGapBetweenRanges(range, nextRange);
      if (rangeHaveBigGap) {
        err.bigGap = `The gap between amount rages must ${maxRangeGap}. For example (Amount From 0, Amount To 100), (Amount From 100.𝟎𝟏, Amount To: 200).`;
      }
      if (!isEmpty(err)) {
        rangesErrors[index] = err;
      }
    });
  }
  if (!isEmpty(rangesErrors)) {
    errors.rangesErrors = rangesErrors;
  }
  return errors;
}

function haveOverlappingRanges(
  a: CryptoInOutRange,
  b: CryptoInOutRange,
): boolean {
  const a1 = toNumber(a.amountFrom, 0);
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  const b2 = toNumber(b.amountTo, Infinity);
  if (b1 <= a1 && a1 <= b2) {
    return true;
  }
  if (b1 <= a2 && a2 <= b2) {
    return true;
  }
  return false;
}

function haveBigGapBetweenRanges(a: CryptoInOutRange, b: CryptoInOutRange) {
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  return b1 - a2 > maxRangeGap + maxRangeGap / 10 ** 6;
}

export function getDefaultFeeCurrencyId(corridor: CryptoInOutCorridorConfiguration): number | undefined {
  return corridor.currencyIds.length === 1 ? corridor.currencyIds[0] : undefined
}