export function toNumber(value: any, defaultValue = NaN): number {
  switch (typeof value) {
    case 'number':
      return value;
    case 'string':
      if (value.length === 0) {
        return defaultValue;
      }
      return Number(value);
    default:
      return defaultValue;
  }
}
