import React, { useCallback, useMemo } from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import uniq from 'lodash/uniq';
import useCountries from '../hooks/useCountries';
import { ALL_COUNTRIES_ID, ALL_OTHERS_ID } from '../constants/countryGroups';

interface CountrySelectProps extends Omit<SelectProps, 'options'> {
  labelField?: 'name' | 'iso2Code' | 'iso3Code';
  transformOptions?: (options: DropdownItemProps[]) => DropdownItemProps[];
  onChange?: (event: any, inputProps: any) => void;
  readOnly?: boolean;
  withGroups?: boolean;
}

const displayName = 'CountrySelect';
const CountrySelect: React.FC<CountrySelectProps> = ({
  placeholder = 'Start to write name...',
  labelField = 'name',
  transformOptions = (options) => options,
  onChange = () => undefined,
  readOnly = false,
  withGroups = false,
  ...restProps
}: CountrySelectProps) => {
  const { countries, loading } = useCountries({ withGroups, labelField });

  const options = useMemo(() => {
    if (!countries) return [];
    const options: DropdownItemProps[] = countries.map((o) => ({
      key: o.id,
      value: o.id,
      text: o.label,
      defaultcurrencyid: o.defaultCurrencyId,
    }));
    return transformOptions(options);
  }, [countries, transformOptions]);

  const onChangeWithGroups = useCallback(
    (event, inputProps) => {
      const { value, options } = inputProps;
      const newInputProps = { ...inputProps };
      if (typeof value === 'object' && value.includes(ALL_OTHERS_ID)) {
        newInputProps.value = [ALL_OTHERS_ID];
        newInputProps.defaultCurrencyId = null;
      } else if (
        typeof value === 'object' &&
        value.includes(ALL_COUNTRIES_ID)
      ) {
        newInputProps.value = [ALL_COUNTRIES_ID];
        newInputProps.defaultCurrencyId = null;
      } else {
        const currencies = uniq(
          options
            .filter(
              (o: any) =>
                value === o.value ||
                (typeof value === 'object' && value.includes(o.value)),
            )
            .map((o: any) => o.defaultcurrencyid),
        );
        // we need currency which use in all selected countries
        newInputProps.defaultCurrencyId =
          currencies.length === 1 ? currencies[0] : null;
      }
      onChange(event, newInputProps);
    },
    [onChange],
  );

  return (
    <Form.Select
      options={options}
      loading={loading}
      search={!readOnly}
      placeholder={placeholder}
      open={readOnly ? false : undefined}
      onChange={readOnly ? () => undefined : onChangeWithGroups}
      {...restProps}
    />
  );
};

export default Object.assign(React.memo(CountrySelect), { displayName });
