import React, { useCallback, useMemo } from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import useCurrencies from '../hooks/useCurrencies';

interface CurrencySelectProps extends Omit<SelectProps, 'options'> {
  transformOptions?: (options: DropdownItemProps[]) => DropdownItemProps[];
  onChange?: (event: any, inputProps: any) => void;
  readOnly?: boolean;
}

const displayName = 'CurrencySelect';
const CurrencySelect: React.FC<CurrencySelectProps> = ({
  placeholder = 'Start to write name...',
  transformOptions = (options) => options,
  onChange = () => undefined,
  readOnly = false,
  ...restProps
}: CurrencySelectProps) => {
  const { currencies, loading } = useCurrencies();

  const options = useMemo(() => {
    if (!currencies) return [];
    const options: DropdownItemProps[] = currencies.map((o: any) => ({
      key: o.id,
      value: o.id,
      text: o.label,
    }));

    const finalOptions = restProps.local
      ? [
          {
            key: 'null',
            value: 0,
            text: 'Local Currencies',
          },
          ...options,
        ]
      : options;

    return transformOptions([...finalOptions]);
  }, [currencies, transformOptions]);

  const rightOnChange = useCallback(
    (event: any, inputProps: any): void => {
      if (typeof inputProps.value === 'string') {
        inputProps.value = null;
      }
      onChange(event, inputProps);
    },
    [onChange],
  );

  return (
    <Form.Select
      options={options}
      loading={loading}
      placeholder={placeholder}
      search={!readOnly}
      open={readOnly ? false : undefined}
      onChange={readOnly ? () => undefined : rightOnChange}
      {...restProps}
    />
  );
};

export default Object.assign(React.memo(CurrencySelect), { displayName });
