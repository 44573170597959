import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { CustomerType } from '../types/enums/CustomerType';
import { enumToSelectOptions } from '../utils/components';

interface CustomerTypeSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof CustomerType | (keyof typeof CustomerType)[];
}

const options = enumToSelectOptions(CustomerType);

export default React.memo((props: CustomerTypeSelectProps) => (
  <Form.Select options={options} {...props} />
));
