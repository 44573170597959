import React, { useMemo, useState, useEffect } from 'react';
import { Header, Button, Form, Grid } from 'semantic-ui-react';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isArrayLike from 'lodash/isArrayLike';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import saveTypes from '../../constants/saveTypes';
import Loader from '../../components/Loader';
import Page404 from '../../components/Page404';

function UserEditForm(props) {
  const { header, data, onChange, onSubmit } = props;
  function canBeSubmit() {
    const requiredFields = ['name', 'email', 'role'];
    if (!data.id) {
      requiredFields.push('password');
    }
    for (const fieldName of requiredFields) {
      const fieldValue = data[fieldName];
      if (!fieldValue) {
        return false;
      }
      if (typeof fieldValue === 'string' && trim(fieldValue).length === 0) {
        return false;
      }
      if (isArrayLike(fieldValue) && isEmpty(fieldValue)) {
        return false;
      }
    }
    return true;
  }

  function onBlurInput(event) {
    const { name, value } = event.target;
    onChange({ [name]: trim(value) });
  }

  function onChangeInput(event, inputProps) {
    const { name, value } = inputProps;
    onChange({ [name]: value });
  }

  function onChangeCheckbox(event, inputProps) {
    const { name, checked } = inputProps;
    onChange({ [name]: checked });
  }

  const rolesOptions = useMemo(() => {
    const roles = ['ADMIN', 'SUPER_ADMIN'];
    return roles.map((role) => ({
      key: role,
      value: role,
      text: capitalize(role.replace('_', ' ')),
    }));
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(saveTypes.APPLY);
      }}
      autoComplete="off"
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">{header}</Header>
            <br />
            <Form.Group widths="equal">
              <Form.Input
                label="User Name"
                name="name"
                value={data.name}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                required
              />
              <Form.Select
                label="Role"
                name="role"
                value={data.role}
                onChange={onChangeInput}
                options={rolesOptions}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Email"
                name="email"
                type="email"
                value={data.email}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                required
              />
              <Form.Input
                label="Password"
                name="password"
                type="password"
                value={data.password}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                required={!data.id}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                label="Is active"
                name="isActive"
                checked={data.isActive}
                onChange={onChangeCheckbox}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            <Button
              type="submit"
              color="blue"
              onClick={(e) => {
                e.preventDefault();
                onSubmit(saveTypes.APPLY);
              }}
              disabled={!canBeSubmit()}
            >
              Apply
            </Button>
            <Button
              type="submit"
              color="blue"
              onClick={(e) => {
                e.preventDefault();
                onSubmit(saveTypes.SAVE);
              }}
              disabled={!canBeSubmit()}
            >
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

const USER_FIELDS = gql`
  fragment UserEditFields on User {
    id
    email
    name
    role
    isActive
    createdAt
    updatedAt
  }
`;

const GET_USER = gql`
  query GetUser($userId: Int) {
    user(where: { id: $userId }) {
      ...UserEditFields
    }
  }
  ${USER_FIELDS}
`;

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $name: String!
    $password: String!
    $role: UserRole!
    $isActive: Boolean!
  ) {
    user: createUser(
      data: {
        email: $email
        name: $name
        password: $password
        role: $role
        isActive: $isActive
      }
    ) {
      ...UserEditFields
    }
  }
  ${USER_FIELDS}
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $email: String!
    $name: String!
    $password: String!
    $role: UserRole!
    $isActive: Boolean!
  ) {
    user: updateUser(
      data: {
        email: { set: $email }
        name: { set: $name }
        password: { set: $password }
        role: { set: $role }
        isActive: { set: $isActive }
      }
      where: { id: $id }
    ) {
      ...UserEditFields
    }
  }
  ${USER_FIELDS}
`;

export function useUser(userId, initState = {}) {
  const [user, setUser] = useState({
    email: '',
    name: '',
    password: '',
    role: 'ADMIN',
    isActive: true,
    ...initState,
  });
  const [fetchUser, { loading, error }] = useLazyQuery(GET_USER, {
    onCompleted: (data) => setUser((prev) => ({ ...prev, ...data.user })),
  });
  const [createUser] = useMutation(CREATE_USER, {
    onError: (error) => {},
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (error) => {},
  });

  function saveUser(options) {
    const isNew = !isFinite(user.id);
    return isNew ? createUser(options) : updateUser(options);
  }

  useEffect(() => {
    if (isFinite(userId)) {
      fetchUser({ variables: { userId: parseInt(userId) } });
    }
  }, [userId]);

  return {
    user,
    loading,
    error,
    setUser,
    createUser,
    updateUser,
    saveUser,
  };
}

export default function UserEdit(props) {
  const { userId } = useParams();
  const history = useHistory();

  const { loading, user, setUser, saveUser } = useUser(userId);

  if (loading) return <Loader isLoading={loading} />;
  if (!user) return <Page404 />;

  const header = user.id ? `Edit User #${user.id}` : 'New User';

  function onChange(updated) {
    setUser((prev) => ({ ...prev, ...updated }));
  }

  async function onSubmit(saveType) {
    const result = await saveUser({ variables: user });
    if (!result) return;
    const {
      data: {
        user: { id },
      },
    } = result;
    if (saveType === saveTypes.SAVE) {
      history.push('/users');
    } else {
      history.replace(`/users/${id}`);
    }
  }

  return (
    <div>
      <UserEditForm
        header={header}
        data={user}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </div>
  );
}
