import React, { useMemo } from 'react';
import './UsersList.scss';
import { Grid, Header, Pagination } from 'semantic-ui-react';
import cn from 'classnames';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import formatDate from 'date-fns/format';
import capitalize from 'lodash/capitalize';
import Loader from '../../components/Loader';
import SortableTable from '../../components/SortableTable';
import QueryComposer from '../../components/QueryComposer';
import HeaderButton from '../../components/HeaderButton';
import { dateTimeFormat } from '../../constants/dateTimeFormat';

const COLUMNS = {
  ID: 'ID',
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  ROLE: 'ROLE',
  IS_ACTIVE: 'IS_ACTIVE',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

const columnsConfig = {
  keyAttribute: 'id',
  defaultText: '-',
  columns: [
    COLUMNS.ID,
    COLUMNS.NAME,
    COLUMNS.EMAIL,
    COLUMNS.ROLE,
    COLUMNS.IS_ACTIVE,
    COLUMNS.CREATED_AT,
    COLUMNS.UPDATED_AT,
  ],
  config: {
    [COLUMNS.ID]: {
      label: 'Id',
      sortable: true,
      dataAttribute: 'id',
    },
    [COLUMNS.EMAIL]: {
      label: 'Email',
      sortable: true,
      dataAttribute: 'email',
    },
    [COLUMNS.NAME]: {
      label: 'Name',
      sortable: true,
      dataAttribute: 'name',
      customRender: true,
    },
    [COLUMNS.ROLE]: {
      label: 'Role',
      sortable: true,
      dataAttribute: 'role',
      customRender: true,
    },
    [COLUMNS.IS_ACTIVE]: {
      label: 'Is Active',
      sortable: true,
      dataAttribute: 'isActive',
      customRender: true,
    },
    [COLUMNS.CREATED_AT]: {
      label: 'Created At',
      sortable: true,
      dataAttribute: 'createdAt',
      customRender: true,
    },
    [COLUMNS.UPDATED_AT]: {
      label: 'Updated At',
      sortable: true,
      dataAttribute: 'updatedAt',
      customRender: true,
    },
  },
};

function renderNameColumn(column, item) {
  const { config } = columnsConfig;
  const idAttribute = config[COLUMNS.ID].dataAttribute;
  const currentAttribute = config[column].dataAttribute;

  return (
    <Link to={`/users/${item[idAttribute]}`} data-qa="user-name">
      {item[currentAttribute] || 'No Name'}
    </Link>
  );
}

function renderDateColumn(column, item) {
  const { dataAttribute } = columnsConfig.config[column];
  return formatDate(new Date(item[dataAttribute]), dateTimeFormat);
}

function renderCheckboxColumn(column, item) {
  const { dataAttribute } = columnsConfig.config[column];
  const iconClassName = cn('icon square outline checkbox-icon', {
    check: item[dataAttribute],
  });
  return <i className={iconClassName} />;
}

function renderRoleColumn(column, item) {
  const { dataAttribute } = columnsConfig.config[column];
  const value = item[dataAttribute];
  return capitalize(value.replace('_', ' '));
}

function renderItem(column, item) {
  switch (column) {
    case COLUMNS.NAME:
      return renderNameColumn(column, item);
    case COLUMNS.ROLE:
      return renderRoleColumn(column, item);
    case COLUMNS.IS_ACTIVE:
      return renderCheckboxColumn(column, item);
    case COLUMNS.CREATED_AT:
    case COLUMNS.UPDATED_AT:
      return renderDateColumn(column, item);
    default:
      return null;
  }
}

const GET_USER_LIST = gql`
  query GetUserList(
    $offset: Int
    $perPage: Int
    $orderBy: UserOrderByWithRelationInput!
  ) {
    items: users(skip: $offset, take: $perPage, orderBy: [$orderBy]) {
      id
      email
      name
      role
      isActive
      createdAt
      updatedAt
    }
    total: aggregateUser {
      _count {
        _all
      }
    }
  }
`;

function UsersTable(props) {
  const page = props.page || 1;
  const perPage = props.perPage || 10;
  const offset = (page - 1) * perPage;
  const { sortColumn, sortOrder } = props;
  const orderBy = useMemo(() => {
    if (!sortColumn || !sortOrder) {
      return { id: 'desc' };
    }
    const { config } = columnsConfig;
    const attribute = config[sortColumn].dataAttribute || 'id';
    let order = sortOrder.toLowerCase();
    if (!['asc', 'desc'].includes(order)) {
      order = 'asc';
    }
    return {
      [attribute]: order,
    };
  }, [sortColumn, sortOrder]);

  const { loading, error, data } = useQuery(GET_USER_LIST, {
    variables: {
      offset,
      perPage,
      orderBy,
    },
  });

  if (loading) return <Loader isLoading />;
  if (error) return `Error! ${error.message}`;

  const pages = Math.ceil(data.total._count._all / perPage);

  return (
    <>
      <SortableTable
        items={data.items}
        columnsConfig={columnsConfig}
        renderItem={renderItem}
        isExternalSort
        onSortChange={props.onSortChange}
        sortColumn={props.sortColumn}
        sortOrder={props.sortOrder}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Pagination
              activePage={page}
              totalPages={pages || 1}
              onPageChange={(_, data) => props.setPage(data.activePage)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default function UsersList(props) {
  return (
    <div className="users-list">
      <QueryComposer>
        {(query, setQuery) => (
          <>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column className="flex align-items-center">
                  <Header size="large">Users List</Header>
                </Grid.Column>
                <Grid.Column className="flex justify-content-end align-items-center spaced-items">
                  <HeaderButton as={Link} to="/users/new">
                    Create User
                  </HeaderButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UsersTable
              page={query.page || 1}
              perPage={10}
              setPage={(page) => setQuery({ page })}
              onSortChange={({ sortColumn, sortOrder }) =>
                setQuery({
                  sortBy: sortColumn,
                  sortOrder,
                })
              }
              sortColumn={query.sortBy}
              sortOrder={query.sortOrder}
            />
          </>
        )}
      </QueryComposer>
    </div>
  );
}
