import React from 'react';
import { Loader as UILoader } from 'semantic-ui-react';
import './Loader.scss';

function Loader(props) {
  const { isLoading, ...forwardedProps } = props;
  return (
    <UILoader
      active={isLoading}
      size="huge"
      content="Loading..."
      {...forwardedProps}
    />
  );
}

export default Loader;
