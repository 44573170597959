import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import CryptoInOutCorridorConfigurationForm from './CryptoInOutCorridorConfigurationForm';
import {
  CryptoInOutCorridorConfiguration,
  StateCryptoInOutCorridorConfiguration,
  validateCryptoInOutCorridorConfiguration,
} from '../../../../types/models/CryptoInOutCorridorConfiguration';
import { TransferMethod } from '../../../../types/enums/TransferMethod';
import { PayoutMethod } from '../../../../types/enums/PayoutMethod';
import { PaymentMethod } from '../../../../types/enums/PaymentMethod';
import { CustomerType } from '../../../../types/enums/CustomerType';
import { TransferDirection } from '../../../../types/enums/TransferDirection';

interface CryptoInOutCorridorCreateFormProps {
  defaultValues?: Partial<CryptoInOutCorridorConfiguration>;
  existsConfigurations?: CryptoInOutCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onSubmit: (corridorConfig: CryptoInOutCorridorConfiguration) => void;
  onClose: () => void;
}

export default function CryptoInOutCorridorCreateForm({
  defaultValues = undefined,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: CryptoInOutCorridorCreateFormProps): JSX.Element {
  const initialState = useMemo<StateCryptoInOutCorridorConfiguration>(
    () => ({
      ...{
        countryId: null,
        currencyIds: [],
        direction: [],
        paymentMethods: [PaymentMethod.BANK_ACCOUNT],
        payoutMethods: [PayoutMethod.BANK_ACCOUNT],
        paymentDetails: '',
        payoutDetails: '',
        customerType: CustomerType.NOT_SPECIFIED,
      },
      ...(defaultValues || {}),
    }),
    [defaultValues],
  );

  const [state, setState] =
    useState<StateCryptoInOutCorridorConfiguration>(initialState);
  const validationErrors = useMemo(
    () => validateCryptoInOutCorridorConfiguration(state, existsConfigurations),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev: any) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    onSubmit(state as CryptoInOutCorridorConfiguration);
    setState(initialState);
  }, [onSubmit, state, setState, initialState]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Add New Table" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <CryptoInOutCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
                data-qa="add-table"
              >
                Add Table
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
