import React from 'react';
import { Message, Loader } from 'semantic-ui-react';

import './Saving.scss';

const Saving = ({ variant, children }) => {
  return (
    <Message
      info={variant === 'info'}
      error={variant === 'error'}
      success={variant === 'success'}
      className="saving"
      data-qa="notification-msg"
    >
      <Loader active inline size="mini" style={{ marginRight: '1em' }} />
      <b>{children}</b>
    </Message>
  );
};

export default Saving;
