import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import get from 'lodash/get';
import { createUploadLink } from 'apollo-upload-client';
import storage from '../utils/storage';
import store from '../store';
import errorHandlerSlice from '../store/errorHandler/slice';

const backendUri = process.env.REACT_APP_API_URL;

const notifiedErrors = [
  'INTERNAL_SERVER_ERROR',
  'GRAPHQL_PARSE_FAILED',
  'GRAPHQL_VALIDATION_FAILED',
  'UNAUTHENTICATED',
  'FORBIDDEN',
  'PERSISTED_QUERY_NOT_FOUND',
  'PERSISTED_QUERY_NOT_SUPPORTED',
  'BAD_USER_INPUT',
];

const link = onError(({ response, networkError }) => {
  const message =
    get(response, 'errors.0.message') || get(networkError, 'message');
  const code = get(response, 'errors.0.extensions.code', null);
  if (code && !notifiedErrors.includes(code)) {
    return;
  }
  store.dispatch(errorHandlerSlice.actions.failure({ message, code }));
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = storage.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createUploadLink({
  uri: backendUri,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      FormRate: {
        fields: {
          amounts: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([link, authLink.concat(httpLink)]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export { backendUri };
export default client;
