import React from 'react';
import { Link } from 'react-router-dom';

export default function (props) {
  const { user, logout } = props;
  return (
    <>
      {user.role === 'SUPER_ADMIN' ? (
        <span className="header-item">
          <i className="users icon" />
          <Link to="/users">Users</Link>
        </span>
      ) : null}
      <span className="header-item">
        <i className="tasks icon" />
        <Link to="/collections">Collections</Link>
      </span>
      <div className="header-item-separator" />
      <span className="header-item">
        <i className="user icon" />
        {user.name || user.email}
      </span>
      <span className="header-item">
        <i className="sign-out icon" />
        <span className="action-link" onClick={logout} data-qa="logout">
          Logout
        </span>
      </span>
    </>
  );
}
