import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from '../utils/qs';

class QueryComposer extends React.Component {
  static defaultProps = {
    onChange: () => {},
  };

  static checkStateEqual = (prevState, curState) =>
    Object.keys(curState).length === Object.keys(prevState).length &&
    Object.entries(curState).every(([key, value]) => prevState[key] === value);

  static checkLocationSearchEqual = (prevLocation, curLocation) =>
    prevLocation.search === curLocation.search;

  constructor(props) {
    super(props);
    this.state = {
      query: qs.parse(props.location.search),
    };
  }

  componentDidMount() {
    const { query } = this.state;
    const { onChange } = this.props;
    onChange(query);
  }

  componentDidUpdate(prevProps, prevState) {
    const { query: prevQuery } = prevState;
    const { location: prevLocation } = prevProps;
    const { query: curQuery } = this.state;
    const { location: curLocation, onChange } = this.props;

    const isStateEqual = QueryComposer.checkStateEqual(prevQuery, curQuery);
    const isSearchEqual = QueryComposer.checkLocationSearchEqual(
      prevLocation,
      curLocation,
    );

    if (isStateEqual && !isSearchEqual) {
      this.setQuery(qs.parse(curLocation.search), true);
    }

    if (!isStateEqual) onChange(curQuery);
  }

  getFilteredQuery = (query, excludedKeys) =>
    Object.entries(query).reduce(
      (acc, [key, val]) =>
        excludedKeys.includes(key) ? acc : { ...acc, [key]: val },
      {},
    );

  setQuery = (query, isFromLocation = false) => {
    const { history, location } = this.props;
    const { query: prevQuery } = this.state;

    if (isFromLocation) {
      this.setState({ query });
      return;
    }

    const resetKeys = Object.keys(query).filter((key) => query[key] == null);

    const newQuery = {
      ...this.getFilteredQuery(prevQuery, resetKeys),
      ...this.getFilteredQuery(query, resetKeys),
    };

    const queryString = qs.stringify(newQuery);
    history.replace(`${location.pathname}${queryString}`);

    this.setState({
      query: newQuery,
    });
  };

  render() {
    const { children } = this.props;
    const { query } = this.state;
    return children(query, this.setQuery);
  }
}

export default withRouter(QueryComposer);
