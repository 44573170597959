import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../scenes/Login/Login';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      auth ? <Component {...componentProps} {...props} /> : <Login />
    }
  />
);
export default PrivateRoute;
