import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import CryptoInOutCorridorConfigurationForm from './CryptoInOutCorridorConfigurationForm';
import {
  CryptoInOutCorridorConfiguration,
  StateCryptoInOutCorridorConfiguration,
  validateCryptoInOutCorridorConfiguration,
} from '../../../../types/models/CryptoInOutCorridorConfiguration';
import { CryptoInOutCorridor } from '../../../../types/models/CryptoInOutCorridor';
import { TransferDirection } from '../../../../types/enums/TransferDirection';

interface CryptoInOutCorridorEditFormProps {
  corridor: CryptoInOutCorridor;
  existsConfigurations?: CryptoInOutCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (
    corridorId: number,
    config: CryptoInOutCorridorConfiguration,
  ) => void;
}

export default function CryptoInOutCorridorEditForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: CryptoInOutCorridorEditFormProps): JSX.Element {
  const [state, setState] =
    useState<StateCryptoInOutCorridorConfiguration>(corridor);
  const validationErrors = useMemo(
    () =>
      validateCryptoInOutCorridorConfiguration(
        state as CryptoInOutCorridorConfiguration,
        existsConfigurations,
      ),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev: any) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedRanges = state.ranges.map((range) => ({
      ...range,
    }));
    const updatedState = { ...state, ...{ ranges: updatedRanges } };
    setState(updatedState);

    onSubmit(corridor.id, updatedState as CryptoInOutCorridorConfiguration);
    onClose();
  }, [onSubmit, onClose, state, corridor.id]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Edit Table Settings" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <CryptoInOutCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
                data-qa="save-settings"
              >
                Save Settings
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
