import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Header,
  Button,
  Form,
  Grid,
  Input,
  Label,
  Message,
} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isArrayLike from 'lodash/isArrayLike';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import copy from 'copy-to-clipboard';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import saveTypes from '../../constants/saveTypes';
import CountrySelect from '../../components/CountrySelect';
import CurrencySelect from '../../components/CurrencySelect';
import { collectRateBaseUrl } from '../../constants/collectBaseUrl';
import OrganizationSelect from '../../components/OrganizationSelect';
import CollectionSelect from '../../components/CollectionSelect';
import Loader from '../../components/Loader';
import ClientTypeSelect from '../../components/ClientTypeSelect';
import TransferDirectionSelect from '../../components/TransferDirectionSelect';
import Page404 from '../../components/Page404';
import { FormType } from '../../types/enums/FormType';

function FormRateEditForm(props) {
  const { header, data, onChange, onSubmit, changeSlug } = props;
  const hasSubmittedAlready = data.submission && !!data.submission.submitted_at;

  const [repeatsOfAmounts, setRepeatsOfAmounts] = useState([]);
  const findRepeatsOfAmounts = useCallback(() => {
    setRepeatsOfAmounts(
      filter(data.amounts, (val, i, iteratee) =>
        includes(iteratee, val, i + 1),
      ),
    );
  }, [data.amounts, setRepeatsOfAmounts]);

  const canBeSubmit = useMemo(() => {
    if (hasSubmittedAlready || repeatsOfAmounts.length > 0) {
      return false;
    }
    const requiredFields = [
      'collectionId',
      'researcherName',
      'clientType',
      'transferDirection',
      'organizationId',
      'countryId',
      'currencyFromId',
      'currencyToIds',
      'amounts',
      'slug',
    ];
    for (const fieldName of requiredFields) {
      const fieldValue = data[fieldName];
      if (!fieldValue) {
        return false;
      }
      if (typeof fieldValue === 'string' && trim(fieldValue).length === 0) {
        return false;
      }
      if (isArrayLike(fieldValue) && isEmpty(fieldValue)) {
        return false;
      }
    }
    return true;
  }, [data, hasSubmittedAlready, repeatsOfAmounts]);

  const onBlurInput = useCallback(
    (event) => {
      const { name, value } = event.target;
      onChange({ [name]: trim(value) });
    },
    [onChange],
  );

  const onChangeInput = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      onChange({ [name]: value });
    },
    [onChange],
  );

  const onGenerateSlug = useCallback(() => {
    if (hasSubmittedAlready) {
      return;
    }
    if (window.confirm('Do you want to generate a new URL for this form?')) {
      changeSlug();
    }
  }, [hasSubmittedAlready, changeSlug]);

  const copyLinkToClipboard = useCallback(() => {
    const fullUrl = collectRateBaseUrl + data.slug;
    copy(fullUrl);
  }, [data.slug]);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(saveTypes.SAVE);
    },
    [onSubmit],
  );

  const onApply = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(saveTypes.APPLY);
    },
    [onSubmit],
  );

  return (
    <Form onSubmit={onApply} autoComplete="off">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">{header}</Header>
            <Form.Group widths="2">
              <CollectionSelect
                data-qa="collection-name"
                name="collectionId"
                value={data.collectionId}
                formType={FormType.RATE}
                onChange={onChangeInput}
                required
                search
                style={useMemo(() => ({ width: 'auto' }), [])}
              />
            </Form.Group>
            <br />
            {hasSubmittedAlready && (
              <>
                <Message
                  info
                  icon="inbox"
                  header="This form has now been submitted"
                  content="Changes are no longer possible."
                />
                <br />
              </>
            )}
            <Form.Group widths="2">
              <Form.Input
                data-qa="researcher-name"
                label="Researcher name"
                name="researcherName"
                value={data.researcherName}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                required
              />
              <ClientTypeSelect
                data-qa="client_type"
                label="Client type"
                name="clientType"
                value={data.clientType}
                onChange={onChangeInput}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Bank Name</label>
                <OrganizationSelect
                  data-qa="bank_name"
                  name="organizationId"
                  value={data.organizationId}
                  onChange={onChangeInput}
                />
              </Form.Field>
              <CountrySelect
                data-qa="country"
                label="Country"
                name="countryId"
                value={data.countryId}
                onChange={onChangeInput}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <CurrencySelect
                data-qa="currency_from"
                label="Currency From"
                name="currencyFromId"
                value={data.currencyFromId}
                onChange={onChangeInput}
                required
              />
              <CurrencySelect
                label="Currency To"
                data-qa="currency_to"
                name="currencyToIds"
                value={data.currencyToIds}
                onChange={onChangeInput}
                required
                multiple
                clearable
              />
            </Form.Group>
            <Form.Group widths="2">
              <TransferDirectionSelect
                label="Sending or Receiving Rates"
                name="transferDirection"
                value={data.transferDirection}
                onChange={onChangeInput}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                data-qa="amounts"
                label="Amounts (separated by ;)"
                rows={2}
                placeholder="For example: 10.50; 100; 500"
                required
                name="amounts"
                value={useMemo(() => data.amounts.join('; '), [data.amounts])}
                onChange={onChangeInput}
                onBlur={findRepeatsOfAmounts}
                error={useMemo(
                  () =>
                    repeatsOfAmounts.length
                      ? `Repeats: ${repeatsOfAmounts.join(', ')}`
                      : undefined,
                  [repeatsOfAmounts],
                )}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>URL</label>
                <Input
                  type="text"
                  value={data.slug}
                  action
                  labelPosition="left"
                  required
                >
                  <Label data-qa="url_label">{collectRateBaseUrl}</Label>
                  <input data-qa="url_input" />
                  <Button
                    type="button"
                    color="red"
                    onClick={onGenerateSlug}
                    data-qa="gen-new-link"
                  >
                    Generate new{' '}
                  </Button>
                  <Button
                    type="button"
                    onClick={copyLinkToClipboard}
                    data-qa="copy-link"
                  >
                    Copy link
                  </Button>
                </Input>
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            <Button
              type="submit"
              color="blue"
              onClick={onApply}
              disabled={!canBeSubmit}
              data-qa="apply-btn"
            >
              Apply
            </Button>
            <Button
              type="submit"
              color="blue"
              onClick={onSave}
              disabled={!canBeSubmit}
              data-qa="save-btn"
            >
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

const FORM_RATE_FIELDS = gql`
  fragment FormRateEditFields on FormRate {
    id
    slug
    countryId
    collectionId
    organizationId
    amounts
    researcherName
    clientType
    transferDirection
    currencyFromId
    currencyToIds
    submission {
      submittedAt
    }
  }
`;

const GET_FORM = gql`
  query GetFormRate($formId: Int) {
    form: formRate(where: { id: $formId }) {
      ...FormRateEditFields
    }
  }
  ${FORM_RATE_FIELDS}
`;

const CREATE_FORM = gql`
  mutation CreateFormRate(
    $slug: String!
    $countryId: Int!
    $collectionId: Int!
    $organizationId: Int!
    $amounts: [Float!]!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
    $currencyFromId: Int!
    $currencyToIds: [Int!]!
  ) {
    form: createFormRate(
      data: {
        slug: $slug
        countryId: $countryId
        collection: { connect: { id: $collectionId } }
        organizationId: $organizationId
        researcherName: $researcherName
        clientType: $clientType
        transferDirection: $transferDirection
        currencyFromId: $currencyFromId
        currencyToIds: { set: $currencyToIds }
        amounts: { set: $amounts }
      }
    ) {
      ...FormRateEditFields
    }
  }
  ${FORM_RATE_FIELDS}
`;

const UPDATE_FORM = gql`
  mutation UpdateFormRate(
    $id: Int!
    $slug: String!
    $countryId: Int!
    $collectionId: Int!
    $organizationId: Int!
    $amounts: [Float!]!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
    $currencyFromId: Int!
    $currencyToIds: [Int!]!
  ) {
    form: updateFormRate(
      data: {
        slug: { set: $slug }
        countryId: { set: $countryId }
        collection: { connect: { id: $collectionId } }
        organizationId: { set: $organizationId }
        researcherName: { set: $researcherName }
        clientType: { set: $clientType }
        transferDirection: { set: $transferDirection }
        currencyFromId: { set: $currencyFromId }
        currencyToIds: { set: $currencyToIds }
        amounts: { set: $amounts }
      }
      where: { id: $id }
    ) {
      ...FormRateEditFields
    }
  }
  ${FORM_RATE_FIELDS}
`;

export function useFormRate(formId, initState = {}) {
  const [form, setForm] = useState({
    slug: '',
    countryId: null,
    collectionId: null,
    organizationId: null,
    researcherName: '',
    clientType: '',
    transferDirection: '',
    currencyFromId: null,
    currencyToIds: [],
    amounts: [],
    ...initState,
  });
  const [fetchForm, { loading, error }] = useLazyQuery(GET_FORM, {
    onCompleted: (data) =>
      setForm({
        ...data.form,
        amounts: data.form ? data.form.amounts.map(String) : [],
      }),
  });
  const [createForm] = useMutation(CREATE_FORM, {
    onError: () => undefined,
  });
  const [updateForm] = useMutation(UPDATE_FORM, {
    onError: () => undefined,
  });

  const saveForm = useCallback(
    (options) => {
      const isNew = !isFinite(form.id);
      return isNew ? createForm(options) : updateForm(options);
    },
    [form.id, createForm, updateForm],
  );

  const changeSlug = useCallback(() => {
    setForm((prev) => ({ ...prev, slug: uuidv4() }));
  }, []);

  useEffect(() => {
    if (isFinite(formId)) {
      fetchForm({ variables: { formId: parseInt(formId) } });
    } else {
      // generate slug for new form
      changeSlug();
    }
  }, [formId, fetchForm, changeSlug]);

  return {
    form,
    loading,
    error,
    setForm,
    createForm,
    updateForm,
    saveForm,
    changeSlug,
  };
}

export default function FormRateEdit(props) {
  const { formId, collectionId } = useParams();
  const history = useHistory();

  const initState = {};
  if (collectionId && isFinite(collectionId)) {
    initState.collectionId = parseInt(collectionId);
  }

  const { form, setForm, loading, saveForm, changeSlug } = useFormRate(
    formId,
    initState,
  );

  const onChange = useCallback(
    (updated) => {
      if ('amounts' in updated) {
        const value = updated.amounts;
        let amounts = value.split(';');
        amounts = amounts.map((amount) =>
          amount
            .replace(/[^0-9.]/g, '')
            .replace(/^([0-9]+\.?[0-9]*).*$/g, '$1'),
        );
        if (amounts.length === 1 && amounts[0] === '') {
          amounts = [];
        }
        updated.amounts = amounts;
      }
      setForm((prev) => ({ ...prev, ...updated }));
    },
    [setForm],
  );

  const onSubmit = useCallback(
    async (saveType) => {
      const {
        data: {
          form: { id, collectionId },
        },
      } = await saveForm({
        variables: {
          ...form,
          researcherName: trim(form.researcherName),
          amounts: form.amounts.filter((v) => v.length).map(Number),
        },
      });
      if (saveType === saveTypes.SAVE) {
        history.push(`/collections/${collectionId}`);
      } else {
        history.replace(`/rates-forms/${id}`);
      }
    },
    [form, saveForm, history],
  );

  if (loading) return <Loader isLoading={loading} />;
  if (!form) return <Page404 />;

  const header = form.id ? `Form Configuration #${form.id}` : 'New Form';

  return (
    <div>
      <FormRateEditForm
        header={header}
        data={form}
        onChange={onChange}
        onSubmit={onSubmit}
        changeSlug={changeSlug}
      />
    </div>
  );
}
