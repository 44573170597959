import autobind from 'autobind-decorator';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import formatISO from 'date-fns/formatISO';
import { FetchResult } from '@apollo/client/link/core';
import omitDeep from 'omit-deep-lodash';
import {
  createFeeCorridor,
  FeeCorridor,
  validateFeeCorridor,
} from '../../../types/models/FeeCorridor';
import {
  FeeSubmission,
  validateFeeSubmission,
} from '../../../types/models/FeeSubmission';
import { FeeSubmissionCollectionType } from '../../../types/enums/FeeSubmissionCollectionType';
import {
  CollectFormFeeFields,
  DELETE_IMAGE,
  GET_FORM,
  SAVE_FORM,
  SUBMIT_FORM,
  UPLOAD_IMAGES,
  UploadFormFeeFields,
} from '../gql';
import { createFeeRange, FeeRange } from '../../../types/models/FeeRange';
import { createFeeValue, FeeValue } from '../../../types/models/FeeValue';
import { FeeValueType } from '../../../types/enums/FeeValueType';
import { ValidationErrors } from '../../../types/models/ValidationErrors';
import { updateItem } from '../../../utils/collection';
import { TransferDirection } from '../../../types/enums/TransferDirection';

export const defaultSubmission = {
  locationAddress: '',
  collectionType: FeeSubmissionCollectionType.ONLINE,
  accountType: '',
  accountName: '',
  onlineUrls: [
    {
      url: '',
      note: '',
    },
  ],
};

interface State {
  form: Partial<
    Omit<CollectFormFeeFields, 'corridors' | 'submission' | 'transferDirection'>
  > & {
    corridors: CollectFormFeeFields['corridors'];
    submission: CollectFormFeeFields['submission'];
    transferDirection: CollectFormFeeFields['transferDirection'];
  };
  fetched: any;
  status: 'loading' | 'submitting' | 'saving' | 'error' | 'ready';
  error: null | string;
  changedAt: null | number;
}

const initialState: State = {
  form: {
    transferDirection: TransferDirection.SENDING,
    savedAt: null,
    submittedAt: null,
    submission: { ...defaultSubmission },
    corridors: [],
    images: [],
  },
  fetched: {},
  status: 'loading',
  error: null,
  changedAt: null,
};

function reducer(
  state: State,
  { type, payload }: { type: string; payload?: any },
): State {
  if (state.status === 'saving' && !['FETCHED', 'ERROR'].includes(type)) {
    return { ...state };
  }
  switch (type) {
    case 'FETCHED':
      return {
        ...state,
        form: {
          ...state.form,
          ...get(payload, 'form', {}),
          submission: {
            ...defaultSubmission,
            ...state.form.submission,
            ...get(payload, 'form.submission', {}),
          },
        },
        fetched: {
          ...state.fetched,
          ...get(payload, 'form', {}),
        },
        status: 'ready',
        error: null,
        changedAt: null,
      };
    case 'ERROR':
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case 'SUBMIT':
      return {
        ...state,
        status: 'submitting',
      };
    case 'SAVE':
      return {
        ...state,
        status: 'saving',
      };
    case 'UPDATE_SUBMISSION':
      return {
        ...state,
        form: {
          ...state.form,
          submission: {
            ...state.form.submission,
            ...payload,
          },
        },
        changedAt: Date.now(),
      };
    case 'ADD_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          corridors: [...state.form.corridors, payload],
        },
        changedAt: Date.now(),
      };
    case 'UPDATE_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          corridors: updateItem(state.form.corridors, payload),
        },
        changedAt: Date.now(),
      };
    case 'DELETE_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          corridors: state.form.corridors.filter(
            (corridor) => corridor.id !== payload,
          ),
        },
        changedAt: Date.now(),
      };
    default:
      throw new Error();
  }
}

function cloneFeeCorridor(corridor: FeeCorridor): FeeCorridor {
  return createFeeCorridor({
    ...corridor,
    ranges: corridor.ranges.map((range) =>
      createFeeRange({
        ...range,
        editedAt: undefined,
        collectedAtLocal: undefined,
        values: range.values.map((value) =>
          createFeeValue({
            ...value,
            ...(value.type === FeeValueType.PERCENTAGE
              ? {
                  minValue: '',
                  maxValue: '',
                }
              : {}),
            value: '',
            currencyId: corridor.amountCurrencyId,
          }),
        ),
      }),
    ),
  });
}

export type UseFormFeeCollect = {
  state: State & {
    isSubmitted: boolean;
    canSubmit: boolean;
    canSave: boolean;
    validationErrors: {
      submission: ValidationErrors;
      corridors: {
        [key: number]: ValidationErrors | undefined;
      };
    };
  };
  actions: {
    save: () => Promise<FetchResult<CollectFormFeeFields>>;
    submit: () => Promise<FetchResult<CollectFormFeeFields>>;
    updateSubmission: (updated: Partial<FeeSubmission>) => void;
    addCorridor: (
      corridor: FeeCorridor,
    ) => Promise<FetchResult<UploadFormFeeFields>>;
    copyCorridor: (corridor: FeeCorridor) => void;
    updateCorridor: (id: number, updated: Partial<FeeCorridor>) => void;
    deleteCorridor: (id: number) => void;
    uploadImage: (image: File) => Promise<FetchResult<UploadFormFeeFields>>;
    deleteImage: (image: string) => Promise<FetchResult<UploadFormFeeFields>>;
  };
};

export function useFormFeeCollect(formSlug: string): UseFormFeeCollect {
  const [state, dispatch] = useReducer(reducer, initialState);

  const validationErrors = useMemo(
    () => ({
      submission: validateFeeSubmission(state.form.submission),
      corridors: Object.fromEntries(
        state.form.corridors
          .map((corridor) => {
            const errors = validateFeeCorridor(corridor);
            return [corridor.id, !isEmpty(errors) ? errors : undefined];
          })
          .filter((e) => e[1] !== undefined),
      ),
    }),
    [state.form.submission, state.form.corridors],
  );

  useQuery(GET_FORM, {
    variables: { formSlug },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const [submitMutation] = useMutation(SUBMIT_FORM, {
    onCompleted: (data) => {
      dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const submit = useCallback(() => {
    dispatch({ type: 'SUBMIT' });
    return submitMutation({
      variables: {
        formSlug: state.form.slug,
      },
    });
  }, [submitMutation, dispatch, state]);

  const isSubmitted = Boolean(state.form.submittedAt);
  const canSubmit = useMemo(
    // eslint-disable-next-line max-len
    () =>
      !isSubmitted &&
      state.status === 'ready' &&
      state.form.corridors.length > 0 &&
      isEmpty(validationErrors.submission) &&
      isEmpty(validationErrors.corridors),
    [isSubmitted, state.form.corridors.length, validationErrors, state.status],
  );

  const [saveMutation] = useMutation(SAVE_FORM, {
    onCompleted: (data) => {
      dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const save = useCallback(() => {
    dispatch({ type: 'SAVE' });
    const converter = new StateConverter(state);
    return saveMutation({
      variables: converter.convert(),
    });
  }, [saveMutation, dispatch, state]);

  useEffect(() => {
    const timeout = setTimeout(() => state.changedAt && save(), 3000);
    return () => clearTimeout(timeout);
  }, [state.changedAt, save]);

  const canSave = Boolean(state.status === 'ready');

  const [uploadMutation] = useMutation(UPLOAD_IMAGES, {
    onError: () => undefined,
  });

  const [deleteImageMutation] = useMutation(DELETE_IMAGE, {
    onError: () => undefined
  })

  const uploadImage = useCallback(
    (image: File) => {
      dispatch({ type: 'SAVE' });
      return uploadMutation({
        variables: {
          formSlug: state.form.slug,
          images: [image],
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [uploadMutation, dispatch, state.form.slug],
  );

  const deleteImage = useCallback(
    (image: string) => {
      dispatch({ type: 'SAVE' });
      return deleteImageMutation({
        variables: {
          formSlug: state.form.slug,
          image: image,
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [deleteImageMutation, dispatch, state.form.slug],
  );

  const updateSubmission = useCallback(
    (payload) => {
      dispatch({ type: 'UPDATE_SUBMISSION', payload });
    },
    [dispatch],
  );

  const addCorridor = useCallback(
    async (payload) => {
      dispatch({ type: 'SAVE' });
      const converter = new StateConverter(state);
      const newCorridor = converter.convertCorridor(payload);
      return saveMutation({
        variables: {
          formSlug: state.form.slug,
          corridors: {
            create: [newCorridor.create],
          },
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [dispatch, saveMutation, state],
  );

  const updateCorridor = useCallback(
    (id, changed) => {
      if ('amountCurrencyId' in changed) {
        // eslint-disable-next-line no-restricted-syntax
        for (const { values } of changed.ranges) {
          // eslint-disable-next-line no-restricted-syntax
          for (const value of values) {
            value.currencyId = changed.amountCurrencyId;
          }
        }
      }
      dispatch({ type: 'UPDATE_CORRIDOR', payload: { id, ...changed } });
    },
    [dispatch],
  );

  const deleteCorridor = useCallback(
    (payload) => {
      dispatch({ type: 'DELETE_CORRIDOR', payload });
    },
    [dispatch],
  );

  const copyCorridor = useCallback(
    (payload: FeeCorridor) => {
      const newCorridor = cloneFeeCorridor(payload);
      return addCorridor(newCorridor);
    },
    [addCorridor],
  );

  return {
    state: {
      ...state,
      isSubmitted,
      canSubmit,
      canSave,
      validationErrors,
    },
    actions: {
      save,
      submit,
      updateSubmission,
      addCorridor,
      copyCorridor,
      updateCorridor,
      deleteCorridor,
      uploadImage,
      deleteImage
    },
  };
}

@autobind
export class StateConverter {
  private state: State;

  constructor(state: State) {
    this.state = state;
  }

  convert() {
    const prevIds = get(this.state.fetched, 'corridors', []).map(
      ({ id }: { id: number }) => id,
    );
    const currIds = this.state.form.corridors.map(({ id }) => id);
    const deleteIds = difference(prevIds, currIds);
    return {
      formSlug: this.state.form.slug,
      submission: {
        upsert: this.convertSubmission(this.state.form.submission),
      },
      corridors: {
        upsert: this.state.form.corridors.map(this.convertCorridor),
        deleteMany: {
          id: { in: deleteIds },
        },
      },
    };
  }

  convertSubmission(submission: FeeSubmission) {
    return {
      create: submission,
      update: {
        ...this.addSetForFields(submission),
        onlineUrls: submission.onlineUrls,
      },
    };
  }

  convertCorridor(corridor: FeeCorridor) {
    const { id, ranges, ...fields } = corridor;
    const convertedRanges = ranges.map(this.convertRange);
    const prevCorridor = get(this.state.fetched, 'corridors', []).find(
      (c: any) => c.id === corridor.id,
    );
    const prevIds = (prevCorridor?.ranges || []).map(
      ({ id }: { id: number }) => id,
    );
    const currIds = ranges.map(({ id }) => id);
    const deleteIds = difference(prevIds, currIds);
    return {
      where: { id },
      create: {
        ...fields,
        countryToIds: { set: corridor.countryToIds },
        paymentMethods: { set: corridor.paymentMethods },
        payoutMethods: { set: corridor.payoutMethods },
        transferMethods: { set: corridor.transferMethods },
        ranges: { create: convertedRanges.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        ranges: {
          upsert: convertedRanges,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertRange(range: FeeRange) {
    const { id, values, amountFrom, amountTo, collectedAtLocal, ...others } =
      range;
    const fields = {
      ...others,
      amountFrom: Number(amountFrom),
      amountTo: amountTo ? Number(amountTo) : null,
      collectedAtLocal:
        collectedAtLocal instanceof Date
          ? formatISO(collectedAtLocal).slice(0, 19)
          : collectedAtLocal, // omit timezone
    };
    const convertedValues = values.map(this.convertValue);
    const prevRange = get(this.state.fetched, 'corridors', [])
      .reduce(
        (acc: FeeRange[], corridor: FeeCorridor) => acc.concat(corridor.ranges),
        [],
      )
      .find((c: any) => c.id === range.id);
    const prevIds = (prevRange?.values || []).map(
      ({ id }: { id: number }) => id,
    );
    const currIds = values.map(({ id }) => id);
    const deleteIds = difference(prevIds, currIds);
    return {
      where: { id },
      create: {
        ...fields,
        values: { create: convertedValues.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        values: {
          upsert: convertedValues,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertValue(feeValue: FeeValue, priority: number) {
    const { id, currencyId, type } = feeValue;
    const fields = {
      ...(feeValue.type === FeeValueType.PERCENTAGE
        ? {
            minValue:
              feeValue.minValue !== '' && feeValue.minValue !== null
                ? Number(feeValue.minValue)
                : null,
            maxValue:
              feeValue.maxValue !== '' && feeValue.maxValue !== null
                ? Number(feeValue.maxValue)
                : null,
          }
        : {}),
      value: feeValue.value !== '' ? Number(feeValue.value) : 0,
      currencyId,
      type,
      priority,
    };
    return {
      where: { id },
      create: fields,
      update: this.addSetForFields(fields),
    };
  }

  addSetForFields(object: any) {
    const entries = Object.entries(object).map(([key, value]) => [
      key,
      { set: value },
    ]);
    return Object.fromEntries(entries);
  }

  createOnly = ({ create }: { create: any }) => create;
}
