import debounce from 'lodash/debounce';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { gmapApiKey } from '../constants/gmap';

const initState = { isLoading: false, results: [], value: '' };

export default class LocationSearchInput extends Component {
  gmapIsReady = false;

  constructor(props) {
    super(props);
    this.state = { ...initState, value: props.value };
  }

  componentDidMount() {
    this.gmapIsReady = !!(window.google && window.google.maps);
    if (this.gmapIsReady || document.querySelector('script#gmap')) return;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${gmapApiKey}&libraries=places&language=en-GB`;
    script.async = true;
    script.id = 'gmap';
    script.onload = this.onGmapReady;
    document.body.appendChild(script);
  }

  onGmapReady = () => {
    this.gmapIsReady = true;
    this.fetchPlaces();
  };

  getAutocompleteService() {
    if (!this.autocompleteService) {
      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
    }
    return this.autocompleteService;
  }

  getPlacesService() {
    if (!this.placesService) {
      this.placesService = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    return this.placesService;
  }

  resetComponent = () => this.setState(initState);

  handleResultSelect = (e, { result }) => {
    const request = {
      placeId: result.key,
      fields: ['name', 'geometry'],
    };
    const value = `${result.title}, ${result.description}`;
    this.setState({ value });
    this.getPlacesService().getDetails(request, (place, status) => {
      if (status !== 'OK') {
        this.setState({ value: '' });
        alert("Can't find location coordinates");
      }
      const { name } = this.props;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      // eslint-disable-next-line react/destructuring-assignment,object-curly-newline
      this.props.onChange(e, { name, value, lng, lat });
    });
  };

  // eslint-disable-next-line consistent-return
  onSearchChange = (e, { value }) => {
    if (value.length === 0) {
      return this.resetComponent();
    }
    this.setState({ isLoading: true, value });
    this.fetchPlaces();
  };

  fetchPlaces = debounce(() => {
    const { value } = this.state;
    if (value.length === 0 || !this.gmapIsReady) {
      return;
    }
    this.getAutocompleteService().getPlacePredictions(
      { input: value },
      this.handleAutocompleteResult,
    );
  }, 500);

  handleAutocompleteResult = (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      this.setState({
        isLoading: false,
        results: predictions.map((prediction) => ({
          key: prediction.place_id,
          title: prediction.structured_formatting.main_text,
          description: prediction.structured_formatting.secondary_text,
        })),
      });
    } else if (
      status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
    ) {
      this.setState({
        isLoading: false,
        results: [],
      });
    }
  };

  onBlur = () => {
    const { value } = this.props;
    this.setState({ value });
  };

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.value !== this.props.value) {
      const { value } = this.props;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value });
    }
  }

  render() {
    const { isLoading, value, results } = this.state;
    const { readOnly } = this.props;

    return (
      <Search
        {...this.props}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.onSearchChange}
        results={results}
        value={value}
        readOnly={readOnly}
        open={readOnly ? false : undefined}
        onBlur={this.onBlur}
      />
    );
  }
}
