import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { PackageName } from '../types/enums/PackageName';
import { enumToSelectOptions } from '../utils/components';

interface PackageNameSelectProps extends Omit<SelectProps, 'options'> {
  value?: string;
}

const displayName = 'PackageNameSelect';
const options = enumToSelectOptions(PackageName);
export default Object.assign(
  React.memo((props: PackageNameSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
