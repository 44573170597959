import React from 'react';
import DecimalInput from '../../../components/DecimalInput';
import MoneyInput from '../../../components/MoneyInput';
import CurrencySelect from '../../../components/CurrencySelect';
import { FeePercentageValue } from '../../../types/models/FeePercentageValue';
import { ValidationErrors } from '../../../types/models/ValidationErrors';

interface FeePercentageValueItemProps {
  value: FeePercentageValue;
  onChange: (event: any, inputProps: any) => void;
  readOnly?: boolean;
  validationErrors?: ValidationErrors;
}

export default function FeePercentageValueItem({
  value,
  onChange,
  readOnly = false,
  validationErrors = {},
}: FeePercentageValueItemProps) {
  return (
    <>
      <DecimalInput
        className="form_fee_collect__fee_value_percent"
        label="%"
        name="value"
        value={value.value}
        onChange={onChange}
        readOnly={readOnly}
        placeholder="0"
        error={validationErrors.value}
      />
      <div className="form_fee_collect__fee_dynamic_value_input">
        <MoneyInput
          className="form_fee_collect__fee_value_amount"
          label="Min fee"
          name="minValue"
          value={value.minValue}
          onChange={onChange}
          readOnly={readOnly}
          placeholder="0"
          error={validationErrors.minValue}
        />
        <MoneyInput
          className="form_fee_collect__fee_value_amount"
          label="Max fee"
          name="maxValue"
          value={value.maxValue}
          onChange={onChange}
          readOnly={readOnly}
          placeholder="Inf"
          error={validationErrors.maxValue}
        />
        <CurrencySelect
          className="form_fee_collect__fee_currency"
          placeholder=""
          label="CCY"
          name="currencyId"
          value={value.currencyId}
          onChange={onChange}
          readOnly={readOnly}
        />
      </div>
    </>
  );
}
