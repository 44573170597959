import React from 'react';
import { Button } from 'semantic-ui-react';

const FileUploader = (props) => {
  const {
    handleFiles,
    accept = null,
    multiple = false,
    ...buttonProps
  } = props;
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    handleFiles(event.target.files);
  };
  return (
    <>
      <Button type="button" onClick={handleClick} {...buttonProps} />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
        multiple={multiple}
      />
    </>
  );
};
export default FileUploader;
