import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { TransferMethod } from '../types/enums/TransferMethod';
import { enumToSelectOptions } from '../utils/components';

interface TransferMethodSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof TransferMethod | (keyof typeof TransferMethod)[];
}

const displayName = 'TransferMethodSelect';
const options = enumToSelectOptions(TransferMethod);
export default Object.assign(
  React.memo((props: TransferMethodSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
