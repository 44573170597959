import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    loginSuccess: (state, { payload }) => ({
      ...state,
      isLoading: false,
      user: payload.user,
    }),
    loginFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    setUser: (state, { payload }) => ({
      ...state,
      user: payload.user,
    }),
    logout: (state) => ({
      ...state,
      user: null,
    }),
    login: (state) => state,
  },
});

export default authSlice;
