import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import FeeCorridorConfigurationForm from './FeeCorridorConfigurationForm';
import {
  FeeCorridorConfiguration,
  StateFeeCorridorConfiguration,
  validateFeeCorridorConfiguration,
} from '../../../types/models/FeeCorridorConfiguration';
import { TransferMethod } from '../../../types/enums/TransferMethod';
import { PayoutMethod } from '../../../types/enums/PayoutMethod';
import { PaymentMethod } from '../../../types/enums/PaymentMethod';
import { CustomerType } from '../../../types/enums/CustomerType';
import { TransferDirection } from '../../../types/enums/TransferDirection';

interface FeeCorridorCreateFormProps {
  defaultValues?: Partial<FeeCorridorConfiguration>;
  existsConfigurations?: FeeCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onSubmit: (corridorConfig: FeeCorridorConfiguration) => void;
  onClose: () => void;
}

export default function FeeCorridorCreateForm({
  defaultValues = undefined,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: FeeCorridorCreateFormProps): JSX.Element {
  const initialState = useMemo<StateFeeCorridorConfiguration>(
    () => ({
      ...{
        countryFromId: null,
        currencyFromId: null,
        countryToIds: [],
        currencyToId: null,
        paymentMethods: [PaymentMethod.BANK_ACCOUNT],
        payoutMethods: [PayoutMethod.BANK_ACCOUNT],
        paymentDetails: '',
        payoutDetails: '',
        transferMethods: [TransferMethod.ONLINE],
        amountCurrencyId: null,
        customerType: CustomerType.EXISTING_CUSTOMER_PRICING,
        transferSpeed: 'Not specified',
        transferSpeedDetails: '',
        packageName: 'standard',
      },
      ...(defaultValues || {}),
    }),
    [defaultValues],
  );

  const [state, setState] =
    useState<StateFeeCorridorConfiguration>(initialState);
  const validationErrors = useMemo(
    () => validateFeeCorridorConfiguration(state, existsConfigurations),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    onSubmit(state as FeeCorridorConfiguration);
    setState(initialState);
  }, [onSubmit, state, setState, initialState]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Add New Table" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <FeeCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
                data-qa="add-table"
              >
                Add Table
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
