import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { TransferSpeedType } from '../types/enums/TransferSpeedType';
import { enumToSelectOptions } from '../utils/components';

interface TransferSpeedSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof TransferSpeedType | (keyof typeof TransferSpeedType)[];
}

const displayName = 'TransferSpeedSelect';
const options = enumToSelectOptions(TransferSpeedType);
export default Object.assign(
  React.memo((props: TransferSpeedSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
