import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import pull from 'lodash/pull';
import isEmpty from 'lodash/isEmpty';
import { PaymentMethod } from '../enums/PaymentMethod';
import { PayoutMethod } from '../enums/PayoutMethod';
import { TransferMethod } from '../enums/TransferMethod';
import { CustomerType } from '../enums/CustomerType';
import { WithNullable } from '../utility';
import { ValidationErrors } from './ValidationErrors';
import { ALL_COUNTRIES_ID, ALL_OTHERS_ID } from '../../constants/countryGroups';
import { PackageName } from '../enums/PackageName';
import { TransferSpeedType } from '../enums/TransferSpeedType';

export interface FeeCorridorConfiguration {
  countryFromId: number;
  currencyFromId: number;
  countryToIds: number[];
  currencyToId?: number;
  paymentMethods: (keyof typeof PaymentMethod)[];
  payoutMethods: (keyof typeof PayoutMethod)[];
  paymentDetails: string;
  payoutDetails: string;
  transferMethods: (keyof typeof TransferMethod)[];
  amountCurrencyId: number;
  customerType: keyof typeof CustomerType;
  transferSpeed: keyof typeof TransferSpeedType;
  transferSpeedDetails: string;
  packageName: string;
}

export type StateFeeCorridorConfiguration = WithNullable<
  FeeCorridorConfiguration,
  'countryFromId' | 'currencyFromId' | 'currencyToId' | 'amountCurrencyId'
>;

export const configurationFields: Array<keyof FeeCorridorConfiguration> = [
  'countryFromId',
  'currencyFromId',
  'countryToIds',
  'currencyToId',
  'paymentMethods',
  'payoutMethods',
  'transferMethods',
  'amountCurrencyId',
  'customerType',
  'paymentDetails',
  'payoutDetails',
  'transferSpeed',
  'transferSpeedDetails',
  'packageName',
];

export function validateFeeCorridorConfiguration(
  state: StateFeeCorridorConfiguration,
  existsConfigurations: FeeCorridorConfiguration[],
): ValidationErrors {
  const errors: ValidationErrors =
    validateFeeCorridorConfigurationRequiredFields(state);
  if (!isEmpty(errors)) {
    return errors;
  }
  const config = state as FeeCorridorConfiguration;

  if (config.countryToIds.includes(config.countryFromId)) {
    errors.countryToIds = 'You cannot use the country selected in Country From';
  }

  const sameConfigs = findSameConfigurations(existsConfigurations, config, [
    'countryToIds',
    'currencyToId',
    'transferSpeed',
    'packageName',
  ]);
  if (includesSameConfiguration(sameConfigs, config)) {
    errors.sameConfiguration =
      'These field are overlapping in another table - please use unique field combination';
    configurationFields.forEach((fieldName) => {
      errors[fieldName] = 'Overlapping field';
    });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (config.countryToIds.includes(ALL_COUNTRIES_ID)) {
      const configsHaveParticularCountry = sameConfigs.some(
        (c) => !c.countryToIds.includes(ALL_COUNTRIES_ID),
      );
      if (configsHaveParticularCountry) {
        errors.sameConfiguration =
          'You cannot use "All Countries" with same configuration specified in another table';
      }
    } else {
      const alreadyHaveAllCountries = sameConfigs.some((c) =>
        c.countryToIds.includes(ALL_COUNTRIES_ID),
      );
      if (alreadyHaveAllCountries) {
        errors.sameConfiguration =
          'You cannot add table with selected Country To as you have already used "All Countries" with the same configuration.';
      }
    }
    if (
      !errors.sameConfiguration &&
      config.countryToIds.includes(ALL_OTHERS_ID)
    ) {
      const configsHaveParticularCountry = sameConfigs.some(
        (c) => !c.countryToIds.includes(ALL_OTHERS_ID),
      );

      if (!configsHaveParticularCountry) {
        errors.sameConfiguration =
          'You cannot use "All others", first you must specify the a configuration with at least one Country To';
      }
    }
  }
  return errors;
}

export function validateFeeCorridorConfigurationRequiredFields(
  config: StateFeeCorridorConfiguration,
): ValidationErrors {
  const errors: ValidationErrors = {};
  const requiredFields = [...configurationFields];
  const currencyToRequired =
    config.countryToIds.length === 1 &&
    !config.countryToIds.includes(ALL_COUNTRIES_ID) &&
    !config.countryToIds.includes(ALL_OTHERS_ID);
  if (!currencyToRequired) {
    pull(requiredFields, 'currencyToId');
  }
  if (!config.countryFromId) {
    pull(requiredFields, 'currencyFromId');
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName of requiredFields) {
    const fieldValue = config[fieldName];
    if (fieldValue === undefined) {
      errors[fieldName] = 'Please fill in this field';
    } else if (
      fieldValue &&
      typeof fieldValue === 'object' &&
      fieldValue.length === 0
    ) {
      errors[fieldName] = 'Please choose options for this field';
    }
  }
  return errors;
}

export function findSameConfigurations(
  existsConfigurations: FeeCorridorConfiguration[],
  requiredConfig: FeeCorridorConfiguration,
  excludeFields: Array<keyof FeeCorridorConfiguration> = [],
): FeeCorridorConfiguration[] {
  return existsConfigurations.filter((c) =>
    isSameFeeConfiguration(c, requiredConfig, excludeFields),
  );
}

export function includesSameConfiguration(
  existsConfigurations: FeeCorridorConfiguration[],
  requiredConfig: FeeCorridorConfiguration,
  excludeFields: Array<keyof FeeCorridorConfiguration> = [],
): boolean {
  return existsConfigurations.some((c) =>
    isSameFeeConfiguration(c, requiredConfig, excludeFields),
  );
}

export function isSameFeeConfiguration(
  a: FeeCorridorConfiguration,
  b: FeeCorridorConfiguration,
  excludeFields: Array<keyof FeeCorridorConfiguration> = [],
): boolean {
  const fields = difference(configurationFields, excludeFields);

  return fields.every((fieldName) => {
    return (
      a[fieldName] === b[fieldName] ||
      (Array.isArray(a[fieldName]) &&
        hasIntersect(
          a[fieldName] as Array<string | number>,
          b[fieldName] as Array<string | number>,
        ))
    );
  });
}

export function hasIntersect(
  a: ArrayLike<string | number>,
  b: ArrayLike<string | number>,
): boolean {
  return intersection(a, b).length > 0;
}
