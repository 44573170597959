import React, { useCallback, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { FeeCorridorConfiguration } from '../../../types/models/FeeCorridorConfiguration';
import { titleCase } from '../../../utils/string';
import ListToString from '../../../components/ListToString';
import { TransferDirection } from '../../../types/enums/TransferDirection';
import useCountries from '../../../hooks/useCountries';
import useCurrencies from '../../../hooks/useCurrencies';

export interface FeeCorridorViewProps {
  configuration: FeeCorridorConfiguration;
  transferDirection: keyof typeof TransferDirection;
}

export default function FeeCorridorConfigurationHeader({
  configuration,
  transferDirection,
}: FeeCorridorViewProps): JSX.Element {
  const { countries } = useCountries({ withGroups: true });
  const { currencies } = useCurrencies();

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  const countryFrom = countries.find(
    (c) => c.id === configuration.countryFromId,
  );
  const countriesTo = countries
    .filter((c) => configuration.countryToIds.includes(c.id))
    .map((c) => c.label);

  const currencyFrom = currencies.find(
    (c) => c.id === configuration.currencyFromId,
  );
  const currencyTo = currencies.find(
    (c) => c.id === configuration.currencyToId,
  );

  const paymentMethods = configuration.paymentMethods.map(titleCase);
  const payoutMethods = configuration.payoutMethods.map(titleCase);
  const transferMethods = configuration.transferMethods.map(titleCase);
  const { paymentDetails, payoutDetails } = configuration;
  return (
    <Grid
      divided
      className="form_fee_collect__configuration_header"
      onClick={toggleExpanded}
    >
      <Grid.Row columns={10}>
        <div />
        <Grid.Column data-qa="country-from">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Sending Country'
              : 'Receiving Country'}
          </b>
          <br />
          {countryFrom?.label}
        </Grid.Column>
        <Grid.Column data-qa="country-to">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Receiving Country'
              : 'Sending Country'}
          </b>{' '}
          <br />
          {expanded ? (
            countriesTo.join(', ')
          ) : (
            <ListToString list={countriesTo} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="currency-from">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Sending Currency'
              : 'Receiving Currency'}
          </b>{' '}
          <br />
          {currencyFrom?.label}
        </Grid.Column>
        <Grid.Column data-qa="currency-to">
          <b>
            {transferDirection === TransferDirection.SENDING
              ? 'Receiving Currency'
              : 'Sending Currency'}
          </b>{' '}
          <br />
          {currencyTo?.label || 'Local Currencies'}
        </Grid.Column>
        <Grid.Column data-qa="payment-method">
          <b>Payment Method</b> <br />
          {expanded ? (
            paymentMethods.join(', ')
          ) : (
            <ListToString list={paymentMethods} />
          )}
          {paymentDetails.length ? ` (${paymentDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="payout-method">
          <b>Payout Method</b> <br />
          {expanded ? (
            payoutMethods.join(', ')
          ) : (
            <ListToString list={payoutMethods} />
          )}
          {payoutDetails.length ? ` (${payoutDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="transfer_method">
          <b>Transfer Method</b> <br />
          {expanded ? (
            transferMethods.join(', ')
          ) : (
            <ListToString list={transferMethods} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="customer-type">
          <b>Customer Type</b> <br />
          {titleCase(configuration.customerType)}
        </Grid.Column>
        <Grid.Column data-qa="transfer-speed">
          <b>Transfer Speed</b> <br />
          {configuration.transferSpeed}
          {configuration.transferSpeedDetails.length ? ` (${configuration.transferSpeedDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="package-Name">
          <b>Transfer Speed Nature</b> <br />
          {titleCase(configuration.packageName)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
