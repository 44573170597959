import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import SubmissionForm from './components/SubmissionForm';
import SubmitConfirmation from './components/SubmitConfirmation';
import Loader from '../../components/Loader';
import Page404 from '../../components/Page404';
import './FormFeeCollect.scss';
import { createFeeValue } from '../../types/models/FeeValue';
import FormSubmitted from '../../components/FormSubmitted';
import FeeCorridorCreateForm from './components/FeeCorridorCreateForm';
import { createFeeCorridor, FeeCorridor } from '../../types/models/FeeCorridor';
import FeeCorridorView from './components/FeeCorridorView';
import { FeeCorridorConfiguration } from '../../types/models/FeeCorridorConfiguration';
import { createFeeRange } from '../../types/models/FeeRange';
import FormFeeInstructions from './components/FormFeeInstructions';
import { useFormFeeCollect } from './hooks/useFormFeeCollect';
import { backendUri } from '../../api/client';
import { useLoggedUser } from '../../hooks/useLoggedUser';
import storage from '../../utils/storage';
import FeeCorridorEditForm from './components/FeeCorridorEditForm';
import FeeCorridorCloneForm from './components/FeeCorridorCloneForm';
import useModalState from '../../hooks/useModalState';
import { ALL_OTHERS_ID } from '../../constants/countryGroups';
import Saving from 'src/components/Saving';

export default function FormFeeCollect(): JSX.Element {
  const { formSlug } = useParams<{ formSlug: string }>();
  const {
    state: {
      isSubmitted,
      canSubmit,
      canSave,
      validationErrors,
      status,
      error,
      form: { submission, corridors, ...form },
    },
    actions: {
      submit,
      save,
      updateSubmission,
      addCorridor,
      updateCorridor,
      deleteCorridor,
      copyCorridor,
      uploadImage,
      deleteImage
    },
  } = useFormFeeCollect(formSlug);
  const { loggedUser } = useLoggedUser();
  const readOnly = isSubmitted;

  const imagesBaseDownloadUrl = loggedUser
    ? `${backendUri}/formFees/${
        form.id
      }/images/{filename}?token=${storage.getToken()}`
    : null;

  const [submitConfirmationDisplayed, showSubmitConfirmation] = useState(false);
  const [
    createCorridorFormDisplayed,
    showCreateCorridorForm,
    hideCreateCorridorForm,
  ] = useModalState();
  const onCreateCorridor = useCallback(
    (configuration: FeeCorridorConfiguration) => {
      addCorridor(
        createFeeCorridor({
          ...configuration,
          ranges: [
            createFeeRange({
              values: [
                createFeeValue({
                  currencyId: configuration.amountCurrencyId,
                }),
              ],
            }),
          ],
        }),
      );
      hideCreateCorridorForm();
    },
    [addCorridor, hideCreateCorridorForm],
  );

  const [editingCorridor, setEditingCorridor] = useState<
    FeeCorridor | undefined
  >();
  const onEditCorridor = useCallback(
    (corridor: FeeCorridor) => {
      setEditingCorridor(corridor);
    },
    [setEditingCorridor],
  );

  const [cloningCorridor, setCloningCorridor] = useState<
    FeeCorridor | undefined
  >();
  const onCopyCorridor = useCallback(
    (corridor: FeeCorridor) => {
      setCloningCorridor(corridor);
    },
    [setCloningCorridor],
  );

  const sortedCorridors = useMemo(
    () =>
      corridors.slice().sort((a: FeeCorridor, b: FeeCorridor) => {
        if (a.countryFromId !== b.countryFromId) {
          return a.countryFromId - b.countryFromId;
        }
        if (a.currencyFromId !== b.currencyFromId) {
          return a.currencyFromId - b.currencyFromId;
        }
        if (a.countryToIds.includes(ALL_OTHERS_ID)) {
          return 1;
        }
        if (b.countryToIds.includes(ALL_OTHERS_ID)) {
          return -1;
        }
        return 0;
      }),
    [corridors],
  );

  if (status === 'loading') return <Loader isLoading />;
  if (error === 'FORM_SUBMITTED_ERROR') return <FormSubmitted />;
  if (!form.id) return <Page404 />;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">
              {form.organization?.name}
              <Label>Fees</Label>
              {form.country && <Label>in {form.country.name}</Label>}
              <Label>{capitalize(form.clientType)} client</Label>
            </Header>
            {isSubmitted && (
              <>
                <br />
                <Message
                  info
                  icon="inbox"
                  header="This form has now been submitted"
                  content="Changes are no longer possible."
                />
              </>
            )}
            {!isSubmitted && <FormFeeInstructions />}
            {(status === 'saving' || status === 'submitting') && (
              <Saving
                variant={'success'}
                children="Saving..."
                key={'saving_snackbar'}
              />
            )}
            <br />
            <SubmissionForm
              researcherName={form.researcherName}
              clientType={form.clientType}
              submission={submission}
              updateSubmission={updateSubmission}
              editable={!readOnly}
              downloadUrl={imagesBaseDownloadUrl}
              images={form.images}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              validationErrors={validationErrors.submission}
            />
            <br />
            {sortedCorridors.map((corridor: FeeCorridor) => (
              <FeeCorridorView
                key={corridor.id}
                corridor={corridor}
                onChange={updateCorridor}
                onEditConfiguration={onEditCorridor}
                onCopy={onCopyCorridor}
                onDelete={deleteCorridor}
                readOnly={readOnly}
                validationErrors={validationErrors.corridors[corridor.id]}
                transferDirection={form.transferDirection}
              />
            ))}
            {!isSubmitted && corridors.length > 0 && (
              <Button
                data-qa="add-new-table"
                type="button"
                color="green"
                onClick={showCreateCorridorForm}
              >
                Add New Table
              </Button>
            )}
            {corridors.length === 0 && (
              <Segment placeholder textAlign="center">
                <Header icon>
                  <Icon name="edit outline" />
                  No fee tables have been added to the form
                </Header>
                <Button
                  onClick={showCreateCorridorForm}
                  type="button"
                  color="green"
                  data-qa="add-new-table"
                >
                  Add New Table
                </Button>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            {!isSubmitted && status !== 'saving' && (
              <Button
                type="submit"
                data-qa="save-btn"
                color="green"
                disabled={!canSave}
                onClick={save}
              >
                Save
              </Button>
            )}
            {status === 'saving' && (
              <Button type="submit" loading color="green" disabled>
                Saving...
              </Button>
            )}
            <div style={{ display: 'inline-block', width: '1em' }} />
            {!isSubmitted && status !== 'submitting' && (
              <Button
                type="submit"
                data-qa="submit-btn"
                color="blue"
                onClick={() => showSubmitConfirmation(true)}
                disabled={!canSubmit}
              >
                Submit
              </Button>
            )}
            {status === 'submitting' && (
              <Button type="submit" loading color="blue" disabled>
                Submitting...
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SubmitConfirmation
        open={submitConfirmationDisplayed}
        onClose={() => showSubmitConfirmation(false)}
        onSubmit={() => {
          showSubmitConfirmation(false);
          save().then(() => {
            if (canSubmit) {
              submit().then();
            }
          });
        }}
      />
      {createCorridorFormDisplayed && (
        <FeeCorridorCreateForm
          existsConfigurations={corridors}
          onClose={hideCreateCorridorForm}
          onSubmit={onCreateCorridor}
          transferDirection={form.transferDirection}
        />
      )}
      {editingCorridor && (
        <FeeCorridorEditForm
          corridor={editingCorridor}
          existsConfigurations={corridors.filter(
            (c: FeeCorridor) => c.id !== editingCorridor.id,
          )}
          onClose={() => setEditingCorridor(undefined)}
          onSubmit={updateCorridor}
          transferDirection={form.transferDirection}
        />
      )}
      {cloningCorridor && (
        <FeeCorridorCloneForm
          corridor={cloningCorridor}
          existsConfigurations={corridors}
          onClose={() => setCloningCorridor(undefined)}
          onSubmit={copyCorridor}
          transferDirection={form.transferDirection}
        />
      )}
    </Form>
  );
}
