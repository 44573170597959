import { useCallback } from 'react';
import { ListItem, SetState } from '../types/utility';

export default function useDelete<T extends ListItem>(setState: SetState<T>) {
  return useCallback(
    (id: T['id']) => {
      setState((prevState: T[]) => prevState.filter((item) => item.id !== id));
    },
    [setState],
  );
}
