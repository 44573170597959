import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import CryptoBuySellCorridorConfigurationForm from './CryptoBuySellCorridorConfigurationForm';
import {
  CryptoBuySellCorridorConfiguration,
  StateCryptoBuySellCorridorConfiguration,
  validateCryptoBuySellCorridorConfiguration,
} from '../../../../types/models/CryptoBuySellCorridorConfiguration';
import {
  createCryptoBuySellCorridor,
  CryptoBuySellCorridor,
  getDefaultFeeCurrencyId,
} from '../../../../types/models/CryptoBuySellCorridor';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import { TransactionType } from 'src/types/enums/TransactionType';
import { FeeValueType } from 'src/types/enums/FeeValueType';
import { FeeValue } from 'src/types/models/FeeValue';

interface CryptoBuySellCorridorCloneFormProps {
  corridor: CryptoBuySellCorridor;
  existsConfigurations?: CryptoBuySellCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (corridor: CryptoBuySellCorridor) => void;
}

export default function CryptoBuySellCorridorCloneForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: CryptoBuySellCorridorCloneFormProps): JSX.Element {
  // eslint-disable-next-line max-len
  const [state, setState] = useState<StateCryptoBuySellCorridorConfiguration>(
    corridor as StateCryptoBuySellCorridorConfiguration,
  );
  const validationErrors = useMemo(
    () =>
      validateCryptoBuySellCorridorConfiguration(state, existsConfigurations),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    const defaultFeeCurrencyId = getDefaultFeeCurrencyId(
      state as CryptoBuySellCorridorConfiguration,
    );
    const isExchangeTrade =
      state.transactionType === TransactionType.EXCHANGE_TRADE;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedRanges = state.ranges.map((range: CryptoBuySellRange) => ({
      ...range,
      fees: range.fees.map((fee: FeeValue) => ({
        ...(fee.type === FeeValueType.FIXED && isExchangeTrade
          ? {
              ...fee,
              type: FeeValueType.PERCENTAGE,
              value: '',
            }
          : fee),
        currencyId: defaultFeeCurrencyId,
      })),
    }));
    const updatedState = { ...state, ...{ ranges: updatedRanges } };
    setState(updatedState);

    onSubmit(
      createCryptoBuySellCorridor(
        updatedState as CryptoBuySellCorridorConfiguration,
      ),
    );
    onClose();
  }, [onSubmit, onClose, state]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Clone Table" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p style={{ marginBottom: '2em', fontStyle: 'italic' }}>
                You will need to select different settings to clone a table
              </p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <CryptoBuySellCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                data-qa="clone-table"
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
              >
                Clone Table
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
