import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import './DateTimePicker.scss';
import DatePicker from 'react-datepicker';
import { dateTimeFormat, timeFormat } from '../constants/dateTimeFormat';
import 'react-datepicker/dist/react-datepicker.css';
import BaseTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

export default class DateTimePicker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
      .isRequired,
    timezone: PropTypes.string,
    dateTimeFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    dateTimeFormat,
    timeFormat,
    maxDate: Date.parse('2050-01-01'), // set the maximum year that the user can not enter very far-off year
    timezone: '',
    error: null,
  };

  render() {
    const {
      'data-qa': dataQa,
      name,
      onChange,
      dateTimeFormat,
      timeFormat,
      error,
      value,
      timezone,
      ...pickerProps
    } = this.props;

    return (
      <div className="date_time_picker field" data-qa={dataQa}>
        <div className="date_time_picker__fields">
          <DatePicker
            selected={this.dateToLocal(value)}
            onChange={this.onChange}
            dateFormat={dateTimeFormat}
            timeFormat={timeFormat}
            showTimeInput
            customTimeInput={<TimePicker />}
            {...pickerProps}
          />
        </div>
        {error && (
          <Label pointing basic prompt className="date_time_picker__label">
            {error}
          </Label>
        )}
      </div>
    );
  }

  dateToLocal = (value) => {
    if (!value || (typeof value === 'string' && !value.length)) {
      return null;
    }
    if (typeof value === 'string') {
      return Date.parse(value);
    }
    return value;
  };

  onChange = (date) => {
    const { name, onChange } = this.props;
    const value = date ? date.toISOString() : null;
    onChange({ name, value, date });
  };
}

export function TimePicker({ value, onChange, ...others }) {
  const momentValue = useMemo(
    () => (value ? moment(value, 'HH:mm') : moment()),
    [value],
  );
  const onChangeWithMoment = useCallback(
    (newValue) => {
      if (newValue && newValue.isValid()) {
        onChange(newValue.format('HH:mm'));
      }
    },
    [onChange],
  );
  const curRef = useRef(null);
  return (
    <BaseTimePicker
      ref={curRef}
      value={momentValue}
      onChange={onChangeWithMoment}
      getPopupContainer={() => curRef.current.picker.parentNode}
      showSecond={false}
      allowEmpty={false}
      {...others}
    />
  );
}
