import React, { useCallback, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { CryptoInOutCorridorConfiguration } from '../../../../types/models/CryptoInOutCorridorConfiguration';
import { titleCase } from '../../../../utils/string';
import ListToString from '../../../../components/ListToString';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import useCountries from '../../../../hooks/useCountries';
import useCurrencies from '../../../../hooks/useCurrencies';

export interface CryptoInOutCorridorViewProps {
  configuration: CryptoInOutCorridorConfiguration;
  transferDirection: keyof typeof TransferDirection;
}

export default function CryptoInOutCorridorConfigurationHeader({
  configuration,
  transferDirection,
}: CryptoInOutCorridorViewProps): JSX.Element {
  const { countries } = useCountries({ withGroups: true });
  const { currencies } = useCurrencies();

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  const country = countries.find((c) => c.id === configuration.countryId);

  const currencyTitles = configuration.currencyIds.map(
    (currencyId) => currencies.find((c) => c.id === currencyId)?.label || '',
  );

  const paymentMethods = configuration.paymentMethods.map(titleCase);
  const payoutMethods = configuration.payoutMethods.map(titleCase);
  const directions = configuration.direction.map(titleCase);
  const { paymentDetails, payoutDetails } = configuration;
  return (
    <Grid
      divided
      className="crypto_form_collect__configuration_header"
      onClick={toggleExpanded}
    >
      <Grid.Row columns={7}>
        <div />
        <Grid.Column data-qa="country">
          <b>Country</b> <br />
          {country?.label}
        </Grid.Column>
        <Grid.Column data-qa="currency">
          <b>Currency</b> <br />
          {expanded ? (
            currencyTitles.join(', ')
          ) : (
            <ListToString list={currencyTitles} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="direction">
          <b>Direction</b> <br />
          {expanded ? (
            directions.join(', ')
          ) : (
            <ListToString list={directions} />
          )}
        </Grid.Column>
        <Grid.Column data-qa="payment-method">
          <b>Payment Method</b> <br />
          {expanded ? (
            paymentMethods.join(', ')
          ) : (
            <ListToString list={paymentMethods} />
          )}
          {paymentDetails.length ? ` (${paymentDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="payout-method">
          <b>Payout Method</b> <br />
          {expanded ? (
            payoutMethods.join(', ')
          ) : (
            <ListToString list={payoutMethods} />
          )}
          {payoutDetails.length ? ` (${payoutDetails})` : ''}
        </Grid.Column>
        <Grid.Column data-qa="customer-type">
          <b>Customer Type</b> <br />
          {titleCase(configuration.customerType)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
