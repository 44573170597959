import React, { useCallback, useState } from 'react';
import { Header, List, Button, Icon } from 'semantic-ui-react';
import FileUploader from './FileUploader';
import './ImageAttachments.scss';

const FileAttachment = ({ file, onRemoveFile }) => (
  <List.Item key={file.name}>{file.name}</List.Item>
);

const FileAttachments = ({ files, onRemoveFile }) =>
  files &&
  files.length > 0 && (
    <>
      <Header size="tiny">Uploading:</Header>
      <List>
        {files.map(({ file }) => (
          <FileAttachment
            key={file.name}
            file={file}
            onRemoveFile={onRemoveFile}
          />
        ))}
      </List>
    </>
  );

const UploadedAttachments = ({ files, downloadUrl, deleteImage, readOnly }) =>
  files &&
  files.length > 0 && (
    <>
      {false && <Header size="tiny">Uploaded Attachments:</Header>}
      <List className='image-attachments'>
        {files.map((file) => (
          <List.Item key={file}>
            {downloadUrl && (
              <>
                <a
                  href={downloadUrl.replace('{filename}', file)}
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Click to see this attachment"
                >
                  {file}
                </a>
                {!readOnly && <Button
                  type='button'
                  className='remove'
                  title="Remove form"
                  data-qa="remove"
                  icon
                  onClick={() => deleteImage(file)}
                >
                  <Icon name="trash" />
                </Button>}
              </>
            )}
            {!downloadUrl && file}
          </List.Item>
        ))}
      </List>
    </>
  );

export default function ImageAttachments({
  images,
  uploadImage,
  deleteImage,
  readOnly = false,
  downloadUrl = undefined,
}) {
  const extensions = ['png', 'jpg', 'jpeg'];
  const [uploadingImages, setUploadingImages] = useState([]);
  const handleFiles = useCallback(
    (files) => {
      const images = [...files].filter((file) =>
        extensions.includes(file.name.split('.').pop().toLowerCase()),
      );
      setUploadingImages((prev) => [
        ...prev,
        ...images.map((image) => ({
          file: image,
          promise: uploadImage(image).then((data) => {
            setTimeout(() => {
              setUploadingImages((prev) =>
                prev.filter(({ file }) => file !== image),
              );
            }, 0);
            return data;
          }),
        })),
      ]);
    },
    [setUploadingImages, extensions, uploadImage],
  );
  const onRemoveFile = useCallback((file) => {
    // setNewImages((prev) => prev.filter((f) => f !== file));
  }, []);

  return (
    <>
      <UploadedAttachments files={images} downloadUrl={downloadUrl} deleteImage={deleteImage} readOnly={readOnly}/>
      {!readOnly && (
        <FileAttachments files={uploadingImages} onRemoveFile={onRemoveFile} />
      )}
      {!readOnly && (
        <FileUploader
          accept=".png,.jpg,.jpeg"
          multiple
          handleFiles={handleFiles}
          data-qa="attach-images"
        >
          Attach Image(s)
        </FileUploader>
      )}
    </>
  );
}
