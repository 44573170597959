import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { ClientType } from '../types/enums/ClientType';
import { enumToSelectOptions } from '../utils/components';
import {
  AccountType,
  IndividualAccountType,
  BusinessAccountType,
} from '../types/enums/AccountType';

export interface AccountTypeSelectProps extends Omit<SelectProps, 'options'> {
  clientType: keyof typeof ClientType;
  value?: keyof typeof AccountType | (keyof typeof AccountType)[];
}

const displayName = 'AccountTypeSelect';
const individualOptions = enumToSelectOptions(IndividualAccountType);
const businessOptions = enumToSelectOptions(BusinessAccountType);
export default Object.assign(
  React.memo(({ clientType, ...props }: AccountTypeSelectProps) => (
    <Form.Select
      options={
        clientType === ClientType.INDIVIDUAL
          ? individualOptions
          : businessOptions
      }
      {...props}
    />
  )),
  { displayName },
);
