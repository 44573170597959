import React from 'react';
import { Form } from 'semantic-ui-react';
import { TransferDirection } from '../types/enums/TransferDirection';
import { enumToSelectOptions } from '../utils/components';

const displayName = 'TransferDirectionSelect';
const options = enumToSelectOptions(TransferDirection);
export default Object.assign(
  React.memo((props) => <Form.Select options={options} {...props} />),
  { displayName },
);
