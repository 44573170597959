import { ListItem } from '../types/utility';

export function updateItem<T extends ListItem>(
  collection: T[],
  { id, ...data }: Partial<Omit<T, 'id'>> & { id: T['id'] },
): T[] {
  const index = collection.findIndex((item) => item.id === id);
  const item = collection[index];
  const updatedItem = { ...item, ...data };
  return [
    ...collection.slice(0, index),
    updatedItem,
    ...collection.slice(index + 1),
  ];
}
