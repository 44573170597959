import React from 'react';
import { Table } from 'semantic-ui-react';
import cn from 'classnames';
import './SortableTable.scss';
import get from 'lodash/get';

const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const CLASS_NAME_TYPE = {
  ROW: 'ROW',
  COLUMN: 'COLUMN',
  HEADER_CELL: 'HEADER_CELL',
};

class SortableTable extends React.Component {
  static defaultCompare = (a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  static sortItems = (
    items,
    attribute,
    desc = false,
    compare = SortableTable.defaultCompare,
  ) => {
    const result = [...items];
    result.sort((a, b) => compare(a[attribute], b[attribute]));
    if (desc) result.reverse();
    return result;
  };

  static getSortOptions = (newColumn, sortColumn, sortOrder) => {
    switch (true) {
      case sortColumn !== newColumn:
        return {
          sortColumn: newColumn,
          sortOrder: SORT_ORDER.ASC,
        };
      case sortOrder === SORT_ORDER.ASC:
        return {
          sortColumn,
          sortOrder: SORT_ORDER.DESC,
        };
      default:
        return {
          sortOrder: null,
          sortColumn: null,
        };
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      sortColumn: null,
      sortOrder: null,
    };
  }

  onInternalSort = (column) => {
    const { sortColumn, sortOrder } = this.state;
    const newSort = SortableTable.getSortOptions(column, sortColumn, sortOrder);
    this.setState(newSort);
  };

  onExternalSort = (column) => {
    const { sortColumn, sortOrder, onSortChange } = this.props;
    const newSort = SortableTable.getSortOptions(column, sortColumn, sortOrder);
    onSortChange(newSort);
  };

  renderItem = (column, item) => {
    const {
      columnsConfig: { config, defaultText },
      renderItem,
    } = this.props;

    if (config[column].customRender && typeof renderItem === 'function') {
      return renderItem(column, item);
    }

    const { dataAttribute } = config[column];
    return get(item, dataAttribute, defaultText);
  };

  getItemKey = (column, item) => {
    const {
      columnsConfig: { keyAttribute },
    } = this.props;

    const prefix = column != null ? column : 'item';

    if (Array.isArray(keyAttribute)) {
      return keyAttribute.reduce((key, attr) => {
        const attrValue = attr
          .split('.')
          .reduce((result, path) => result[path], item);
        return `${key}.${attrValue}`;
      }, prefix);
    }

    const key = keyAttribute
      .split('.')
      .reduce((result, path) => result[path], item);

    return `${prefix}.${key}`;
  };

  setClassName = (type, column, item) => {
    const { setRowClassName, setColumnClassName, setHeaderCellClassName } =
      this.props;

    switch (type) {
      case CLASS_NAME_TYPE.ROW:
        return typeof setRowClassName === 'function'
          ? setRowClassName(item)
          : '';
      case CLASS_NAME_TYPE.COLUMN:
        return typeof setColumnClassName === 'function'
          ? setColumnClassName(column, item)
          : '';
      case CLASS_NAME_TYPE.HEADER_CELL:
        return typeof setHeaderCellClassName === 'function'
          ? setHeaderCellClassName(column)
          : '';
      default:
        return '';
    }
  };

  render() {
    const {
      items: rawItems,
      columnsConfig: { columns, config },
      isExternalSort,
    } = this.props;

    const { sortColumn, sortOrder } = isExternalSort ? this.props : this.state;

    const onSort = isExternalSort ? this.onExternalSort : this.onInternalSort;

    const items =
      !isExternalSort && sortColumn && sortOrder
        ? SortableTable.sortItems(
            rawItems,
            config[sortColumn].dataAttribute,
            sortOrder === SORT_ORDER.DESC,
            config[sortColumn].compare,
          )
        : rawItems;

    return (
      <Table celled fixed className="sortable-table">
        <Table.Header>
          <Table.Row>
            {columns.map((column) => (
              <Table.HeaderCell
                data-qa={config[column].label}
                key={column}
                onClick={
                  config[column].sortable ? () => onSort(column) : undefined
                }
                className={cn(
                  {
                    sortable: !!config[column].sortable,
                    asc: sortColumn === column && sortOrder === SORT_ORDER.ASC,
                    desc:
                      sortColumn === column && sortOrder === SORT_ORDER.DESC,
                  },
                  this.setClassName(CLASS_NAME_TYPE.HEADER_CELL, column),
                )}
              >
                {config[column].label}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => (
            <Table.Row
              key={this.getItemKey(null, item)}
              className={this.setClassName(CLASS_NAME_TYPE.ROW, null, item)}
            >
              {columns.map((column) => (
                <Table.Cell
                  data-qa={config[column].label}
                  key={this.getItemKey(column, item)}
                  className={this.setClassName(
                    CLASS_NAME_TYPE.COLUMN,
                    column,
                    item,
                  )}
                >
                  {this.renderItem(column, item)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default SortableTable;
