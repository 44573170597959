import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { TransactionType } from '../types/enums/TransactionType';
import { enumToSelectOptions } from '../utils/components';

interface TransactionTypeSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof TransactionType | (keyof typeof TransactionType)[];
}

const displayName = 'TransactionTypeSelect';
const options = enumToSelectOptions(TransactionType);

export default Object.assign(
  React.memo((props: TransactionTypeSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
