import React, { useState } from 'react';
import { Button, Form, Grid, Header, Label, Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import SubmissionForm from './components/SubmissionForm';
import SubmitConfirmation from './components/SubmitConfirmation';
import Loader from '../../components/Loader';
import Saving from 'src/components/Saving';
import Page404 from '../../components/Page404';
import './CryptoFormCollect.scss';
import FormSubmitted from '../../components/FormSubmitted';
import CryptoFormInstructions from './components/CryptoFormInstructions';
import { useCryptoFormCollect } from './hooks/useCryptoFormCollect';
import { backendUri } from '../../api/client';
import { useLoggedUser } from '../../hooks/useLoggedUser';
import storage from '../../utils/storage';
import CryptoBuySellCorridors from './components/CryptoBuySellCorridors';
import CryptoInOutCorridors from './components/CryptoInOutCorridors';

export default function CryptoFormCollect(): JSX.Element {
  const { formSlug } = useParams<{ formSlug: string }>();
  const formData = useCryptoFormCollect(formSlug);
  const {
    state: {
      isSubmitted,
      canSubmit,
      canSave,
      validationErrors,
      status,
      error,
      form: { submission, buySell, inOut, ...form },
    },
    actions: { submit, save, updateSubmission, uploadImage, deleteImage },
  } = formData;
  const { loggedUser } = useLoggedUser();
  const readOnly = isSubmitted;

  const [submitConfirmationDisplayed, showSubmitConfirmation] = useState(false);

  const imagesBaseDownloadUrl = loggedUser
    ? `${backendUri}/cryptoForms/${
        form.id
      }/images/{filename}?token=${storage.getToken()}`
    : null;

  if (status === 'loading') return <Loader isLoading />;
  if (error === 'FORM_SUBMITTED_ERROR') return <FormSubmitted />;
  if (!form.id) return <Page404 />;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">
              {form.organization?.name}
              <Label>Crypto</Label>
              {form.country && <Label>in {form.country.name}</Label>}
              <Label>{capitalize(form.clientType)} client</Label>
            </Header>
            {isSubmitted && (
              <>
                <br />
                <Message
                  info
                  icon="inbox"
                  header="This form has now been submitted"
                  content="Changes are no longer possible."
                />
              </>
            )}
            {!isSubmitted && <CryptoFormInstructions />}
            {(status === 'saving' || status === 'submitting') && (
              <Saving
                variant={'success'}
                children="Saving..."
                key={'saving_snackbar'}
              />
            )}
            <br />
            <SubmissionForm
              researcherName={form.researcherName}
              clientType={form.clientType}
              submission={submission}
              updateSubmission={updateSubmission}
              editable={!readOnly}
              downloadUrl={imagesBaseDownloadUrl}
              images={form.images}
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              validationErrors={validationErrors.submission}
            />
            <br />
            <CryptoBuySellCorridors formData={formData} />
            <br />
            <br />
            <CryptoInOutCorridors formData={formData} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            {!isSubmitted && status !== 'saving' && (
              <Button
                type="submit"
                data-qa="save-btn"
                color="green"
                disabled={!canSave}
                onClick={save}
              >
                Save
              </Button>
            )}
            {status === 'saving' && (
              <Button type="submit" loading color="green" disabled>
                Saving...
              </Button>
            )}
            <div style={{ display: 'inline-block', width: '1em' }} />
            {!isSubmitted && status !== 'submitting' && (
              <Button
                type="submit"
                data-qa="submit-btn"
                color="blue"
                onClick={() => showSubmitConfirmation(true)}
                disabled={!canSubmit}
              >
                Submit
              </Button>
            )}
            {status === 'submitting' && (
              <Button type="submit" loading color="blue" disabled>
                Submitting...
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SubmitConfirmation
        open={submitConfirmationDisplayed}
        onClose={() => showSubmitConfirmation(false)}
        onSubmit={() => {
          showSubmitConfirmation(false);
          save().then(() => {
            if (canSubmit) {
              submit().then();
            }
          });
        }}
      />
    </Form>
  );
}
