import validator from 'validator';
import { ValidationErrors } from './ValidationErrors';

export interface OnlineUrl {
  url?: string;
  note?: string;
}

export function createOnlineUrl({
  url = '',
  note = '',
}: Partial<OnlineUrl> = {}): OnlineUrl {
  return {
    url,
    note,
  };
}

export function validateOnlineUrl(data: OnlineUrl): ValidationErrors {
  const errors: ValidationErrors = {};
  if (!data.url?.length) {
    errors.url = 'Please fill in this field';
  } else if (!validator.isURL(data.url)) {
    errors.url = 'Not valid url';
  }
  return errors;
}
