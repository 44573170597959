import React from 'react';
import { Message } from 'semantic-ui-react';

export default function FormSubmitted(props) {
  return (
    <div className="ui grid middle aligned">
      <div className="ui column center aligned" data-qa="submitted-form">
        <Message positive>
          <Message.Header>Thank you</Message.Header>
          <Message.Content>
            The form has been successfully submitted. <br />
            In case of any issues, please contact us at{' '}
            <a href="mailto:data_collection@fxcintel.com">
              data_collection@fxcintel.com
            </a>
            .
          </Message.Content>
        </Message>
      </div>
    </div>
  );
}
