import React, { useCallback, useMemo, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import CryptoBuySellCorridorCreateForm from './CryptoBuySellCorridorCreateForm';
import {
  createCryptoBuySellCorridor,
  CryptoBuySellCorridor,
} from '../../../../types/models/CryptoBuySellCorridor';
import CryptoBuySellCorridorView from './CryptoBuySellCorridorView';
import { CryptoBuySellCorridorConfiguration } from '../../../../types/models/CryptoBuySellCorridorConfiguration';
import { createCryptoBuySellRange } from '../../../../types/models/CryptoBuySellRange';
import { createFeeValue } from '../../../../types/models/FeeValue';
import CryptoBuySellCorridorEditForm from './CryptoBuySellCorridorEditForm';
import CryptoBuySellCorridorCloneForm from './CryptoBuySellCorridorCloneForm';
import useModalState from '../../../../hooks/useModalState';
import { UseCryptoFormCollect } from '../../hooks/useCryptoFormCollect';
import { getDefaultFeeCurrencyId } from '../../../../types/models/CryptoBuySellCorridor';
import { FeeValueType } from 'src/types/enums/FeeValueType';

export default function CryptoBuySellCorridors({
  formData,
}: {
  formData: UseCryptoFormCollect;
}): JSX.Element {
  const {
    state: {
      isSubmitted,
      validationErrors,
      form: { buySell, countryId, country, amounts, ...form },
    },
    actions: {
      addBuySellCorridor,
      updateBuySellCorridor,
      deleteBuySellCorridor,
      copyBuySellCorridor,
    },
  } = formData;
  const readOnly = isSubmitted;
  const sortedAmounts = [...(amounts || [])].sort((a, b) => a - b);

  const [
    createCorridorFormDisplayed,
    showCreateCorridorForm,
    hideCreateCorridorForm,
  ] = useModalState();
  const onCreateCorridor = useCallback(
    (configuration: CryptoBuySellCorridorConfiguration) => {
      const defaultFeeCurrencyId = getDefaultFeeCurrencyId(configuration);
      const defaultFeeType =
        configuration.transactionType === 'EXCHANGE_TRADE'
          ? FeeValueType.PERCENTAGE
          : FeeValueType.FIXED;
      addBuySellCorridor(
        createCryptoBuySellCorridor({
          ...configuration,
          ranges: sortedAmounts?.length
            ? [
                ...sortedAmounts.map((amount, i) =>
                  createCryptoBuySellRange({
                    amountFrom:
                      i > 0 ? sortedAmounts[i - 1].toString() : undefined,
                    amountTo: (amount - 0.01).toString(),
                    fees: [
                      createFeeValue({
                        currencyId: defaultFeeCurrencyId,
                        type: defaultFeeType,
                      }),
                    ],
                  }),
                ),
                createCryptoBuySellRange({
                  amountFrom:
                    sortedAmounts[sortedAmounts.length - 1].toString(),
                  fees: [
                    createFeeValue({
                      currencyId: defaultFeeCurrencyId,
                      type: defaultFeeType,
                    }),
                  ],
                }),
              ]
            : [
                createCryptoBuySellRange({
                  fees: [
                    createFeeValue({
                      currencyId: defaultFeeCurrencyId,
                      type: defaultFeeType,
                    }),
                  ],
                }),
              ],
        }),
      );
      hideCreateCorridorForm();
    },
    [addBuySellCorridor, hideCreateCorridorForm, amounts],
  );

  const [editingCorridor, setEditingCorridor] = useState<
    CryptoBuySellCorridor | undefined
  >();
  const onEditCorridor = useCallback(
    (corridor: CryptoBuySellCorridor) => {
      setEditingCorridor(corridor);
    },
    [setEditingCorridor],
  );

  const [cloningCorridor, setCloningCorridor] = useState<
    CryptoBuySellCorridor | undefined
  >();
  const onCopyCorridor = useCallback(
    (corridor: CryptoBuySellCorridor) => {
      setCloningCorridor(corridor);
    },
    [setCloningCorridor],
  );

  // we want to keep the order in the same way in which the corridors were created by the user
  const sortedCorridors = useMemo(
    () =>
      buySell
        .slice()
        .sort((a: CryptoBuySellCorridor, b: CryptoBuySellCorridor) => {
          return a.id - b.id;
        }),
    [buySell],
  );

  return (
    <>
      <Header as="h2">Buying / Selling Cost</Header>
      {sortedCorridors.map((corridor: CryptoBuySellCorridor) => (
        <CryptoBuySellCorridorView
          key={corridor.id}
          corridor={corridor}
          onChange={updateBuySellCorridor}
          onEditConfiguration={onEditCorridor}
          onCopy={onCopyCorridor}
          onDelete={deleteBuySellCorridor}
          readOnly={readOnly}
          validationErrors={validationErrors.buySell[corridor.id]}
          transferDirection={form.transferDirection}
        />
      ))}
      {!readOnly && buySell.length > 0 && (
        <Button
          data-qa="add-new-table"
          type="button"
          color="green"
          onClick={showCreateCorridorForm}
        >
          Add New Table
        </Button>
      )}
      {buySell.length === 0 && (
        <Segment placeholder textAlign="center">
          <Header icon>
            <Icon name="edit outline" />
            No buying / selling cost tables
          </Header>
          {!readOnly && (
            <Button
              onClick={showCreateCorridorForm}
              type="button"
              color="green"
              data-qa="add-new-table"
            >
              Add New Table
            </Button>
          )}
        </Segment>
      )}
      {createCorridorFormDisplayed && (
        <CryptoBuySellCorridorCreateForm
          existsConfigurations={buySell}
          onClose={hideCreateCorridorForm}
          onSubmit={onCreateCorridor}
          transferDirection={form.transferDirection}
          defaultValues={{
            countryId,
            currencyFromIds: country?.defaultCurrencyId
              ? [country?.defaultCurrencyId]
              : [],
          }}
        />
      )}
      {editingCorridor && (
        <CryptoBuySellCorridorEditForm
          corridor={editingCorridor}
          existsConfigurations={buySell.filter(
            (c: CryptoBuySellCorridor) => c.id !== editingCorridor.id,
          )}
          onClose={() => setEditingCorridor(undefined)}
          onSubmit={updateBuySellCorridor}
          transferDirection={form.transferDirection}
        />
      )}
      {cloningCorridor && (
        <CryptoBuySellCorridorCloneForm
          corridor={cloningCorridor}
          existsConfigurations={buySell}
          onClose={() => setCloningCorridor(undefined)}
          onSubmit={copyBuySellCorridor}
          transferDirection={form.transferDirection}
        />
      )}
    </>
  );
}
