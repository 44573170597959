import React from 'react';
import { Form } from 'semantic-ui-react';
import trim from 'lodash/trim';
import LocationSearchInput from '../../../components/LocationSearchInput';
import collectionTypes from '../../../constants/submissionCollectionType';
import './SubmissionForm.scss';
import AccountTypeSelect from '../../../components/AccountTypeSelect';
import ImageAttachments from './ImageAttachments';

const collectionTypesOptions = Object.entries(collectionTypes).map(
  ([value, label]) => ({
    key: value,
    text: label,
    value,
  }),
);

export default class SubmissionForm extends React.PureComponent {
  static defaultProps = {
    editable: true,
  };

  render() {
    const {
      researcherName,
      clientType,
      submission,
      editable,
      downloadUrl,
      images,
      setImages,
    } = this.props;

    return (
      <>
        <Form.Group widths="2">
          <Form.Field>
            <label>Researcher Name</label>
            <div className="submission_form__researcher_name_value">
              {researcherName}
            </div>
          </Form.Field>
          <AccountTypeSelect
            label="Account Type"
            name="accountType"
            clientType={clientType}
            value={submission.accountType}
            onChange={this.onChange}
            required
            open={!editable ? false : undefined}
          />
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field required>
            <label>Researcher Location (closest city)</label>
            <LocationSearchInput
              name="locationAddress"
              value={submission.locationAddress}
              onChange={this.onChangeLocation}
              required
              readOnly={!editable}
            />
          </Form.Field>
          <Form.Input
            label="Account Name"
            name="accountName"
            value={submission.accountName}
            onChange={this.onChange}
            onBlur={this.onChangeWithTrim}
            required
            readOnly={!editable}
          />
        </Form.Group>
        <Form.Group widths="2">
          <Form.Select
            label="Collection Type"
            options={collectionTypesOptions}
            name="collectionType"
            value={submission.collectionType}
            onChange={this.onChange}
            required
            open={!editable ? false : undefined}
          />
          <Form.Field>
            <label>Screenshots of the rate information</label>
            <ImageAttachments
              images={images}
              setImages={setImages}
              downloadUrl={downloadUrl}
              readOnly={!editable}
            />
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  onChangeWithTrim = (event) => {
    const { name, value } = event.target;
    this.props.setSubmission((prev) => ({ ...prev, [name]: trim(value) }));
  };

  onChange = (event, inputProps) => {
    const { name, value } = inputProps;
    this.props.setSubmission((prev) => ({ ...prev, [name]: value }));
  };

  onChangeLocation = (event, inputProps) => {
    const { name, value, lat, lng } = inputProps;
    this.props.setSubmission((prev) => ({
      ...prev,
      [name]: value,
      locationLat: lat,
      locationLng: lng,
    }));
  };
}
