import React, { useCallback, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import CountrySelect from '../../../../components/CountrySelect';
import CurrencySelect from '../../../../components/CurrencySelect';
import PaymentMethodSelect from '../../../../components/PaymentMethodSelect';
import PayoutMethodSelect from '../../../../components/PayoutMethodSelect';
import InOutDirectionSelect from '../../../../components/InOutDirectionSelect';
import {
  CryptoInOutCorridorConfiguration,
  StateCryptoInOutCorridorConfiguration,
} from '../../../../types/models/CryptoInOutCorridorConfiguration';
import CustomerTypeSelect from '../../../../components/CustomerTypeSelect';
import { ValidationErrors } from '../../../../types/models/ValidationErrors';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import { InOutDirection } from 'src/types/enums/InOutDirection';
import { PaymentMethod } from 'src/types/enums/PaymentMethod';
import { PayoutMethod } from 'src/types/enums/PayoutMethod';

export type ChangedCryptoInOutCorridorConfiguration =
  Partial<CryptoInOutCorridorConfiguration>;

export interface CorridorConfigurationProps
  extends StateCryptoInOutCorridorConfiguration {
  onChange: (changed: ChangedCryptoInOutCorridorConfiguration) => void;
  validationErrors?: ValidationErrors;
  transferDirection: keyof typeof TransferDirection;
}

const defaultMethods = {
  [InOutDirection.DEPOSIT]: {
    payment_method: PaymentMethod.BANK_ACCOUNT,
    payout_method: PayoutMethod.PROVIDER_WALLET,
  },
  [InOutDirection.WITHDRAWAL]: {
    payment_method: PaymentMethod.PROVIDER_WALLET,
    payout_method: PayoutMethod.BANK_ACCOUNT,
  },
};

export default function CryptoInOutCorridorConfigurationForm({
  countryId,
  currencyIds,
  paymentMethods,
  payoutMethods,
  paymentDetails,
  payoutDetails,
  customerType,
  onChange,
  transferDirection,
  direction,
  validationErrors = {},
}: CorridorConfigurationProps): JSX.Element {
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed: ChangedCryptoInOutCorridorConfiguration = {
        [name]: value,
      };
      switch (name) {
        case 'direction':
          if (value.length === 1) {
            changed.paymentMethods = [
              defaultMethods[value[0] as InOutDirection].payment_method,
            ];
            changed.payoutMethods = [
              defaultMethods[value[0] as InOutDirection].payout_method,
            ];
            changed.paymentDetails = '';
            changed.payoutDetails = '';
          }
          break;
        case 'countryId':
          changed.currencyIds = [inputProps.defaultCurrencyId];
          break;
        case 'paymentMethods':
          if (value.length !== 1) {
            changed['paymentDetails'] = '';
          }
          break;

        case 'payoutMethods':
          if (value.length !== 1) {
            changed['payoutDetails'] = '';
          }
          break;
      }
      onChange(changed);
    },
    [onChange],
  );
  return (
    <>
      <Form.Group widths="equal">
        <CountrySelect
          label="Country"
          name="countryId"
          placeholder=""
          value={countryId}
          onChange={onChangeField}
          required
          error={validationErrors.countryId}
        />
        <Form.Field>
          <PaymentMethodSelect
            label="Payment Method"
            name="paymentMethods"
            placeholder=""
            value={paymentMethods}
            onChange={onChangeField}
            required
            multiple
            error={validationErrors.paymentMethods}
          />
          <div style={{ height: 10 }} />
          <Form.Input
            label="Payment details"
            name="paymentDetails"
            placeholder="Wire, ACH, etc."
            disabled={paymentMethods.length > 1}
            value={paymentDetails}
            onChange={onChangeField}
          />
        </Form.Field>
        <Form.Field>
          <PayoutMethodSelect
            label="Payout Method"
            name="payoutMethods"
            placeholder=""
            value={payoutMethods}
            onChange={onChangeField}
            required
            multiple
            error={validationErrors.payoutMethods}
          />
          <div style={{ height: 10 }} />
          <Form.Input
            label="Payout details"
            name="payoutDetails"
            placeholder="Wire, ACH, etc."
            disabled={payoutMethods.length > 1}
            value={payoutDetails}
            onChange={onChangeField}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <CurrencySelect
          label="Currency"
          name="currencyIds"
          placeholder=""
          value={currencyIds}
          onChange={onChangeField}
          required
          multiple
          disabled={!countryId}
          error={validationErrors.currencyIds}
        />
        <InOutDirectionSelect
          label="Deposit or Withdrawal"
          name="direction"
          placeholder=""
          value={direction}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.direction}
        />
        <CustomerTypeSelect
          label="Customer Type"
          name="customerType"
          placeholder=""
          value={customerType}
          onChange={onChangeField}
          required
          error={validationErrors.customerType}
        />
      </Form.Group>
    </>
  );
}
