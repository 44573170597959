import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';

export default class SubmitConfirmation extends React.PureComponent {
  state = {
    missingAmountReason: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.missingAmountReason !== this.props.missingAmountReason) {
      this.setState({ missingAmountReason: this.props.missingAmountReason });
    }
  }

  handleSubmit = (event) => {
    const { onSubmit, onClose } = this.props;
    const { missingAmountReason } = this.state;
    onSubmit(event, { missingAmountReason });
    onClose();
    this.setState({ missingAmountReason: '' });
  };

  onChangeReason = (event) => {
    const { value } = event.target;
    this.setState({ missingAmountReason: value });
  };

  render() {
    const { open, onClose, hasMissingAmounts } = this.props;
    const { missingAmountReason } = this.state;
    const canSubmit = hasMissingAmounts ? missingAmountReason.length > 0 : true;
    return (
      <Modal data-qa="modal-dialog" onClose={onClose} open={open} size="mini">
        <Modal.Header>Submit Confirmation</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Once you have submitted this form, changes will no longer be
              possible. Please confirm the following before submitting.
            </p>
            {this.renderMissingReason()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            negative
            content="Cancel"
            onClick={onClose}
            data-qa="cancel"
          />
          <Button
            type="button"
            positive
            onClick={this.handleSubmit}
            content="OK"
            disabled={!canSubmit}
            data-qa="accept"
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderMissingReason() {
    if (!this.props.hasMissingAmounts) {
      return null;
    }
    return (
      <>
        <p>
          You have not entered a value in all fields on the form. Please provide
          a reason for this in the box below.
        </p>
        <Form>
          <Form.Field>
            <Form.TextArea
              data-qa="missingAmountReason"
              value={this.state.missingAmountReason}
              onChange={this.onChangeReason}
            />
          </Form.Field>
        </Form>
      </>
    );
  }
}
