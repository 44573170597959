import React, { useState } from 'react';
import { Grid, Segment, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import authSlice from '../../store/auth/slice';
import './Login.scss';
import { gql, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    token: login(email: $email, password: $password)
  }
`;

const { PUBLIC_URL } = process.env;
const PREFIX = 'login';
const FIELD_NAMES = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const canSubmit = email !== '' && password !== '';

  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ token }) => {
      props.login({ token });
    },
    onError: (error) => {},
  });

  if (loading) return <Loader isLoading />;

  return (
    <div className="login">
      <Grid>
        <Grid.Row centered>
          <Grid.Column>
            <Segment raised>
              <img
                alt="logo"
                className="logo"
                src={`${PUBLIC_URL}/images/fxc_logo.svg`}
              />
              <Form onSubmit={(e) => login({ variables: { email, password } })}>
                <Form.Field>
                  <label htmlFor={`${PREFIX}.${FIELD_NAMES.EMAIL}`}>
                    Email
                  </label>
                  <input
                    id={`${PREFIX}.${FIELD_NAMES.EMAIL}`}
                    name={FIELD_NAMES.EMAIL}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor={`${PREFIX}.${FIELD_NAMES.PASSWORD}`}>
                    Password
                  </label>
                  <input
                    id={`${PREFIX}.${FIELD_NAMES.PASSWORD}`}
                    name={FIELD_NAMES.PASSWORD}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                  />
                </Form.Field>
                <Form.Field className="submit">
                  <Button color="green" type="submit" disabled={!canSubmit}>
                    Login
                  </Button>
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const { login } = authSlice.actions;

export default connect(null, {
  login,
})(Login);
