import React from 'react';
import { connect } from 'react-redux';
import Page404 from './components/Page404';
import Login from './scenes/Login/Login';
import errorHandlerSlice from './store/errorHandler/slice';
import Snackbar from './components/Snackbar';

const ErrorHandler = ({ errorCode, errorMessage, errorId, children }) => {
  switch (errorCode) {
    case 404:
      return <Page404 />;

    case 'UNAUTHENTICATED':
      return <Login />;

    // ... cases for other types of errors

    default:
      return (
        <>
          {errorMessage && (
            <Snackbar variant="error" key={errorId}>
              {errorMessage}
            </Snackbar>
          )}
          {children}
        </>
      );
  }
};

export default connect(
  ({ [errorHandlerSlice.name]: { id, errorCode, errorMessage } }) => ({
    id,
    errorCode,
    errorMessage,
  }),
)(ErrorHandler);
