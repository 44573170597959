import React, { useCallback, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import CountrySelect from '../../../components/CountrySelect';
import CurrencySelect from '../../../components/CurrencySelect';
import PaymentMethodSelect from '../../../components/PaymentMethodSelect';
import PayoutMethodSelect from '../../../components/PayoutMethodSelect';
import TransferMethodSelect from '../../../components/TransferMethodSelect';
import {
  FeeCorridorConfiguration,
  StateFeeCorridorConfiguration,
} from '../../../types/models/FeeCorridorConfiguration';
import CustomerTypeSelect from '../../../components/CustomerTypeSelect';
import {
  ALL_COUNTRIES_ID,
  ALL_OTHERS_ID,
} from '../../../constants/countryGroups';
import { ValidationErrors } from '../../../types/models/ValidationErrors';
import { TransferDirection } from '../../../types/enums/TransferDirection';
import PackageNameSelect from 'src/components/PackageNameSelect';
import { PackageName } from 'src/types/enums/PackageName';
import TransferSpeedSelect from 'src/components/TransferSpeedSelect';

export type ChangedFeeCorridorConfiguration = Partial<FeeCorridorConfiguration>;

export interface CorridorConfigurationProps
  extends StateFeeCorridorConfiguration {
  onChange: (changed: ChangedFeeCorridorConfiguration) => void;
  validationErrors?: ValidationErrors;
  transferDirection: keyof typeof TransferDirection;
}

export default function FeeCorridorConfigurationForm({
  countryFromId,
  currencyFromId,
  countryToIds = [],
  currencyToId,
  paymentMethods,
  payoutMethods,
  paymentDetails,
  payoutDetails,
  transferSpeed,
  transferSpeedDetails,
  transferMethods,
  amountCurrencyId,
  customerType,
  packageName,
  onChange,
  transferDirection,
  validationErrors = {},
}: CorridorConfigurationProps): JSX.Element {
  const currencyToRequired = useMemo(
    () =>
      countryToIds.length === 1 &&
      !countryToIds.includes(ALL_COUNTRIES_ID) &&
      !countryToIds.includes(ALL_OTHERS_ID),
    [countryToIds],
  );
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed: ChangedFeeCorridorConfiguration = { [name]: value };
      switch (name) {
        case 'countryFromId':
          changed.currencyFromId = inputProps.defaultCurrencyId;
          break;
        case 'countryToIds':
          if (value.some((v: number) => v < 1)) {
            changed.currencyToId = 0;
          } else {
            changed.currencyToId = inputProps.defaultCurrencyId;
          }
          break;
        case 'paymentMethods':
          if (value.length !== 1) {
            changed['paymentDetails'] = '';
          }
          break;

        case 'payoutMethods':
          if (value.length !== 1) {
            changed['payoutDetails'] = '';
          }
          break;
      }
      if ('currencyFromId' in changed) {
        changed.amountCurrencyId = changed.currencyFromId;
      }
      onChange(changed);
    },
    [onChange, currencyToRequired],
  );
  return (
    <>
      <Form.Group widths="equal">
        <CountrySelect
          label={
            transferDirection === TransferDirection.SENDING
              ? 'Sending Country'
              : 'Receiving Country'
          }
          name="countryFromId"
          placeholder=""
          value={countryFromId}
          onChange={onChangeField}
          required
          error={validationErrors.countryFromId}
        />
        <CountrySelect
          label={
            transferDirection === TransferDirection.SENDING
              ? 'Receiving Country'
              : 'Sending Country'
          }
          name="countryToIds"
          placeholder=""
          value={countryToIds}
          onChange={onChangeField}
          required
          multiple
          withGroups
          error={validationErrors.countryToIds}
        />
        <PaymentMethodSelect
          label="Payment Method"
          name="paymentMethods"
          placeholder=""
          value={paymentMethods}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.paymentMethods}
        />
        <PayoutMethodSelect
          label="Payout Method"
          name="payoutMethods"
          placeholder=""
          value={payoutMethods}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.payoutMethods}
        />
      </Form.Group>
      <Form.Group widths="equal" style={{marginTop: 25}}>
        <CurrencySelect
          label={
            transferDirection === TransferDirection.SENDING
              ? 'Sending Currency'
              : 'Receiving Currency'
          }
          name="currencyFromId"
          placeholder=""
          value={currencyFromId}
          onChange={onChangeField}
          required
          disabled={!countryFromId}
          error={validationErrors.currencyFromId}
        />
        <CurrencySelect
          label={
            transferDirection === TransferDirection.SENDING
              ? 'Receiving Currency'
              : 'Sending Currency'
          }
          name="currencyToId"
          placeholder=""
          value={currencyToId === null ? 0 : currencyToId}
          onChange={onChangeField}
          required
          error={validationErrors.currencyToId}
          local
        />
        <Form.Input
          label="Payment details"
          name="paymentDetails"
          placeholder="Wire, ACH, etc."
          disabled={paymentMethods.length > 1}
          value={paymentDetails}
          onChange={onChangeField}
        />
        <Form.Input
          label="Payout details"
          name="payoutDetails"
          placeholder="Wire, ACH, etc."
          disabled={payoutMethods.length > 1}
          value={payoutDetails}
          onChange={onChangeField}
        />
      </Form.Group>
      <Form.Group widths="4" style={{marginTop: 25}}>
        <CurrencySelect
          label="Amount Currency"
          name="amountCurrencyId"
          placeholder=""
          value={amountCurrencyId}
          onChange={onChangeField}
          required
          error={validationErrors.amountCurrencyId}
        />
        <TransferMethodSelect
          label="Transfer Method"
          name="transferMethods"
          placeholder=""
          value={transferMethods}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.transferMethods}
        />
        <CustomerTypeSelect
          label="Customer Type"
          name="customerType"
          placeholder=""
          value={customerType}
          onChange={onChangeField}
          required
          error={validationErrors.customerType}
        />
      </Form.Group>
      <Form.Group widths="4" style={{marginTop: 25}}>
        <TransferSpeedSelect
          label="Transfer speed"
          name="transferSpeed"
          value={transferSpeed}
          onChange={onChangeField}
          required
          error={validationErrors.transferSpeed}
        />
        <Form.Input
          label="Transfer speed details"
          name="transferSpeedDetails"
          placeholder="in minutes, 1 days, 2 days, etc."
          value={transferSpeedDetails}
          onChange={onChangeField}
          error={validationErrors.transferSpeedDetails}
        />
        <PackageNameSelect
          label="Transfer Speed Nature"
          name="packageName"
          placeholder=""
          value={packageName as PackageName}
          onChange={onChangeField}
          required
          error={validationErrors.packageName}
        />
      </Form.Group>
      <Form.Group widths="four"></Form.Group>
    </>
  );
}
