import autobind from 'autobind-decorator';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import formatISO from 'date-fns/formatISO';
import { FetchResult } from '@apollo/client/link/core';
import omitDeep from 'omit-deep-lodash';
import {
  createCryptoBuySellCorridor,
  CryptoBuySellCorridor,
  validateCryptoBuySellCorridor,
} from '../../../types/models/CryptoBuySellCorridor';
import {
  createCryptoBuySellRange,
  CryptoBuySellRange,
} from '../../../types/models/CryptoBuySellRange';
import {
  createCryptoInOutCorridor,
  CryptoInOutCorridor,
  validateCryptoInOutCorridor,
} from '../../../types/models/CryptoInOutCorridor';
import {
  createCryptoInOutRange,
  CryptoInOutRange,
} from '../../../types/models/CryptoInOutRange';
import { createFeeValue, FeeValue } from '../../../types/models/FeeValue';
import {
  CryptoFormSubmission,
  validateCryptoFormSubmission,
} from '../../../types/models/CryptoFormSubmission';
import { FeeSubmissionCollectionType } from '../../../types/enums/FeeSubmissionCollectionType';
import {
  CollectCryptoFormFields,
  DELETE_IMAGE,
  GET_FORM,
  SAVE_FORM,
  SUBMIT_FORM,
  UPLOAD_IMAGES,
  UploadCryptoFormFields,
} from '../gql';
import { FeeValueType } from '../../../types/enums/FeeValueType';
import { ValidationErrors } from '../../../types/models/ValidationErrors';
import { updateItem } from '../../../utils/collection';
import { TransferDirection } from '../../../types/enums/TransferDirection';

export const defaultSubmission = {
  locationAddress: '',
  collectionType: FeeSubmissionCollectionType.ONLINE,
  accountType: '',
  accountName: '',
  onlineUrls: [
    {
      url: '',
      note: '',
    },
  ],
};

enum Status {
  READY = 'ready',
  SAVING = 'saving',
  SUBMITTING = 'submitting',
  LOADING = 'loading',
  ERROR = 'error',
}

interface State {
  form: Partial<
    Omit<
      CollectCryptoFormFields,
      'buySell' | 'inOut' | 'submission' | 'transferDirection'
    >
  > & {
    buySell: CollectCryptoFormFields['buySell'];
    inOut: CollectCryptoFormFields['inOut'];
    submission: CollectCryptoFormFields['submission'];
    transferDirection: CollectCryptoFormFields['transferDirection'];
  };
  fetched: any;
  status: Status;
  error: null | string;
  changedAt: null | number;
}

const initialState: State = {
  form: {
    transferDirection: TransferDirection.SENDING,
    savedAt: null,
    submittedAt: null,
    submission: { ...defaultSubmission },
    buySell: [],
    inOut: [],
    images: [],
  },
  fetched: {},
  status: Status.LOADING,
  error: null,
  changedAt: null,
};

function reducer(
  state: State,
  { type, payload }: { type: string; payload?: any },
): State {
  if (state.status === Status.SAVING && !['FETCHED', 'ERROR'].includes(type)) {
    return { ...state };
  }
  switch (type) {
    case 'FETCHED':
      return {
        ...state,
        form: {
          ...state.form,
          ...get(payload, 'form', {}),
          submission: {
            ...defaultSubmission,
            ...state.form.submission,
            ...get(payload, 'form.submission', {}),
          },
        },
        fetched: {
          ...state.fetched,
          ...get(payload, 'form', {}),
        },
        status: Status.READY,
        error: null,
        changedAt: null,
      };
    case 'ERROR':
      return {
        ...state,
        status: Status.ERROR,
        error: payload as string,
      };
    case 'SUBMIT':
      return {
        ...state,
        status: Status.SUBMITTING,
      };
    case 'SAVE':
      return {
        ...state,
        status: Status.SAVING,
      };
    case 'UPDATE_SUBMISSION':
      return {
        ...state,
        form: {
          ...state.form,
          submission: {
            ...state.form.submission,
            ...payload,
          },
        },
        changedAt: Date.now(),
      };
    case 'ADD_BUY_SELL_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          buySell: [...state.form.buySell, payload],
        },
        changedAt: Date.now(),
      };
    case 'UPDATE_BUY_SELL_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          buySell: updateItem(state.form.buySell, payload),
        },
        changedAt: Date.now(),
      };
    case 'DELETE_BUY_SELL_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          buySell: state.form.buySell.filter(
            (corridor: CryptoBuySellCorridor) => corridor.id !== payload,
          ),
        },
        changedAt: Date.now(),
      };
    case 'ADD_IN_OUT_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          inOut: [...state.form.inOut, payload],
        },
        changedAt: Date.now(),
      };
    case 'UPDATE_IN_OUT_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          inOut: updateItem(state.form.inOut, payload),
        },
        changedAt: Date.now(),
      };
    case 'DELETE_IN_OUT_CORRIDOR':
      return {
        ...state,
        form: {
          ...state.form,
          inOut: state.form.inOut.filter(
            (corridor: CryptoInOutCorridor) => corridor.id !== payload,
          ),
        },
        changedAt: Date.now(),
      };
    default:
      throw new Error();
  }
}

function cloneCryptoBuySellCorridor(
  corridor: CryptoBuySellCorridor,
): CryptoBuySellCorridor {
  return createCryptoBuySellCorridor({
    ...corridor,
    ranges: corridor.ranges.map((range) =>
      createCryptoBuySellRange({
        ...range,
        editedAt: new Date(),
        rate: '',
        fees: range.fees.map((value) =>
          createFeeValue({
            ...value,
            ...(value.type === FeeValueType.PERCENTAGE
              ? {
                  minValue: '',
                  maxValue: '',
                }
              : {}),
            value: '',
          }),
        ),
      }),
    ),
  });
}

function cloneCryptoInOutCorridor(
  corridor: CryptoInOutCorridor,
): CryptoInOutCorridor {
  return createCryptoInOutCorridor({
    ...corridor,
    ranges: corridor.ranges.map((range) =>
      createCryptoInOutRange({
        ...range,
        editedAt: new Date(),
        fees: range.fees.map((value) =>
          createFeeValue({
            ...value,
            ...(value.type === FeeValueType.PERCENTAGE
              ? {
                  minValue: '',
                  maxValue: '',
                }
              : {}),
            value: '',
          }),
        ),
      }),
    ),
  });
}

export type UseCryptoFormCollect = {
  state: State & {
    isSubmitted: boolean;
    canSubmit: boolean;
    canSave: boolean;
    validationErrors: {
      submission: ValidationErrors;
      buySell: {
        [key: number]: ValidationErrors | undefined;
      };
      inOut: {
        [key: number]: ValidationErrors | undefined;
      };
    };
  };
  actions: {
    save: () => Promise<FetchResult<CollectCryptoFormFields>>;
    submit: () => Promise<FetchResult<CollectCryptoFormFields>>;
    updateSubmission: (updated: Partial<CryptoFormSubmission>) => void;
    addBuySellCorridor: (
      corridor: CryptoBuySellCorridor,
    ) => Promise<FetchResult<UploadCryptoFormFields>>;
    copyBuySellCorridor: (corridor: CryptoBuySellCorridor) => void;
    updateBuySellCorridor: (
      id: number,
      updated: Partial<CryptoBuySellCorridor>,
    ) => void;
    deleteBuySellCorridor: (id: number) => void;
    addInOutCorridor: (
      corridor: CryptoInOutCorridor,
    ) => Promise<FetchResult<UploadCryptoFormFields>>;
    copyInOutCorridor: (corridor: CryptoInOutCorridor) => void;
    updateInOutCorridor: (
      id: number,
      updated: Partial<CryptoInOutCorridor>,
    ) => void;
    deleteInOutCorridor: (id: number) => void;
    uploadImage: (image: File) => Promise<FetchResult<UploadCryptoFormFields>>;
    deleteImage: (image: string) => Promise<FetchResult<UploadCryptoFormFields>>
  };
};

export function useCryptoFormCollect(formSlug: string): UseCryptoFormCollect {
  const [state, dispatch] = useReducer(reducer, initialState);

  const validationErrors = useMemo(
    () => ({
      submission: validateCryptoFormSubmission(state.form.submission),
      buySell: Object.fromEntries(
        state.form.buySell
          .map((corridor: CryptoBuySellCorridor) => {
            const errors = validateCryptoBuySellCorridor(corridor);
            return [corridor.id, !isEmpty(errors) ? errors : undefined];
          })
          .filter(([id, errors]) => errors !== undefined),
      ),
      inOut: Object.fromEntries(
        state.form.inOut
          .map((corridor: CryptoInOutCorridor) => {
            const errors = validateCryptoInOutCorridor(corridor);
            return [corridor.id, !isEmpty(errors) ? errors : undefined];
          })
          .filter(([id, errors]) => errors !== undefined),
      ),
    }),
    [state.form.submission, state.form.buySell, state.form.inOut],
  );

  useQuery(GET_FORM, {
    variables: { formSlug },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (data) {
        // sometimes it may be undefined (don't know why)
        dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
      }
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const [submitMutation] = useMutation(SUBMIT_FORM, {
    onCompleted: (data) => {
      dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const submit = useCallback(() => {
    dispatch({ type: 'SUBMIT' });
    return submitMutation({
      variables: {
        formSlug: state.form.slug,
      },
    });
  }, [submitMutation, dispatch, state]);

  const isSubmitted = Boolean(state.form.submittedAt);
  const canSubmit = useMemo(
    // eslint-disable-next-line max-len
    () =>
      !isSubmitted &&
      state.status === 'ready' &&
      (state.form.buySell.length > 0 || state.form.inOut.length > 0) &&
      isEmpty(validationErrors.submission) &&
      isEmpty(validationErrors.buySell) &&
      isEmpty(validationErrors.inOut),
    [
      isSubmitted,
      state.form.buySell.length,
      state.form.inOut.length,
      validationErrors,
      state.status,
    ],
  );

  const [saveMutation] = useMutation(SAVE_FORM, {
    onCompleted: (data) => {
      dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      dispatch({ type: 'ERROR', payload: code });
    },
  });

  const save = useCallback(() => {
    dispatch({ type: 'SAVE' });
    const converter = new StateConverter(state);
    return saveMutation({
      variables: converter.convert(),
    });
  }, [saveMutation, dispatch, state]);

  useEffect(() => {
    const timeout = setTimeout(() => state.changedAt && save(), 3000);
    return () => clearTimeout(timeout);
  }, [state.changedAt, save]);

  const canSave = Boolean(state.status === 'ready');

  const [uploadMutation] = useMutation(UPLOAD_IMAGES, {
    onError: () => undefined,
  });

  const [deleteImageMutation] = useMutation(DELETE_IMAGE, {
    onError: () => undefined
  })

  const uploadImage = useCallback(
    (image: File) => {
      dispatch({ type: 'SAVE' });
      return uploadMutation({
        variables: {
          formSlug: state.form.slug,
          images: [image],
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [uploadMutation, dispatch, state.form.slug],
  );

  const deleteImage = useCallback(
    (image: string) => {
      dispatch({ type: 'SAVE' });
      return deleteImageMutation({
        variables: {
          formSlug: state.form.slug,
          image: image,
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [deleteImageMutation, dispatch, state.form.slug],
  );

  const updateSubmission = useCallback(
    (payload) => {
      dispatch({ type: 'UPDATE_SUBMISSION', payload });
    },
    [dispatch],
  );

  const addBuySellCorridor = useCallback(
    async (payload) => {
      dispatch({ type: 'SAVE' });
      const converter = new StateConverter(state);
      const newCorridor = converter.convertBuySellCorridor(payload);
      return saveMutation({
        variables: {
          formSlug: state.form.slug,
          buySell: {
            create: [newCorridor.create],
          },
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [dispatch, saveMutation, state],
  );

  const updateBuySellCorridor = useCallback(
    (id, changed) => {
      dispatch({
        type: 'UPDATE_BUY_SELL_CORRIDOR',
        payload: { id, ...changed },
      });
    },
    [dispatch],
  );

  const deleteBuySellCorridor = useCallback(
    (payload) => {
      dispatch({ type: 'DELETE_BUY_SELL_CORRIDOR', payload });
    },
    [dispatch],
  );

  const copyBuySellCorridor = useCallback(
    (payload: CryptoBuySellCorridor) => {
      const newCorridor = cloneCryptoBuySellCorridor(payload);
      return addBuySellCorridor(newCorridor);
    },
    [addBuySellCorridor],
  );

  const addInOutCorridor = useCallback(
    async (payload) => {
      dispatch({ type: 'SAVE' });
      const converter = new StateConverter(state);
      const newCorridor = converter.convertInOutCorridor(payload);
      return saveMutation({
        variables: {
          formSlug: state.form.slug,
          inOut: {
            create: [newCorridor.create],
          },
        },
      }).then(
        ({ data }) => {
          dispatch({ type: 'FETCHED', payload: omitDeep(data, '__typename') });
          return data;
        },
        (error) => {
          const code = get(error.graphQLErrors, '0.extensions.code');
          dispatch({ type: 'ERROR', payload: code });
          return error;
        },
      );
    },
    [dispatch, saveMutation, state],
  );

  const updateInOutCorridor = useCallback(
    (id, changed) => {
      dispatch({ type: 'UPDATE_IN_OUT_CORRIDOR', payload: { id, ...changed } });
    },
    [dispatch],
  );

  const deleteInOutCorridor = useCallback(
    (payload) => {
      dispatch({ type: 'DELETE_IN_OUT_CORRIDOR', payload });
    },
    [dispatch],
  );

  const copyInOutCorridor = useCallback(
    (payload: CryptoInOutCorridor) => {
      const newCorridor = cloneCryptoInOutCorridor(payload);
      return addInOutCorridor(newCorridor);
    },
    [addInOutCorridor],
  );

  return {
    state: {
      ...state,
      isSubmitted,
      canSubmit,
      canSave,
      validationErrors,
    },
    actions: {
      save,
      submit,
      updateSubmission,
      addBuySellCorridor,
      copyBuySellCorridor,
      updateBuySellCorridor,
      deleteBuySellCorridor,
      addInOutCorridor,
      copyInOutCorridor,
      updateInOutCorridor,
      deleteInOutCorridor,
      uploadImage,
      deleteImage
    },
  };
}

@autobind
export class StateConverter {
  private state: State;

  constructor(state: State) {
    this.state = state;
  }

  convert() {
    const prevBuySellIds = get(this.state.fetched, 'buySell', []).map(
      ({ id }: { id: number }) => id,
    );
    const currBuySellIds = this.state.form.buySell.map(
      ({ id }: { id: number }) => id,
    );
    const deleteBuySellIds = difference(prevBuySellIds, currBuySellIds);

    const prevInOutIds = get(this.state.fetched, 'inOut', []).map(
      ({ id }: { id: number }) => id,
    );
    const currInOutIds = this.state.form.inOut.map(
      ({ id }: { id: number }) => id,
    );
    const deleteInOutIds = difference(prevInOutIds, currInOutIds);

    return {
      formSlug: this.state.form.slug,
      submission: {
        upsert: this.convertSubmission(this.state.form.submission),
      },
      buySell: {
        upsert: this.state.form.buySell.map(this.convertBuySellCorridor),
        deleteMany: {
          id: { in: deleteBuySellIds },
        },
      },
      inOut: {
        upsert: this.state.form.inOut.map(this.convertInOutCorridor),
        deleteMany: {
          id: { in: deleteInOutIds },
        },
      },
    };
  }

  convertSubmission(submission: CryptoFormSubmission) {
    return {
      create: submission,
      update: {
        ...this.addSetForFields(submission),
        onlineUrls: submission.onlineUrls,
      },
    };
  }

  convertBuySellCorridor(corridor: CryptoBuySellCorridor) {
    const { id, ranges, collectedAtLocal, ...others } = corridor;
    const convertedRanges = ranges.map(this.convertBuySellRange);
    const prevCorridor = get(this.state.fetched, 'buySell', []).find(
      (c: any) => c.id === corridor.id,
    );
    const prevIds = (prevCorridor?.ranges || []).map(
      ({ id }: { id: number }) => id,
    );
    const currIds = ranges.map(({ id }) => id);
    const deleteIds = difference(prevIds, currIds);

    const fields = {
      ...others,
      collectedAtLocal:
        collectedAtLocal instanceof Date
          ? formatISO(collectedAtLocal).slice(0, 19)
          : collectedAtLocal, // omit timezone
    };
    return {
      where: { id },
      create: {
        ...fields,
        currencyFromIds: { set: corridor.currencyFromIds },
        currencyToIds: { set: corridor.currencyToIds },
        paymentMethods: { set: corridor.paymentMethods },
        payoutMethods: { set: corridor.payoutMethods },
        ranges: { create: convertedRanges.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        ranges: {
          upsert: convertedRanges,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertBuySellRange(range: CryptoBuySellRange) {
    const { id, amountFrom, amountTo, rate, fees, ...others } = range;
    const fields = {
      ...others,
      amountFrom: Number(amountFrom),
      amountTo: amountTo ? Number(amountTo) : null,
      rate: Number(rate),
    };

    const convertedValues = fees.map(this.convertFeeValue);
    const prevRange = get(this.state.fetched, 'buySell', [])
      .reduce(
        (acc: CryptoBuySellRange[], corridor: CryptoBuySellCorridor) =>
          acc.concat(corridor.ranges),
        [],
      )
      .find((c: any) => c.id === range.id);
    const prevIds = (prevRange?.fees || []).map(({ id }: { id: number }) => id);
    const currIds = fees.map(({ id }: { id: number }) => id);
    const deleteIds = difference(prevIds, currIds);

    return {
      where: { id },
      create: {
        ...fields,
        fees: { create: convertedValues.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        fees: {
          upsert: convertedValues,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertInOutCorridor(corridor: CryptoInOutCorridor) {
    const { id, ranges, collectedAtLocal, ...others } = corridor;
    const convertedRanges = ranges.map(this.convertInOutRange);
    const prevCorridor = get(this.state.fetched, 'inOut', []).find(
      (c: any) => c.id === corridor.id,
    );
    const prevIds = (prevCorridor?.ranges || []).map(
      ({ id }: { id: number }) => id,
    );
    const currIds = ranges.map(({ id }) => id);
    const deleteIds = difference(prevIds, currIds);

    const fields = {
      ...others,
      collectedAtLocal:
        collectedAtLocal instanceof Date
          ? formatISO(collectedAtLocal).slice(0, 19)
          : collectedAtLocal, // omit timezone
    };

    return {
      where: { id },
      create: {
        ...fields,
        currencyIds: { set: corridor.currencyIds },
        paymentMethods: { set: corridor.paymentMethods },
        payoutMethods: { set: corridor.payoutMethods },
        direction: { set: corridor.direction },
        ranges: { create: convertedRanges.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        ranges: {
          upsert: convertedRanges,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertInOutRange(range: CryptoInOutRange) {
    const { id, amountFrom, amountTo, fees, ...others } = range;
    const fields = {
      ...others,
      amountFrom: Number(amountFrom),
      amountTo: amountTo ? Number(amountTo) : null,
    };
    const convertedValues = fees.map(this.convertFeeValue);
    const prevRange = get(this.state.fetched, 'inOut', [])
      .reduce(
        (acc: CryptoInOutRange[], corridor: CryptoInOutCorridor) =>
          acc.concat(corridor.ranges),
        [],
      )
      .find((c: any) => c.id === range.id);
    const prevIds = (prevRange?.fees || []).map(({ id }: { id: number }) => id);
    const currIds = fees.map(({ id }: { id: number }) => id);
    const deleteIds = difference(prevIds, currIds);

    return {
      where: { id },
      create: {
        ...fields,
        fees: { create: convertedValues.map(this.createOnly) },
      },
      update: {
        ...this.addSetForFields(fields),
        fees: {
          upsert: convertedValues,
          deleteMany: {
            id: { in: deleteIds },
          },
        },
      },
    };
  }

  convertFeeValue(feeValue: FeeValue, priority: number) {
    const { id, currencyId, type } = feeValue;
    const fields = {
      ...(feeValue.type === FeeValueType.PERCENTAGE
        ? {
            minValue:
              feeValue.minValue !== '' && feeValue.minValue !== null
                ? Number(feeValue.minValue)
                : null,
            maxValue:
              feeValue.maxValue !== '' && feeValue.maxValue !== null
                ? Number(feeValue.maxValue)
                : null,
          }
        : {}),
      value: feeValue.value !== '' ? Number(feeValue.value) : 0,
      currencyId: currencyId || null,
      type,
      priority,
    };
    return {
      where: { id },
      create: fields,
      update: this.addSetForFields(fields),
    };
  }

  addSetForFields(object: any) {
    const entries = Object.entries(object).map(([key, value]) => [
      key,
      { set: value },
    ]);
    return Object.fromEntries(entries);
  }

  createOnly = ({ create }: { create: any }) => create;
}
