/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Header, Label, Message } from 'semantic-ui-react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import some from 'lodash/some';
import capitalize from 'lodash/capitalize';
import formatISO from 'date-fns/formatISO';
import SubmissionForm from './components/SubmissionForm';
import RatesTable from './components/RatesTable';
import SubmitConfirmation from './components/SubmitConfirmation';
import Loader from '../../components/Loader';
import Page404 from '../../components/Page404';
import './FormRateCollect.scss';
import FormSubmitted from '../../components/FormSubmitted';
import { useLoggedUser } from 'src/hooks/useLoggedUser';
import { backendUri } from '../../api/client';
import storage from '../../utils/storage';

export default function FormRateCollect() {
  const { formSlug } = useParams();
  const [submitConfirmationDisplayed, showSubmitConfirmation] = useState(false);
  const {
    form,
    formError,
    loading,
    submitForm,
    submission,
    setSubmission,
    rates,
    setRates,
    addAmount,
    images,
    setImages,
  } = useCollectForm(formSlug);

  const { loggedUser } = useLoggedUser();

  const imagesBaseDownloadUrl = loggedUser
    ? `${backendUri}/formRates/${
        form.id
      }/images/{filename}?token=${storage.getToken()}`
    : null;

  const hasSubmittedAlready = useMemo(
    () => Boolean(form && form.submission && form.submission.submittedAt),
    [form],
  );
  const hasMissingAmounts = useMemo(
    () =>
      some(form.amounts, (amount) =>
        some(
          form.currenciesTo,
          (currencyTo) =>
            !some(rates, {
              currencyToId: currencyTo.id,
              currencyFromId: form.currencyFrom.id,
              amount,
            }),
        ),
      ),
    [form, rates],
  );
  const editable = !hasSubmittedAlready;

  const canBeSubmitted = useMemo(() => {
    if (hasSubmittedAlready || rates.length === 0) {
      return false;
    }
    const requiredSubmissionFields = [
      'locationAddress',
      'collectionType',
      'accountType',
      'accountName',
    ];
    for (const fieldName of requiredSubmissionFields) {
      if (!submission[fieldName]) {
        return false;
      }
    }
    for (const rate of rates) {
      if (!rate.collectedAtLocal) {
        return false;
      }
    }
    return true;
  }, [hasSubmittedAlready, submission, rates]);

  if (loading) return <Loader isLoading={loading} />;
  if (formError === 'FORM_SUBMITTED_ERROR') return <FormSubmitted />;
  if (!form.id) return <Page404 />;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">
              {form.organization.name}
              <Label>Rates</Label>
              <Label>in {form.country.name}</Label>
              <Label>{capitalize(form.clientType)} client</Label>
            </Header>
            {hasSubmittedAlready && (
              <>
                <br />
                <Message
                  info
                  icon="inbox"
                  header="This form has now been submitted"
                  content="Changes are no longer possible."
                />
              </>
            )}
            {!hasSubmittedAlready && (
              <Message>
                <Message.Header>
                  Firstly, you need to enter this information into the form:
                </Message.Header>
                <Message.List>
                  <Message.Item>
                    Account type (standard consumer account, premier consumer
                    account, standard business account, etc.)
                  </Message.Item>
                  <Message.Item>Account name (e.g. HSBC Advanced)</Message.Item>
                  <Message.Item>
                    Location (enter the city you are currently in, and not the
                    location of the bank you are collecting data for)
                  </Message.Item>
                  <Message.Item>
                    Collection type (phone, online, or in-person)
                  </Message.Item>
                </Message.List>
                <br />
                <Message.Content>
                  <p>
                    You can then enter the foreign exchange rates for the
                    different currency pairs and amounts indicated, by checking
                    your online banking portal, calling your bank or visiting
                    your branch. The foreign exchange rates need to be precise,
                    and include at least 5 digits. If your bank provides
                    receiving amounts and not fx rates, you can also enter those
                    amounts in the form, by selecting "this bank provides
                    Received Amount".
                  </p>
                  <p>
                    As the forms can be quite wide, please make sure to scroll
                    to the right to enter all amounts and the 'Collected At'
                    time. The collection time for each currency pair needs to be
                    filled out by entering the date and local time. Please note
                    that you need to enter the time of where you physically are
                    at the time of collection, and not the time of the location
                    of your bank. Please also try to be as accurate as possible
                    regarding the time (up to the minute).
                  </p>
                  <p>
                    You can then click on Submit and we will receive the data!
                  </p>
                </Message.Content>
              </Message>
            )}
            <br />
            <SubmissionForm
              researcherName={form.researcherName}
              clientType={form.clientType}
              submission={submission}
              setSubmission={setSubmission}
              editable={editable}
              downloadUrl={imagesBaseDownloadUrl}
              images={form.images}
              setImages={setImages}
            />
            <br />
            <RatesTable
              form={form}
              submission={submission}
              setSubmission={setSubmission}
              addAmount={addAmount}
              rates={rates}
              setRates={setRates}
              editable={editable}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            {!hasSubmittedAlready && (
              <Button
                type="submit"
                data-qa="submit-btn"
                color="blue"
                onClick={() => showSubmitConfirmation(true)}
                disabled={!canBeSubmitted}
              >
                Submit
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SubmitConfirmation
        hasMissingAmounts={hasMissingAmounts}
        missingAmountReason={submission.missingAmountReason}
        open={submitConfirmationDisplayed}
        onClose={() => showSubmitConfirmation(false)}
        onSubmit={(e, { missingAmountReason }) => {
          showSubmitConfirmation(false);
          submitForm({
            variables: {
              formSlug: form.slug,
              submission: {
                ...submission,
                missingAmountReason,
              },
              rates: rates.map((rate) => ({
                ...rate,
                rate: rate.rate
                  ? rate.rate * 1
                  : rate.receivedAmount / rate.amount,
                receivedAmount: rate.receivedAmount
                  ? rate.receivedAmount * 1
                  : rate.rate * rate.amount,
                collectedAtLocal: formatISO(rate.collectedAtLocal).slice(0, 19), // omit timezone
              })),
              images,
            },
          });
        }}
      />
    </Form>
  );
}

const FIELDS = gql`
  fragment CollectFormRateFields on FormRate {
    id
    slug
    researcherName
    clientType
    collection {
      startAt
      endAt
    }
    organization {
      name
    }
    country {
      name
    }
    currencyFrom {
      id
      iso3Code
    }
    currenciesTo {
      id
      iso3Code
    }
    amounts
    submission {
      accountType
      accountName
      collectionType
      locationAddress
      providedRate
      submittedAt
      missingAmountReason
    }
    rates {
      currencyFromId
      currencyToId
      amount
      rate
      receivedAmount
      collectedAtLocal
    }
    images
  }
`;

const GET_FORM = gql`
  query GetCollectForm($formSlug: String!) {
    form: formRate(where: { slug: $formSlug }) {
      ...CollectFormRateFields
    }
  }
  ${FIELDS}
`;

const SUBMIT_FORM = gql`
  mutation SubmitForm(
    $formSlug: String!
    $rates: [FormRateSubmitRateInput!]!
    $submission: FormRateSubmitSubmissionInput!
    $images: [Upload!]
  ) {
    submitFormRate(
      where: { slug: $formSlug }
      data: { submission: $submission, rates: $rates, images: $images }
    ) {
      ...CollectFormRateFields
    }
  }
  ${FIELDS}
`;

function useCollectForm(formSlug) {
  const [submission, setSubmission] = useState({
    locationAddress: '',
    collectionType: '',
    accountType: '',
    accountName: '',
    providedRate: '',
    missingAmountReason: '',
  });
  const [images, setImages] = useState([]);
  const [formError, setFormError] = useState(null);
  const [rates, setRates] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const { data, loading } = useQuery(GET_FORM, {
    variables: { formSlug },
    onCompleted: (fetched) => {
      const submissionData = get(fetched, 'form.submission');
      if (submissionData) {
        setSubmission(submissionData);
      }
      setRates([]);
      const amounts = get(fetched, 'form.amounts', []);
      const rates = get(fetched, 'form.rates', []);
      const extraAmounts = rates.map((rate) => rate.amount);
      const images = get(fetched, 'form.images', []);
      setImages(images);
      setAmounts(uniq([...amounts, ...extraAmounts]).sort((a, b) => a - b));
    },
    onError: (error) => {
      const code = get(error.graphQLErrors, '0.extensions.code');
      setFormError(code);
    },
  });
  const [submitForm] = useMutation(SUBMIT_FORM, {
    onError: () => {},
  });

  const form = useMemo(
    () => ({
      ...get(data, 'form', {}),
      amounts,
    }),
    [data, amounts],
  );

  const addAmount = useCallback(
    (amount) => {
      setAmounts((prev) => uniq([...prev, amount]));
    },
    [setAmounts],
  );

  return {
    form,
    formError,
    loading,
    submitForm,
    submission,
    setSubmission,
    rates,
    setRates,
    addAmount,
    images,
    setImages,
  };
}
