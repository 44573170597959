/* eslint-disable max-len */
import { Message, Header } from 'semantic-ui-react';
import React from 'react';

export default function CryptoFormInstructions(): JSX.Element {
  return (
    <Message>
      <Message.Header>
        First, you need to enter the following information into the form:
      </Message.Header>
      <Message.List>
        <Message.Item>
          Account type (standard consumer account, premier consumer account, standard business account, etc.)
        </Message.Item>
        <Message.Item>Account name (e.g. Coinbase Standard Account)</Message.Item>
        <Message.Item>
          Location (enter the city you are currently in, not the location of the provider you are collecting data for)

        </Message.Item>
        <Message.Item>
          Collection type (phone, online, or in-person). If you have collected the fee information online, please provide the URL.
        </Message.Item>
        <Message.Item>
          Please take screenshots of the fees for the different transactions of interest (buy, sell, deposit and withdrawal) and upload them to the form by clicking on “attach images”.
        </Message.Item>
      </Message.List>
      <br />
      <Message.Content>
        <Header as="h4">We would like you to collect data for different types of transactions</Header>
        <br/>
        <p><b>Buying crypto with fiat / selling crypto into fiat</b></p>
        <p>To do so, please click on “add new table”. You then need to enter the following information:</p>
        <ul>
          <li>country you are located in / for which you are collecting the data</li>
          <li>payment method (bank account, provider wallet, credit or debit card, etc.)</li>
          <li>pay-out method (bank account, provider wallet, etc.)</li>
          <li>buy currency, i.e. the currency you are buying using the currency you already have</li>
          <li>sell currency, i.e. the currency you are selling in exchange for the currency you will be receiving or buying</li>
          <li>customer type (new, existing, or not specified)</li>
        </ul>

        <p>You can then add a table and need to enter two types of information:</p>
        <ol>
        <li>Whether you are entering rates or % margins:<br/>
          If on the calculator of the exchange, you can see that when selling USD10, you will get 0.00024041 BTC, you are entering a rate.<br/>
          If the exchange mentions that they take a 2% fee to buy or sell crypto, you are entering a margin.</li>
        <li>Please enter your time and date of collection (local time).</li>
        </ol>
          
          
          <p>For the different amounts specified, you then need to enter:</p>
          
          <ol>
        <li>In the Rate section: the rates or % margin applied by the exchange to buy crypto with fiat or sell crypto into fiat</li>
        <li>In the Fee section: the fixed fees charged by the exchange for these transactions</li>
        </ol>
        <br/>
        <p><b>Depositing fiat onto the exchange and withdrawing fiat out of the exchange</b></p>
        <p>To do so, please click on “add new table”. You then need to enter the following information:</p>
        <ul>
          <li>country you are located in / for which you are collecting the data</li>
          <li>payment method (bank account, provider wallet, credit or debit card, etc.)</li>
          <li>pay-out method (bank account, provider wallet, etc.)</li>
          <li>buy currency, i.e. the currency you are buying using the currency you already have</li>
          <li>sell currency, i.e. the currency you are selling in exchange for the currency you will be receiving or buying</li>
          <li>customer type (new, existing, or not specified)</li>
        </ul>
        <p>
        For the different amounts specified, please add the fees charged by the exchange to deposit or withdraw fiat.</p>
 
        <p>If this is a fixed fee, you need to select “Fixed amount” in Fee Type.<br/>
If this is a percentage fee, you need to select “Percentage” in Fee Type. You can then enter the % fee, as well as the minimum and maximum fee if there is one.
 <br/>
If the exchange charges a percentage fee AND a fixed fee, you can also add both by clicking on “+ Add additional fee”.
</p>
        
        <br/>
        <p><b>Last but not least, please click on “Submit” and we will receive the data!</b></p>
      </Message.Content>
    </Message>
  );
}
