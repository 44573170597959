import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import FeeCorridorConfigurationForm from './FeeCorridorConfigurationForm';
import {
  FeeCorridorConfiguration,
  StateFeeCorridorConfiguration,
  validateFeeCorridorConfiguration,
} from '../../../types/models/FeeCorridorConfiguration';
import {
  createFeeCorridor,
  FeeCorridor,
} from '../../../types/models/FeeCorridor';
import { TransferDirection } from '../../../types/enums/TransferDirection';

interface FeeCorridorCloneFormProps {
  corridor: FeeCorridor;
  existsConfigurations?: FeeCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (corridor: FeeCorridor) => void;
}

export default function FeeCorridorCloneForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: FeeCorridorCloneFormProps): JSX.Element {
  // eslint-disable-next-line max-len
  const [state, setState] = useState<StateFeeCorridorConfiguration>(
    corridor as StateFeeCorridorConfiguration,
  );
  const validationErrors = useMemo(
    () => validateFeeCorridorConfiguration(state, existsConfigurations),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    onSubmit(createFeeCorridor(state as FeeCorridorConfiguration));
    onClose();
  }, [onSubmit, onClose, state]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Clone Table" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p style={{ marginBottom: '2em', fontStyle: 'italic' }}>
                You will need to select different settings to clone a table
              </p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <FeeCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                data-qa="clone-table"
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
              >
                Clone Table
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
