import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { TransferDirection } from '../../types/enums/TransferDirection';
import { CREATE_FORM, GET_FORM, UPDATE_FORM } from './gql';

export default function useCryptoForm(formId, initState = {}) {
  const [form, setForm] = useState({
    slug: '',
    countryId: null,
    collectionId: null,
    organizationId: null,
    researcherName: '',
    clientType: '',
    transferDirection: TransferDirection.SENDING,
    amounts: [],
    ...initState,
  });
  const [fetchForm, { loading, error }] = useLazyQuery(GET_FORM, {
    onCompleted: (data) =>
      setForm({
        ...data.form,
        amounts: (data.form?.amounts || []).map(String),
      }),
  });
  const [createForm] = useMutation(CREATE_FORM, {
    onError: () => undefined,
  });
  const [updateForm] = useMutation(UPDATE_FORM, {
    onError: () => undefined,
  });

  const saveForm = useCallback(
    (options) => {
      const isNew = !isFinite(form.id);
      return isNew ? createForm(options) : updateForm(options);
    },
    [form.id, createForm, updateForm],
  );

  const changeSlug = useCallback(() => {
    setForm((prev) => ({ ...prev, slug: uuidv4() }));
  }, []);

  useEffect(() => {
    if (isFinite(formId)) {
      fetchForm({ variables: { formId: parseInt(formId) } });
    } else {
      // generate slug for new form
      changeSlug();
    }
  }, [formId, fetchForm, changeSlug]);

  return {
    form,
    loading,
    error,
    setForm,
    createForm,
    updateForm,
    saveForm,
    changeSlug,
  };
}
