/* eslint-disable max-len */
import { Message } from 'semantic-ui-react';
import React from 'react';

export default function FormFeeInstructions(): JSX.Element {
  return (
    <Message>
      <Message.Header>
        First, you need to enter the following information into the form:
      </Message.Header>
      <Message.List>
        <Message.Item>
          Account type (standard consumer account, premier consumer account,
          standard business account, etc.)
        </Message.Item>
        <Message.Item>Account name (e.g. HSBC Advanced)</Message.Item>
        <Message.Item>
          Location (enter the city you are currently in, not the location of the
          bank you are collecting data for)
        </Message.Item>
        <Message.Item>
          Collection type (phone, online, or in-person). If you have collected
          the fee information online, please provide the URL
        </Message.Item>
      </Message.List>
      <br />
      <Message.Content>
        <p>
          You can then enter the foreign exchange fees for the different
          currency pairs and amounts indicated by your bank. Please note that
          banks have a sending limit for wire transfers. Please enter the
          sending limit (i.e. the maximum amount you can send abroad in a single
          transaction) in the ‘Amount To’ field.
        </p>
        <p>
          As the forms can be quite wide, please make sure to scroll to the
          right to enter all amounts and the 'Collected At' time. The collection
          time for each currency pair needs to be filled out by entering the
          date and local time. Please note that you need to enter the time of
          where you physically are at the time of collection, and not the time
          of the location of your bank. Please also try to be as accufee as
          possible regarding the time (up to the minute).
        </p>
        <p>There are two types of fees:</p>
        <Message.List>
          <Message.Item>
            The fee can be a fixed amount (e.g. a £4 fee to send money from the
            UK to the US). In that case, you need to select ‘Fixed Amount’ in
            the ‘Fee Type’ entry. In some cases, the fixed fee is valid for a
            certain range (e.g. a £4 fee for transfers up to 1000, and a £0 fee
            for transfer from 1000.01 to 25000). In this case, you need to enter
            the $4 fixed fee with 0 in ‘Amount From’ and 1000 in ‘Amount To’.
            And you then need to click on ‘Add Fee Record’ to add the £0 fee
            with 1000.01 in ‘Amount From’ and 25000 in ‘Amount To’.
          </Message.Item>
          <Message.Item>
            The fee can be a percentage (i.e. a 0.125% fee to send money from
            Singapore to China). In that case, you need to select ‘Percentage’
            in the ‘Fee Type’ entry.
          </Message.Item>
        </Message.List>
        <p>
          Sometimes, two types of fees can be charged for the same corridor.
          There can be, for example a percentage fee of 0.125% and an additional
          fixed fee of SGD20. In that case, you need to click on “Add additional
          fee” to be able to add the second fee of SGD20.
        </p>
        <p>Then click Submit and we will receive the data!</p>
      </Message.Content>
    </Message>
  );
}
