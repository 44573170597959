import React from 'react';
import MoneyInput from '../../../components/MoneyInput';
import CurrencySelect from '../../../components/CurrencySelect';
import { FeeFixedValue } from '../../../types/models/FeeFixedValue';
import { ValidationErrors } from '../../../types/models/ValidationErrors';

interface FeeFixedValueItemProps {
  value: FeeFixedValue;
  onChange: (event: any, inputProps: any) => void;
  readOnly?: boolean;
  validationErrors?: ValidationErrors;
}

export default function FeeFixedValueItem({
  value,
  onChange,
  readOnly = false,
  validationErrors = {},
}: FeeFixedValueItemProps) {
  return (
    <div className="crypto_form_collect__fee_value_input">
      <MoneyInput
        className="crypto_form_collect__fee_value_amount"
        label="Value"
        name="value"
        value={value.value}
        onChange={onChange}
        readOnly={readOnly}
        placeholder="0"
        error={validationErrors.value}
      />
      <CurrencySelect
        className="crypto_form_collect__fee_currency"
        placeholder=""
        label="CCY"
        name="currencyId"
        value={value.currencyId}
        onChange={onChange}
        readOnly={readOnly}
        error={validationErrors.currencyId}
      />
    </div>
  );
}
