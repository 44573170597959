import React from 'react';
import './Layout.scss';
import { Container } from 'semantic-ui-react';
import TopMenu from './TopMenu';

const { PUBLIC_URL } = process.env;

function Layout(props) {
  const { children, user, logout } = props;

  return (
    <div className="layout">
      <div className="main-header">
        <div className="header-container">
          <div className="header-left-group">
            <img
              alt="logo"
              className="logo"
              src={`${PUBLIC_URL}/images/fxc_logo.svg`}
            />
          </div>
          <div className="header-right-group">
            {user && <TopMenu user={user} logout={logout} />}
          </div>
        </div>
      </div>
      <div className="content">
        <Container>{children}</Container>
      </div>
    </div>
  );
}

export default Layout;
