import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import errorHandlerSlice from './errorHandler/slice';
import authSlice from './auth/slice';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    [errorHandlerSlice.name]: errorHandlerSlice.reducer,
    [authSlice.name]: authSlice.reducer,
  });
