import isEmpty from 'lodash/isEmpty';
import { FeeValue, validateFeeValue } from './FeeValue';
import { ValidationErrors } from './ValidationErrors';
import { toNumber } from '../../utils/number';

export interface FeeRange {
  id: number;
  amountFrom: string;
  amountTo: string;
  values: FeeValue[];
  editedAt: Date;
  collectedAtLocal: Date;
}

let ID_SEQUENCE = 0;
export function createFeeRange({
  amountFrom = '',
  amountTo = '',
  values = [],
  editedAt = new Date(),
  collectedAtLocal = new Date(),
}: Partial<FeeRange> = {}): FeeRange {
  ID_SEQUENCE -= 1;
  return {
    amountFrom,
    amountTo,
    values,
    editedAt,
    collectedAtLocal,
    id: ID_SEQUENCE,
  };
}

export function validateFeeRange(
  range: FeeRange,
  amountCurrencyId?: number,
): ValidationErrors {
  const amountFrom = toNumber(range.amountFrom, 0);
  const amountTo = toNumber(range.amountTo, Infinity);
  const errors: ValidationErrors = {};
  if (!range.collectedAtLocal) {
    errors.collectedAtLocal = 'Please fill in this field';
  }
  if (amountFrom < 0) {
    errors.amountFrom = 'The value must be greater than or equal to zero';
  }
  if (amountTo <= 0) {
    errors.amountTo = 'The value must be greater than zero';
  }
  if (amountFrom >= amountTo) {
    errors.amountTo = 'The value must be greater than Amount From';
  }

  const valuesErrors: ValidationErrors = {};
  const valuesCanHaveZeroValue = range.values.length === 1;
  range.values.forEach((value, index) => {
    const err = validateFeeValue(
      value,
      valuesCanHaveZeroValue,
      amountCurrencyId,
      amountTo,
    );
    if (!isEmpty(err)) {
      valuesErrors[index] = err;
    }
  });
  if (!isEmpty(valuesErrors)) {
    errors.valuesErrors = valuesErrors;
  }

  return errors;
}
