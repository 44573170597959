import React from 'react';
import { Button, Form, Grid, Label, Radio, Table } from 'semantic-ui-react';
import find from 'lodash/find';
import classNames from 'classnames';
import reject from 'lodash/reject';
import addToDate from 'date-fns/add';
import DateTimePicker from '../../../components/DateTimePicker';
import PROVIDED_RATE from '../../../constants/providedRate';
import ProvidedRatePrompt from './ProvidedRatePrompt';
import AddAmountModal from './AddAmountModal';
import DecimalInput from '../../../components/DecimalInput';

export default class RatesTable extends React.PureComponent {
  static defaultProps = {
    editable: true,
  };

  state = {
    showProvidedRatePrompt: false,
    showAddAmount: false,
    focusedRate: null,
    collectedTime: {},
  };

  render() {
    const { form, submission, editable } = this.props;
    const { showProvidedRatePrompt, showAddAmount } = this.state;
    const currencyFromId = form.currencyFrom.id;
    const currencyFromCode = form.currencyFrom.iso3Code;
    const minDate = Date.parse(form.collection.startAt);
    const maxDate = Math.min(Date.parse(form.collection.endAt), Date.now());
    return (
      <>
        <Grid>
          <Grid.Column floated="left" width={5}>
            <Form.Field label="This bank provides" />
            <Form.Field>
              <Radio
                label="Rate"
                data-qa="rate"
                name="providedRate"
                value={PROVIDED_RATE.DIRECT}
                checked={submission.providedRate === PROVIDED_RATE.DIRECT}
                onChange={this.onChangeMode}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                data-qa="received-amount"
                label="Received Amount"
                name="providedRate"
                value={PROVIDED_RATE.FROM_RECEIVED_AMOUNT}
                checked={
                  submission.providedRate === PROVIDED_RATE.FROM_RECEIVED_AMOUNT
                }
                onChange={this.onChangeMode}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column
            floated="right"
            width={5}
            textAlign="right"
            verticalAlign="bottom"
          >
            <Form.Field>
              <label>No specific amount?</label>
              <Button
                onClick={() => this.setState({ showAddAmount: true })}
                disabled={!editable}
                type="button"
                data-qa="add-amount"
              >
                Add Amount
              </Button>
            </Form.Field>
          </Grid.Column>
        </Grid>
        <div className="form_collect__table_container">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{currencyFromCode} to</Table.HeaderCell>
                {form.amounts.map((amount) => (
                  <Table.HeaderCell data-qa="amount" key={amount}>
                    {this.formatAmount(amount)}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell className="form_collect__collected_at_header">
                  Collected At (location time)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {form.currenciesTo.map(
                ({ id: currencyToId, iso3Code: currencyToCode }) => (
                  <Table.Row key={currencyToId}>
                    <Table.Cell data-qa={currencyToCode}>
                      <Label>{currencyToCode}</Label>
                    </Table.Cell>
                    {form.amounts.map((amount) => (
                      <Table.Cell
                        key={amount}
                        className={classNames({
                          form_collect__amount_cell: true,
                          'form_collect__cell-submitted': this.hasRateSubmitted(
                            currencyFromId,
                            currencyToId,
                            amount,
                          ),
                          'form_collect__cell-cant_be_submit':
                            !this.canRateBeSubmit(
                              currencyFromId,
                              currencyToId,
                              amount,
                            ),
                        })}
                      >
                        <Form.Field>
                          <DecimalInput
                            data-qa={currencyToCode}
                            data-qa-amount={amount}
                            value={this.getRate(
                              currencyFromId,
                              currencyToId,
                              amount,
                            )}
                            onChange={(e, { value }) =>
                              this.setRate(
                                currencyFromId,
                                currencyToId,
                                amount,
                                value,
                              )
                            }
                            onFocus={(e) =>
                              this.onFocusedRate(
                                currencyFromId,
                                currencyToId,
                                amount,
                              )
                            }
                            onBlur={(e) =>
                              this.onUnfocusedRate(
                                currencyFromId,
                                currencyToId,
                                amount,
                              )
                            }
                            readOnly={!editable}
                          />
                          {this.renderRateWarning(
                            currencyFromId,
                            currencyToId,
                            amount,
                          )}
                        </Form.Field>
                      </Table.Cell>
                    ))}
                    <Table.Cell
                      className={classNames({
                        form_collect__collected_at_cell: true,
                        'form_collect__cell-submitted':
                          this.hasCollectedTimeSubmitted(
                            currencyFromId,
                            currencyToId,
                          ),
                      })}
                    >
                      <DateTimePicker
                        data-qa={currencyToCode}
                        value={this.getCollectedTime(
                          currencyFromId,
                          currencyToId,
                        )}
                        onChange={(time) =>
                          this.setCollectedTime(
                            currencyFromId,
                            currencyToId,
                            time,
                          )
                        }
                        error={this.getCollectedTimeErrorMessage(
                          currencyFromId,
                          currencyToId,
                        )}
                        minDate={minDate}
                        maxDate={maxDate}
                        readOnly={!editable}
                        popperPlacement="top"
                      />
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </div>
        <p>
          <span
            className="form_collect__rect form_collect__rect-not_submitted"
            data-qa="not-saved"
          />{' '}
          Not Saved
        </p>
        <p>
          <span
            className="form_collect__rect form_collect__rect-cant_be_submit"
            data-qa="can-not-be-saved"
          />{' '}
          Can not be Saved
        </p>
        <p>
          <span
            className="form_collect__rect form_collect__rect-submitted"
            data-qa="saved"
          />{' '}
          Saved
        </p>
        <ProvidedRatePrompt
          name="providedRate"
          open={showProvidedRatePrompt}
          onSelect={this.onChangeMode}
          onClose={() => this.setState({ showProvidedRatePrompt: false })}
        />
        <AddAmountModal
          open={showAddAmount}
          onSubmit={this.onAddAmount}
          onClose={() => this.setState({ showAddAmount: false })}
        />
      </>
    );
  }

  onChangeMode = (e, { name, value }) => {
    this.props.setSubmission((prev) => ({ ...prev, [name]: value }));
  };

  onAddAmount = (e, { amount }) => {
    this.props.addAmount(parseFloat(amount));
  };

  formatAmount(string) {
    return Number(string).toLocaleString();
  }

  renderRateWarning(currencyFromId, currencyToId, amount) {
    if (this.rateIsFocused(currencyFromId, currencyToId, amount)) {
      return;
    }
    const warning = this.getRateWarning(currencyFromId, currencyToId, amount);
    if (!warning) {
      return;
    }
    return (
      <Label data-qa="warning" pointing>
        {warning}
      </Label>
    );
  }

  hasRateSubmitted(currencyFromId, currencyToId, amount) {
    const { form, rates } = this.props;
    if (find(rates, { currencyFromId, currencyToId, amount })) {
      return false;
    }
    return !!find(form.rates, { currencyFromId, currencyToId, amount });
  }

  canRateBeSubmit(currencyFromId, currencyToId, amount) {
    if (this.getRate(currencyFromId, currencyToId, amount) === '') {
      return true;
    }
    return !!this.getCollectedTime(currencyFromId, currencyToId);
  }

  getRateWarning(currencyFromId, currencyToId, amount) {
    if (!this.isRateMode()) return '';
    const rate = String(
      this.getRate(currencyFromId, currencyToId, amount) || '',
    );
    const numbers = rate.replace('.', '');
    if (numbers.length > 0 && numbers.length < 5) {
      return '5 digits are required';
    }
    return '';
  }

  onFocusedRate(currencyFromId, currencyToId, amount) {
    if (!this.props.editable) return;
    if (this.props.submission.providedRate === '') {
      this.setState({ showProvidedRatePrompt: true });
      return;
    }
    this.setState({
      focusedRate: [currencyFromId, currencyToId, amount].join('-'),
    });
  }

  onUnfocusedRate() {
    this.setState({ focusedRate: null });
  }

  rateIsFocused(currencyFromId, currencyToId, amount) {
    return (
      this.state.focusedRate ===
      [currencyFromId, currencyToId, amount].join('-')
    );
  }

  isRateMode() {
    return this.props.submission.providedRate === PROVIDED_RATE.DIRECT;
  }

  getRate(currencyFromId, currencyToId, amount) {
    const { form, rates } = this.props;
    let rateRecord = find(rates, { currencyFromId, currencyToId, amount });
    if (rateRecord)
      return (
        (this.isRateMode() ? rateRecord.rate : rateRecord.receivedAmount) || ''
      );
    rateRecord = find(form.rates, { currencyFromId, currencyToId, amount });
    if (rateRecord)
      return (
        (this.isRateMode() ? rateRecord.rate : rateRecord.receivedAmount) || ''
      );
    return '';
  }

  setRate(currencyFromId, currencyToId, amount, value) {
    const { setRates } = this.props;
    const time = this.getCollectedTime(currencyFromId, currencyToId);
    const now = new Date().toISOString();
    const newRecord = {
      currencyFromId,
      currencyToId,
      amount,
      editedAt: now,
      collectedAtLocal: time,
    };
    const filteredValue = value.replace(/[^0-9.]/g, '');
    if (this.isRateMode()) {
      newRecord.rate = filteredValue;
      newRecord.receivedAmount = null;
    } else {
      newRecord.receivedAmount = filteredValue;
      newRecord.rate = null;
    }
    setRates((prev) =>
      reject(prev, { currencyFromId, currencyToId, amount }).concat(
        value ? [newRecord] : [],
      ),
    );
  }

  hasCollectedTimeSubmitted(currencyFromId, currencyToId) {
    const { form } = this.props;
    const timeRecord =
      this.state.collectedTime[[currencyFromId, currencyToId].join('-')];
    if (timeRecord) {
      return timeRecord;
    }
    return !!find(form.rates, { currencyFromId, currencyToId });
  }

  getCollectedTime(currencyFromId, currencyToId) {
    const { form } = this.props;
    const timeRecord =
      this.state.collectedTime[[currencyFromId, currencyToId].join('-')];
    if (timeRecord) {
      return timeRecord;
    }
    const rateRecord = find(form.rates, { currencyFromId, currencyToId });
    if (rateRecord && rateRecord.collectedAtLocal) {
      // display time as it is in the location
      return addToDate(new Date(rateRecord.collectedAtLocal), {
        minutes: new Date().getTimezoneOffset(),
      });
    }
    return '';
  }

  setCollectedTime(currencyFromId, currencyToId, time) {
    const { setRates } = this.props;
    const key = [currencyFromId, currencyToId].join('-');
    const { date } = time;
    this.setState((prev) => ({
      ...prev,
      collectedTime: {
        ...prev.collectedTime,
        [key]: date,
      },
    }));
    setRates((prev) =>
      prev.map((rate) => {
        if (
          rate.currencyFromId === currencyFromId &&
          rate.currencyToId === currencyToId
        ) {
          return {
            ...rate,
            collectedAtLocal: date,
          };
        }
        return rate;
      }),
    );
  }

  getCollectedTimeErrorMessage(currencyFromId, currencyToId) {
    const timeIsSet = this.getCollectedTime(currencyFromId, currencyToId);
    if (timeIsSet) {
      return null;
    }
    for (const amount of this.props.form.amounts) {
      if (this.getRate(currencyFromId, currencyToId, amount)) {
        return 'Enter when rates are collected';
      }
    }
    return null;
  }
}
