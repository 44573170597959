import { gql } from '@apollo/client';
import { CryptoForm } from '../../types/models/CryptoForm';
import { TransferDirection } from '../../types/enums/TransferDirection';

export interface CollectCryptoFormFields extends CryptoForm {
  id: number;
  slug: string;
  researcherName: string;
  clientType: string;
  collection: {
    startAt: Date;
    endAt: Date;
  };
  organization: {
    name: string;
  };
  transferDirection: keyof typeof TransferDirection;
  countryId: number;
  country: {
    name: string;
    defaultCurrencyId: number;
  };
}

const FIELDS = gql`
  fragment CollectCryptoFormFields on CryptoForm {
    id
    slug
    researcherName
    clientType
    collection {
      startAt
      endAt
    }
    organization {
      name
    }
    countryId
    country {
      name
      defaultCurrencyId
    }
    transferDirection
    submittedAt
    savedAt
    amounts
    submission {
      accountType
      accountName
      collectionType
      locationAddress
      onlineUrls
    }
    buySell {
      id
      countryId
      currencyFromIds
      currencyToIds
      paymentMethods
      payoutMethods
      paymentDetails
      payoutDetails
      customerType
      typeRates
      typeAmounts
      transactionType
      collectedAtLocal
      ranges {
        id
        amountFrom
        amountTo
        rate
        editedAt
        fees(orderBy: { priority: asc }) {
          id
          priority
          type
          value
          currencyId
          minValue
          maxValue
        }
      }
    }
    inOut {
      id
      countryId
      currencyIds
      paymentMethods
      payoutMethods
      paymentDetails
      payoutDetails
      customerType
      typeAmounts
      direction
      collectedAtLocal
      ranges {
        id
        amountFrom
        amountTo
        editedAt
        fees(orderBy: { priority: asc }) {
          id
          priority
          type
          value
          currencyId
          minValue
          maxValue
        }
      }
    }
    images
  }
`;

export const GET_FORM = gql`
  query GetCryptoFormForCollect($formSlug: String!) {
    form: cryptoForm(where: { slug: $formSlug }) {
      ...CollectCryptoFormFields
    }
  }
  ${FIELDS}
`;

export const SUBMIT_FORM = gql`
  mutation SubmitCryptoForm($formSlug: String!) {
    form: submitCryptoForm(where: { slug: $formSlug }) {
      ...CollectCryptoFormFields
    }
  }
  ${FIELDS}
`;

export const SAVE_FORM = gql`
  mutation SaveCryptoForm(
    $formSlug: String!
    $buySell: CryptoBuySellCorridorUpdateManyWithoutFormInput
    $inOut: CryptoInOutCorridorUpdateManyWithoutFormInput
    $submission: CryptoFormSubmissionUpdateOneWithoutFormInput
  ) {
    form: saveCryptoForm(
      where: { slug: $formSlug }
      data: { submission: $submission, buySell: $buySell, inOut: $inOut }
    ) {
      ...CollectCryptoFormFields
    }
  }
  ${FIELDS}
`;

export interface UploadCryptoFormFields {
  images: CryptoForm['images'];
}

export const UPLOAD_IMAGES = gql`
  mutation SaveForm($formSlug: String!, $images: [Upload!]) {
    form: saveCryptoForm(
      where: { slug: $formSlug }
      data: { images: $images }
    ) {
      images
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation SaveForm($formSlug: String!, $image: String) {
    form: saveCryptoForm(
      where: { slug: $formSlug }
      data: { imageDelete: $image }
    ) {
      images
    }
  }
`
