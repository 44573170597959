import React, { useCallback, useMemo } from 'react';
import { Grid, Header, Pagination, Button, Icon } from 'semantic-ui-react';

import { Link, useHistory } from 'react-router-dom';
import formatDate from 'date-fns/format';
import { useQuery, useMutation, gql } from '@apollo/client';
import Loader from '../../components/Loader';
import SortableTable from '../../components/SortableTable';
import QueryComposer from '../../components/QueryComposer';
import HeaderButton from '../../components/HeaderButton';
import SearchInput from '../../components/SearchInput';
import { dateTimeFormat } from '../../constants/dateTimeFormat';
import './CollectionList.scss';

const COLUMNS = {
  ID: 'ID',
  NAME: 'NAME',
  START_AT: 'START_AT',
  END_AT: 'END_AT',
  TOTAL_FORMS: 'TOTAL_FORMS',
  SUBMITTED_FORMS: 'SUBMITTED_FORMS',
  ACTIONS: 'ACTIONS',
};

const columnsConfig = {
  keyAttribute: 'id',
  defaultText: '-',
  columns: [
    COLUMNS.ID,
    COLUMNS.NAME,
    COLUMNS.START_AT,
    COLUMNS.END_AT,
    COLUMNS.SUBMITTED_FORMS,
    COLUMNS.TOTAL_FORMS,
    COLUMNS.ACTIONS,
  ],
  config: {
    [COLUMNS.ID]: {
      label: 'Id',
      sortable: true,
      dataAttribute: 'id',
    },
    [COLUMNS.NAME]: {
      label: 'Name',
      sortable: true,
      dataAttribute: 'name',
      customRender: true,
    },
    [COLUMNS.START_AT]: {
      label: 'Start At (your local time)',
      sortable: true,
      dataAttribute: 'startAt',
      customRender: true,
    },
    [COLUMNS.END_AT]: {
      label: 'End At (your local time)',
      sortable: true,
      dataAttribute: 'endAt',
      customRender: true,
    },
    [COLUMNS.SUBMITTED_FORMS]: {
      label: 'Submitted forms',
      dataAttribute: 'countSubmittedForms',
    },
    [COLUMNS.TOTAL_FORMS]: {
      label: 'Total forms',
      dataAttribute: 'countForms',
    },
    [COLUMNS.ACTIONS]: {
      label: 'Actions',
      customRender: true,
    },
  },
};

function renderNameColumn(column, item) {
  const { config } = columnsConfig;
  const idAttribute = config[COLUMNS.ID].dataAttribute;
  const currentAttribute = config[column].dataAttribute;

  return (
    <Link
      to={`/collections/${item[idAttribute]}`}
      data-qa="collection-name"
      data-qa-link-text={item[currentAttribute]}
    >
      {item[currentAttribute] || 'No Name'}
    </Link>
  );
}

function renderDateColumn(column, item) {
  const { dataAttribute } = columnsConfig.config[column];
  return formatDate(new Date(item[dataAttribute]), dateTimeFormat);
}

function renderActionsColumn(column, item) {
  const { keyAttribute } = columnsConfig;
  const id = item[keyAttribute];
  const deletable = item.countSubmittedForms === 0;
  return <Actions id={id} deletable={deletable} />;
}

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: Int) {
    deleted: deleteCollection(where: { id: $id }) {
      id
    }
  }
`;

function Actions(props) {
  const { id, deletable } = props;
  const history = useHistory();
  const [deleteCollection] = useMutation(DELETE_COLLECTION, {
    update(cache, { data: mutationData }) {
      if (mutationData) {
        const idToRemove = mutationData.deleted.id;
        cache.modify({
          fields: {
            collections(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) => idToRemove !== readField('id', ref),
              );
            },
          },
        });
      }
    },
  });

  const onCloneCollection = useCallback(() => {
    history.push(`/collections/${id}/clone`);
  }, [id]);

  const onDeleteCollection = useCallback(() => {
    if (!window.confirm('Do you want to remove this collection?')) {
      return;
    }
    deleteCollection({
      variables: { id },
    });
  }, [id]);

  return (
    <>
      <Button
        data-qa="clone"
        icon
        onClick={onCloneCollection}
        title="Clone collection with forms"
      >
        <Icon name="clone" />
      </Button>
      {deletable && (
        <Button
          data-qa="remove"
          icon
          onClick={onDeleteCollection}
          title="Delete collection"
        >
          <Icon name="trash" />
        </Button>
      )}
    </>
  );
}

function renderItem(column, item) {
  switch (column) {
    case COLUMNS.NAME:
      return renderNameColumn(column, item);
    case COLUMNS.END_AT:
    case COLUMNS.START_AT:
      return renderDateColumn(column, item);
    case COLUMNS.ACTIONS:
      return renderActionsColumn(column, item);
    default:
      return null;
  }
}

function getHeaderCellClassName(column) {
  switch (column) {
    case COLUMNS.ID:
      return 'collections_list__header_cell_id';
    case COLUMNS.ACTIONS:
      return 'collections_list__header_cell_actions';
    default:
      return '';
  }
}

const GET_COLLECTION_LIST = gql`
  query GetCollectionList(
    $offset: Int
    $perPage: Int
    $orderBy: CollectionOrderByWithRelationInput!
    $search: String = ""
  ) {
    collections(
      skip: $offset
      take: $perPage
      where: { name: { contains: $search, mode: insensitive } }
      orderBy: [$orderBy]
    ) {
      id
      name
      startAt
      endAt
      countForms
      countSubmittedForms
    }
    total: aggregateCollection(
      where: { name: { contains: $search, mode: insensitive } }
    ) {
      _count {
        _all
      }
    }
  }
`;

function CollectionsTable(props) {
  const page = props.page || 1;
  const perPage = props.perPage || 10;
  const offset = (page - 1) * perPage;
  const search = props.search || '';
  const { sortColumn, sortOrder } = props;
  const orderBy = useMemo(() => {
    if (!sortColumn || !sortOrder) {
      return { id: 'desc' };
    }
    const { config } = columnsConfig;
    const attribute = config[sortColumn].dataAttribute || 'id';
    let order = sortOrder.toLowerCase();
    if (!['asc', 'desc'].includes(order)) {
      order = 'asc';
    }
    return {
      [attribute]: order,
    };
  }, [sortColumn, sortOrder]);

  const { loading, error, data } = useQuery(GET_COLLECTION_LIST, {
    variables: {
      offset,
      perPage,
      search,
      orderBy,
    },
  });

  if (loading) return <Loader isLoading />;
  if (error) return `Error! ${error.message}`;

  const pages = Math.ceil(data.total._count._all / perPage);

  return (
    <>
      <SortableTable
        items={data.collections}
        columnsConfig={columnsConfig}
        renderItem={renderItem}
        setHeaderCellClassName={getHeaderCellClassName}
        isExternalSort
        onSortChange={props.onSortChange}
        sortColumn={props.sortColumn}
        sortOrder={props.sortOrder}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Pagination
              activePage={page}
              totalPages={pages || 1}
              onPageChange={(_, data) => props.setPage(data.activePage)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

function CollectionList(props) {
  return (
    <div>
      <QueryComposer>
        {(query, setQuery) => (
          <>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column className="flex align-items-center">
                  <Header size="large">Collections List</Header>
                </Grid.Column>
                <Grid.Column className="flex justify-content-end align-items-center spaced-items">
                  <SearchInput
                    onChange={(search) =>
                      setQuery({
                        search: search.length ? search : null,
                        page: null,
                      })
                    }
                    isSyncToDefault
                    defaultValue={query.search}
                    placeholder="Name..."
                  />
                  <HeaderButton
                    as={Link}
                    to="/collections/new"
                    data-qa="create-collection"
                  >
                    Create Collection
                  </HeaderButton>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <CollectionsTable
              search={query.search}
              page={query.page || 1}
              perPage={10}
              setPage={(page) => setQuery({ page })}
              onSortChange={({ sortColumn, sortOrder }) =>
                setQuery({
                  sortBy: sortColumn,
                  sortOrder,
                })
              }
              sortColumn={query.sortBy}
              sortOrder={query.sortOrder}
            />
          </>
        )}
      </QueryComposer>
    </div>
  );
}

export default CollectionList;
