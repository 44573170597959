import React, { useCallback, useMemo, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import CryptoInOutCorridorCreateForm from './CryptoInOutCorridorCreateForm';
import {
  createCryptoInOutCorridor,
  getDefaultFeeCurrencyId,
  CryptoInOutCorridor,
} from '../../../../types/models/CryptoInOutCorridor';
import CryptoInOutCorridorView from './CryptoInOutCorridorView';
import { CryptoInOutCorridorConfiguration } from '../../../../types/models/CryptoInOutCorridorConfiguration';
import { createCryptoInOutRange } from '../../../../types/models/CryptoInOutRange';
import CryptoInOutCorridorEditForm from './CryptoInOutCorridorEditForm';
import CryptoInOutCorridorCloneForm from './CryptoInOutCorridorCloneForm';
import { createFeeValue } from '../../../../types/models/FeeValue';
import useModalState from '../../../../hooks/useModalState';
import { UseCryptoFormCollect } from '../../hooks/useCryptoFormCollect';

export default function CryptoInOutCorridors({
  formData,
}: {
  formData: UseCryptoFormCollect;
}): JSX.Element {
  const {
    state: {
      isSubmitted,
      validationErrors,
      form: { inOut, countryId, country, amounts, ...form },
    },
    actions: {
      addInOutCorridor,
      updateInOutCorridor,
      deleteInOutCorridor,
      copyInOutCorridor,
    },
  } = formData;
  const readOnly = isSubmitted;
  const sortedAmounts = [...(amounts || [])].sort((a, b) => a - b);

  const [
    createCorridorFormDisplayed,
    showCreateCorridorForm,
    hideCreateCorridorForm,
  ] = useModalState();
  const onCreateCorridor = useCallback(
    (configuration: CryptoInOutCorridorConfiguration) => {
      addInOutCorridor(
        createCryptoInOutCorridor({
          ...configuration,
          ranges: sortedAmounts?.length
            ? [
                ...sortedAmounts.map((amount, i) =>
                  createCryptoInOutRange({
                    amountFrom:
                      i > 0 ? sortedAmounts[i - 1].toString() : undefined,
                    amountTo: (amount - 0.01).toString(),
                    fees: [
                      createFeeValue({
                        currencyId: getDefaultFeeCurrencyId(configuration),
                      }),
                    ],
                  }),
                ),
                createCryptoInOutRange({
                  amountFrom:
                    sortedAmounts[sortedAmounts.length - 1].toString(),
                  fees: [
                    createFeeValue({
                      currencyId: getDefaultFeeCurrencyId(configuration),
                    }),
                  ],
                }),
              ]
            : [
                createCryptoInOutRange({
                  fees: [
                    createFeeValue({
                      currencyId: getDefaultFeeCurrencyId(configuration),
                    }),
                  ],
                }),
              ],
        }),
      );
      hideCreateCorridorForm();
    },
    [addInOutCorridor, hideCreateCorridorForm, amounts],
  );

  const [editingCorridor, setEditingCorridor] = useState<
    CryptoInOutCorridor | undefined
  >();
  const onEditCorridor = useCallback(
    (corridor: CryptoInOutCorridor) => {
      setEditingCorridor(corridor);
    },
    [setEditingCorridor],
  );

  const [cloningCorridor, setCloningCorridor] = useState<
    CryptoInOutCorridor | undefined
  >();
  const onCopyCorridor = useCallback(
    (corridor: CryptoInOutCorridor) => {
      setCloningCorridor(corridor);
    },
    [setCloningCorridor],
  );

  // we want to keep the order in the same way in which the corridors were created by the user
  const sortedCorridors = useMemo(
    () =>
      inOut.slice().sort((a: CryptoInOutCorridor, b: CryptoInOutCorridor) => {
        return a.id - b.id;
      }),
    [inOut],
  );

  return (
    <>
      <Header as="h2">Deposit / Withdrawal Cost</Header>
      {sortedCorridors.map((corridor: CryptoInOutCorridor) => (
        <CryptoInOutCorridorView
          key={corridor.id}
          corridor={corridor}
          onChange={updateInOutCorridor}
          onEditConfiguration={onEditCorridor}
          onCopy={onCopyCorridor}
          onDelete={deleteInOutCorridor}
          readOnly={readOnly}
          validationErrors={validationErrors.inOut[corridor.id]}
          transferDirection={form.transferDirection}
        />
      ))}
      {!readOnly && inOut.length > 0 && (
        <Button
          data-qa="add-new-table"
          type="button"
          color="green"
          onClick={showCreateCorridorForm}
        >
          Add New Table
        </Button>
      )}
      {inOut.length === 0 && (
        <Segment placeholder textAlign="center">
          <Header icon>
            <Icon name="edit outline" />
            No deposit / withdrawal cost tables
          </Header>
          {!readOnly && (
            <Button
              onClick={showCreateCorridorForm}
              type="button"
              color="green"
              data-qa="add-new-table"
            >
              Add New Table
            </Button>
          )}
        </Segment>
      )}
      {createCorridorFormDisplayed && (
        <CryptoInOutCorridorCreateForm
          existsConfigurations={inOut}
          onClose={hideCreateCorridorForm}
          onSubmit={onCreateCorridor}
          transferDirection={form.transferDirection}
          defaultValues={{
            countryId,
            currencyIds: country?.defaultCurrencyId
              ? [country?.defaultCurrencyId]
              : [],
          }}
        />
      )}
      {editingCorridor && (
        <CryptoInOutCorridorEditForm
          corridor={editingCorridor}
          existsConfigurations={inOut.filter(
            (c: CryptoInOutCorridor) => c.id !== editingCorridor.id,
          )}
          onClose={() => setEditingCorridor(undefined)}
          onSubmit={updateInOutCorridor}
          transferDirection={form.transferDirection}
        />
      )}
      {cloningCorridor && (
        <CryptoInOutCorridorCloneForm
          corridor={cloningCorridor}
          existsConfigurations={inOut}
          onClose={() => setCloningCorridor(undefined)}
          onSubmit={copyInOutCorridor}
          transferDirection={form.transferDirection}
        />
      )}
    </>
  );
}
