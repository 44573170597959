import { gql } from '@apollo/client';
import { FeeForm } from '../../types/models/FeeForm';
import { TransferDirection } from '../../types/enums/TransferDirection';

export interface CollectFormFeeFields extends FeeForm {
  id: number;
  slug: string;
  researcherName: string;
  clientType: string;
  collection: {
    startAt: Date;
    endAt: Date;
  };
  organization: {
    name: string;
  };
  transferDirection: keyof typeof TransferDirection;
  countryId: number;
  country: {
    name: string;
    defaultCurrencyId: number;
  };
}

const FIELDS = gql`
  fragment CollectFormFeeFields on FormFee {
    id
    slug
    researcherName
    clientType
    collection {
      startAt
      endAt
    }
    organization {
      name
    }
    countryId
    country {
      name
      defaultCurrencyId
    }
    transferDirection
    submittedAt
    savedAt
    submission {
      accountType
      accountName
      collectionType
      locationAddress
      onlineUrls
    }
    corridors {
      id
      countryFromId
      countryToIds
      currencyFromId
      currencyToId
      paymentMethods
      payoutMethods
      paymentDetails
      payoutDetails
      transferMethods
      transferSpeed
      transferSpeedDetails
      packageName
      amountCurrencyId
      customerType
      ranges {
        id
        amountFrom
        amountTo
        collectedAtLocal
        editedAt
        values(orderBy: { priority: asc }) {
          id
          priority
          type
          value
          currencyId
          minValue
          maxValue
        }
      }
    }
    images
  }
`;

export const GET_FORM = gql`
  query GetCollectFormFee($formSlug: String!) {
    form: formFee(where: { slug: $formSlug }) {
      ...CollectFormFeeFields
    }
  }
  ${FIELDS}
`;

export const SUBMIT_FORM = gql`
  mutation SubmitFormFee($formSlug: String!) {
    form: submitFormFee(where: { slug: $formSlug }) {
      ...CollectFormFeeFields
    }
  }
  ${FIELDS}
`;

export const SAVE_FORM = gql`
  mutation SaveFormFee(
    $formSlug: String!
    $corridors: FeeCorridorUpdateManyWithoutFormInput
    $submission: SubmissionFeeUpdateOneWithoutFormInput
  ) {
    form: saveFormFee(
      where: { slug: $formSlug }
      data: { submission: $submission, corridors: $corridors }
    ) {
      ...CollectFormFeeFields
    }
  }
  ${FIELDS}
`;

export interface UploadFormFeeFields {
  images: FeeForm['images'];
}

export const UPLOAD_IMAGES = gql`
  mutation SaveFormFee($formSlug: String!, $images: [Upload!]) {
    form: saveFormFee(where: { slug: $formSlug }, data: { images: $images }) {
      images
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation SaveForm($formSlug: String!, $image: String) {
    form: saveFormFee(
      where: { slug: $formSlug }
      data: { imageDelete: $image }
    ) {
      images
    }
  }
`
