import isEmpty from 'lodash/isEmpty';
import { CryptoBuySellCorridorConfiguration } from './CryptoBuySellCorridorConfiguration';
import {
  CryptoBuySellRange,
  validateCryptoBuySellRange,
} from './CryptoBuySellRange';
import { ValidationErrors } from './ValidationErrors';
import { FeeValueType } from '../enums/FeeValueType';
import { AmountType } from '../enums/AmountType';
import { toNumber } from '../../utils/number';
import { TransactionType } from '../enums/TransactionType';

export interface CryptoBuySellCorridor
  extends CryptoBuySellCorridorConfiguration {
  id: number;
  typeRates: keyof typeof FeeValueType;
  typeAmounts: keyof typeof AmountType;
  collectedAtLocal: Date;
  ranges: CryptoBuySellRange[];
}

export interface CreateCryptoBuySellCorridorParams
  extends CryptoBuySellCorridorConfiguration {
  typeRates?: keyof typeof FeeValueType;
  typeAmounts?: keyof typeof AmountType;
  collectedAtLocal?: Date;
  ranges?: CryptoBuySellRange[];
}

let ID_SEQUENCE = 0;
export function createCryptoBuySellCorridor({
  ranges = [],
  collectedAtLocal = new Date(),
  typeRates = FeeValueType.FIXED,
  typeAmounts = AmountType.RANGE,
  ...params
}: CreateCryptoBuySellCorridorParams): CryptoBuySellCorridor {
  ID_SEQUENCE -= 1;
  return {
    ...params,
    typeRates,
    typeAmounts,
    collectedAtLocal,
    ranges,
    id: ID_SEQUENCE,
  };
}

const maxRangeGap = 0.01;

export function validateCryptoBuySellCorridor(
  data: CryptoBuySellCorridor,
): ValidationErrors {
  const errors: ValidationErrors = {};
  if (!data.collectedAtLocal) {
    errors.collectedAtLocal = 'Please fill in this field';
  }
  
  const defaultFeeCurrencyId = getDefaultFeeCurrencyId(data);
  const rangesErrors: ValidationErrors = {};
  const sortedRanges = data.ranges
    .slice()
    .sort((a, b) => Number(a.amountFrom) - Number(b.amountFrom));
  if (data.typeAmounts === AmountType.SINGLE) {
    sortedRanges.forEach((range, index) => {
      const err = validateCryptoBuySellRange(
        range,
        defaultFeeCurrencyId,
        data.typeAmounts,
      );
      const rangeHaveOverlapping = sortedRanges.some(
        (r, i) => i !== index && range.amountFrom == r.amountFrom,
      );
      if (rangeHaveOverlapping) {
        err.overlapping = 'Duplicate amount';
      }
      if (!isEmpty(err)) {
        rangesErrors[index] = err;
      }
    });
  } else if (data.typeAmounts === AmountType.RANGE) {
    sortedRanges.forEach((range, index) => {
      const err = validateCryptoBuySellRange(range, defaultFeeCurrencyId, data.typeAmounts);
      const rangeHaveOverlapping = sortedRanges.some(
        (r, i) => i !== index && haveOverlappingRanges(range, r),
      );
      if (rangeHaveOverlapping) {
        err.overlapping =
          'Amount Ranges cannot intersect - You need to change Amount From or Amount To';
      }
      const nextRange = sortedRanges[index + 1];
      const rangeHaveBigGap =
        nextRange && haveBigGapBetweenRanges(range, nextRange);
      if (rangeHaveBigGap) {
        err.bigGap = `The gap between amount rages must ${maxRangeGap}. For example (Amount From 0, Amount To 100), (Amount From 100.𝟎𝟏, Amount To: 200).`;
      }
      if (!isEmpty(err)) {
        rangesErrors[index] = err;
      }
    });
  }
  if (!isEmpty(rangesErrors)) {
    errors.rangesErrors = rangesErrors;
  }
  return errors;
}

function haveOverlappingRanges(
  a: CryptoBuySellRange,
  b: CryptoBuySellRange,
): boolean {
  const a1 = toNumber(a.amountFrom, 0);
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  const b2 = toNumber(b.amountTo, Infinity);
  if (b1 <= a1 && a1 <= b2) {
    return true;
  }
  if (b1 <= a2 && a2 <= b2) {
    return true;
  }
  return false;
}

function haveBigGapBetweenRanges(a: CryptoBuySellRange, b: CryptoBuySellRange) {
  const a2 = toNumber(a.amountTo, Infinity);
  const b1 = toNumber(b.amountFrom, 0);
  return b1 - a2 > maxRangeGap + maxRangeGap / 10 ** 6;
}

export function getDefaultFeeCurrencyId(corridor: CryptoBuySellCorridorConfiguration): number | undefined {
  if (corridor.transactionType === TransactionType.BUY) {
    return corridor.currencyFromIds.length === 1 ? corridor.currencyFromIds[0] : undefined
  } else if (corridor.transactionType === TransactionType.SELL) {
    return corridor.currencyToIds.length === 1 ? corridor.currencyToIds[0] : undefined
  }
  return undefined;
}