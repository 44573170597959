import { useCallback } from 'react';
import { ListItem, SetState } from '../types/utility';

export default function useCreate<T extends ListItem>(setState: SetState<T>) {
  return useCallback(
    (item: T) => {
      setState((prevState: T[]) => [...prevState, item] as T[]);
    },
    [setState],
  );
}
