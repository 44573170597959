import React, { useCallback } from 'react';
import { Form, Message } from 'semantic-ui-react';
import CountrySelect from '../../../../components/CountrySelect';
import CurrencySelect from '../../../../components/CurrencySelect';
import PaymentMethodSelect from '../../../../components/PaymentMethodSelect';
import PayoutMethodSelect from '../../../../components/PayoutMethodSelect';
import {
  CryptoBuySellCorridorConfiguration,
  StateCryptoBuySellCorridorConfiguration,
} from '../../../../types/models/CryptoBuySellCorridorConfiguration';
import CustomerTypeSelect from '../../../../components/CustomerTypeSelect';
import TransactionTypeSelect from '../../../../components/TransactionTypeSelect';
import { ValidationErrors } from '../../../../types/models/ValidationErrors';
import { TransferDirection } from '../../../../types/enums/TransferDirection';
import { TransactionType } from 'src/types/enums/TransactionType';
import { PaymentMethod } from 'src/types/enums/PaymentMethod';
import { PayoutMethod } from 'src/types/enums/PayoutMethod';

export type ChangedCryptoBuySellCorridorConfiguration =
  Partial<CryptoBuySellCorridorConfiguration>;

export interface CorridorConfigurationProps
  extends StateCryptoBuySellCorridorConfiguration {
  onChange: (changed: ChangedCryptoBuySellCorridorConfiguration) => void;
  validationErrors?: ValidationErrors;
  transferDirection: keyof typeof TransferDirection;
}

const defaultMethods = {
  [TransactionType.BUY]: {
    payment_method: PaymentMethod.BANK_ACCOUNT,
    payout_method: PayoutMethod.PROVIDER_WALLET,
  },
  [TransactionType.SELL]: {
    payment_method: PaymentMethod.PROVIDER_WALLET,
    payout_method: PayoutMethod.PROVIDER_WALLET,
  },
  [TransactionType.EXCHANGE_TRADE]: {
    payment_method: PaymentMethod.PROVIDER_WALLET,
    payout_method: PayoutMethod.PROVIDER_WALLET,
  },
};

export default function CryptoBuySellCorridorConfigurationForm({
  countryId,
  currencyFromIds,
  currencyToIds,
  paymentMethods,
  payoutMethods,
  paymentDetails,
  payoutDetails,
  customerType,
  transactionType,
  onChange,
  transferDirection,
  validationErrors = {},
}: CorridorConfigurationProps): JSX.Element {
  const onChangeField = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed: ChangedCryptoBuySellCorridorConfiguration = {
        [name]: value,
      };
      switch (name) {
        case 'transactionType':
          changed['currencyFromIds'] = [];
          changed['currencyToIds'] = [];
          changed['paymentMethods'] = [
            defaultMethods[value as TransactionType].payment_method,
          ];
          changed['payoutMethods'] = [
            defaultMethods[value as TransactionType].payout_method,
          ];
          changed['paymentDetails'] = '';
          changed['payoutDetails'] = '';
          break;

        case 'countryId':
          changed.currencyFromIds = [inputProps.defaultCurrencyId];
          break;
        case 'currencyFromIds':
        case 'currencyToIds':
          if (!Array.isArray(value)) {
            changed[name as 'currencyFromIds' | 'currencyToIds'] = [value];
          }
          break;

        case 'paymentMethods':
          if (value.length !== 1) {
            changed['paymentDetails'] = '';
          }
          break;

        case 'payoutMethods':
          if (value.length !== 1) {
            changed['payoutDetails'] = '';
          }
          break;
      }
      onChange(changed);
    },
    [onChange],
  );
  return (
    <>
      <Form.Group widths="equal">
        <CountrySelect
          label="Country"
          name="countryId"
          placeholder=""
          value={countryId}
          onChange={onChangeField}
          required
          error={validationErrors.countryId}
        />
        <PaymentMethodSelect
          label="Payment Method"
          name="paymentMethods"
          placeholder=""
          value={paymentMethods}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.paymentMethods}
        />
        <PayoutMethodSelect
          label="Payout Method"
          name="payoutMethods"
          placeholder=""
          value={payoutMethods}
          onChange={onChangeField}
          required
          multiple
          error={validationErrors.payoutMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TransactionTypeSelect
          label="Transaction Type"
          name="transactionType"
          placeholder=""
          value={transactionType || undefined}
          onChange={onChangeField}
          required
          error={validationErrors.transactionType}
        />
        <Form.Input
          label="Payment details"
          name="paymentDetails"
          placeholder="Wire, ACH, etc."
          disabled={paymentMethods.length > 1}
          value={paymentDetails}
          onChange={onChangeField}
        />
        <Form.Input
          label="Payout details"
          name="payoutDetails"
          placeholder="Wire, ACH, etc."
          disabled={payoutMethods.length > 1}
          value={payoutDetails}
          onChange={onChangeField}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <CurrencySelect
            label={
              transferDirection === TransferDirection.SENDING
                ? 'Buy Currency'
                : 'Sell Currency'
            }
            name="currencyFromIds"
            placeholder=""
            value={
              transactionType !== TransactionType.BUY
                ? currencyFromIds
                : currencyFromIds[0]
            }
            onChange={onChangeField}
            required
            multiple={transactionType !== TransactionType.BUY}
            disabled={!countryId}
            error={validationErrors.currencyFromIds}
          />
          <Message info compact>
            The currency the provider is buying from you, or the starting
            currency in the transaction
          </Message>
        </Form.Field>
        <Form.Field>
          <CurrencySelect
            label={
              transferDirection === TransferDirection.SENDING
                ? 'Sell Currency'
                : 'Buy Currency'
            }
            name="currencyToIds"
            placeholder=""
            value={
              transactionType !== TransactionType.SELL
                ? currencyToIds
                : currencyToIds[0]
            }
            onChange={onChangeField}
            required
            multiple={transactionType !== TransactionType.SELL}
            error={validationErrors.currencyToIds}
          />
          <Message info compact>
            The currency the provider is selling to you, or the end currency in
            the transaction
          </Message>
        </Form.Field>
        <CustomerTypeSelect
          label="Customer Type"
          name="customerType"
          placeholder=""
          value={customerType}
          onChange={onChangeField}
          required
          error={validationErrors.customerType}
        />
      </Form.Group>
    </>
  );
}
