import { useMemo, useState } from 'react';

type ModalAction = () => void;

type ModalState = [boolean, ModalAction, ModalAction];

export default function useModalState(initialState = false): ModalState {
  const [isDisplayed, setDisplayed] = useState<boolean>(initialState);
  const [showModal, hideModal] = useMemo<[() => void, () => void]>(
    () => [() => setDisplayed(true), () => setDisplayed(false)],
    [setDisplayed],
  );
  return [isDisplayed, showModal, hideModal];
}
