import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Grid, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import CryptoInOutCorridorConfigurationForm from './CryptoInOutCorridorConfigurationForm';
import {
  CryptoInOutCorridorConfiguration,
  StateCryptoInOutCorridorConfiguration,
  validateCryptoInOutCorridorConfiguration,
} from '../../../../types/models/CryptoInOutCorridorConfiguration';
import {
  createCryptoInOutCorridor,
  CryptoInOutCorridor,
} from '../../../../types/models/CryptoInOutCorridor';
import { TransferDirection } from '../../../../types/enums/TransferDirection';

interface CryptoInOutCorridorCloneFormProps {
  corridor: CryptoInOutCorridor;
  existsConfigurations?: CryptoInOutCorridorConfiguration[];
  transferDirection: keyof typeof TransferDirection;
  onClose: () => void;
  onSubmit: (corridor: CryptoInOutCorridor) => void;
}

export default function CryptoInOutCorridorCloneForm({
  corridor,
  existsConfigurations = [],
  transferDirection,
  onSubmit,
  onClose,
}: CryptoInOutCorridorCloneFormProps): JSX.Element {
  // eslint-disable-next-line max-len
  const [state, setState] = useState<StateCryptoInOutCorridorConfiguration>(
    corridor as StateCryptoInOutCorridorConfiguration,
  );
  const validationErrors = useMemo(
    () => validateCryptoInOutCorridorConfiguration(state, existsConfigurations),
    [state, existsConfigurations],
  );
  const canSubmit = isEmpty(validationErrors);
  const onChange = useCallback(
    (changed) => setState((prev) => ({ ...prev, ...changed })),
    [setState],
  );
  const onClickSubmitButton = useCallback(() => {
    onSubmit(
      createCryptoInOutCorridor(state as CryptoInOutCorridorConfiguration),
    );
    onClose();
  }, [onSubmit, onClose, state]);
  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header content="Clone Table" />
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p style={{ marginBottom: '2em', fontStyle: 'italic' }}>
                You will need to select different settings to clone a table
              </p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <CryptoInOutCorridorConfigurationForm
                  {...state}
                  onChange={onChange}
                  validationErrors={validationErrors}
                  transferDirection={transferDirection}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              {validationErrors.sameConfiguration && (
                <Label basic color="red" style={{ marginRight: '1em' }}>
                  {validationErrors.sameConfiguration}
                </Label>
              )}
              <Button
                data-qa="clone-table"
                type="button"
                color="blue"
                onClick={onClickSubmitButton}
                disabled={!canSubmit}
              >
                Clone Table
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
