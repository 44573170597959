import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorId: 0,
  errorCode: null,
  errorMessage: null,
};

const authSlice = createSlice({
  name: 'errorHandler',
  initialState,
  reducers: {
    failure: (state, { payload }) => ({
      ...state,
      errorId: state.errorId + 1,
      errorCode: payload.code,
      errorMessage: payload.message,
    }),
    resetState: () => initialState,
  },
});

export default authSlice;
