import React, { useState, useEffect } from 'react';
import { Header, Button, Form, Grid } from 'semantic-ui-react';
import trim from 'lodash/trim';
import endOfMonth from 'date-fns/endOfMonth';
import { useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import DateTimePicker from '../../components/DateTimePicker';
import FormRateList from '../FormRateList/FormRateList';
import saveTypes from '../../constants/saveTypes';
import Page404 from '../../components/Page404';
import FormTypeSelect from '../../components/FormTypeSelect';
import FormFeeList from '../FormFeeList/FormFeeList';
import CryptoFormList from '../CryptoFormList/CryptoFormList';
import { FormType } from '../../types/enums/FormType';

function CollectionEditForm(props) {
  const { header, collection, onChange, onSubmit } = props;

  function onBlurInput(event) {
    const { name, value } = event.target;
    onChange({ [name]: trim(value) });
  }

  function onChangeDateTime(inputProps) {
    const { name, value } = inputProps;
    onChange({ [name]: value });
  }

  function onChangeInput(event, inputProps) {
    const { name, value } = inputProps;
    onChange({ [name]: value });
  }

  function canBeSubmit() {
    const requiredFields = ['name', 'formType', 'startAt', 'endAt'];
    for (const fieldName of requiredFields) {
      const fieldValue = collection[fieldName];
      if (!fieldValue || !fieldValue.length || trim(fieldValue).length === 0) {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(saveTypes.APPLY);
        }}
        autoComplete="off"
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">{header}</Header>
              <br />
              <br />
              <Form.Group widths="equal">
                <Form.Input
                  data-qa="collection-name"
                  label="Collection name"
                  name="name"
                  value={collection.name}
                  onChange={onChangeInput}
                  onBlur={onBlurInput}
                  required
                />
                <FormTypeSelect
                  label="Type of forms (can only be set when creating a new collection)"
                  data-qa="formType"
                  name="formType"
                  value={collection.formType}
                  onChange={onChangeInput}
                  required
                  disabled={!!collection.id}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>Start collect at (your local time)</label>
                  <DateTimePicker
                    data-qa="start-date"
                    name="startAt"
                    value={collection.startAt}
                    onChange={onChangeDateTime}
                    maxDate={
                      collection.endAt
                        ? Date.parse(collection.endAt)
                        : undefined
                    }
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Finish collect at (your local time)</label>
                  <DateTimePicker
                    data-qa="end-date"
                    name="endAt"
                    value={collection.endAt}
                    onChange={onChangeDateTime}
                    minDate={
                      collection.startAt
                        ? Date.parse(collection.startAt)
                        : undefined
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="right" className="action-buttons">
              <Button
                type="submit"
                color="blue"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit(saveTypes.APPLY);
                }}
                disabled={!canBeSubmit()}
                data-qa="apply-btn"
              >
                Apply
              </Button>
              <Button
                type="submit"
                color="blue"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit(saveTypes.SAVE);
                }}
                disabled={!canBeSubmit()}
                data-qa="save-btn"
              >
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </Form>
    </div>
  );
}

const COLLECTION_FIELDS = gql`
  fragment CollectionEditFields on Collection {
    id
    name
    formType
    startAt
    endAt
  }
`;

const GET_COLLECTION = gql`
  query GetCollection($collectionId: Int) {
    collection(where: { id: $collectionId }) {
      ...CollectionEditFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const CREATE_COLLECTION = gql`
  mutation CreateCollection(
    $name: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $formType: FormType!
  ) {
    collection: createCollection(
      data: {
        name: $name
        startAt: $startAt
        endAt: $endAt
        formType: $formType
      }
    ) {
      ...CollectionEditFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const UPDATE_COLLECTION = gql`
  mutation UpdateCollection(
    $id: Int!
    $name: String!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    collection: updateCollection(
      data: {
        name: { set: $name }
        startAt: { set: $startAt }
        endAt: { set: $endAt }
      }
      where: { id: $id }
    ) {
      ...CollectionEditFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const CLONE_COLLECTION = gql`
  mutation CloneCollection(
    $id: Int!
    $name: String!
    $startAt: DateTime!
    $endAt: DateTime!
    $formType: FormType!
  ) {
    collection: cloneCollection(
      data: {
        formType: $formType
        name: $name
        startAt: $startAt
        endAt: $endAt
      }
      sourceId: $id
    ) {
      ...CollectionEditFields
    }
  }
  ${COLLECTION_FIELDS}
`;

export function useCollection(collectionId) {
  const [collection, setCollection] = useState({
    name: '',
    startAt: '',
    endAt: '',
  });
  const [fetchCollection, { loading, error }] = useLazyQuery(GET_COLLECTION, {
    onCompleted: (data) => setCollection(data.collection),
  });
  const [createCollection] = useMutation(CREATE_COLLECTION, {
    onError: (error) => undefined,
  });
  const [updateCollection] = useMutation(UPDATE_COLLECTION, {
    onError: (error) => undefined,
  });
  const [cloneCollection] = useMutation(CLONE_COLLECTION, {
    onError: (error) => undefined,
  });
  useEffect(() => {
    if (isFinite(collectionId)) {
      fetchCollection({ variables: { collectionId: parseInt(collectionId) } });
    } else {
      const now = new Date();
      setCollection({
        name: '',
        startAt: now.toISOString(),
        endAt: endOfMonth(now).toISOString(),
      });
    }
  }, [collectionId, fetchCollection]);

  return {
    collection,
    loading,
    error,
    setCollection,
    createCollection,
    updateCollection,
    cloneCollection,
  };
}

export default function CollectionEdit(props) {
  const { collectionId } = useParams();
  const { clone } = props;

  const history = useHistory();
  const {
    collection,
    setCollection,
    loading,
    createCollection,
    updateCollection,
    cloneCollection,
  } = useCollection(collectionId);

  if (loading) return <Loader isLoading={loading} />;
  if (!collection) return <Page404 />;

  let header;
  if (clone) {
    header = `Clone collection #${collection.id}`;
  } else {
    header = collection.id
      ? `Edit collection #${collection.id}`
      : 'New collection';
  }

  function onChange(updated) {
    setCollection((collection) => ({ ...collection, ...updated }));
  }

  async function saveCollection(options) {
    if (clone) {
      return cloneCollection(options);
    }
    const isNew = !isFinite(collectionId);
    return isNew ? createCollection(options) : updateCollection(options);
  }

  async function onSubmit(saveType) {
    const {
      data: {
        collection: { id },
      },
    } = await saveCollection({ variables: collection });
    if (saveType === saveTypes.SAVE) {
      history.push('/collections');
    } else {
      history.replace(`/collections/${id}`);
    }
  }

  return (
    <div>
      <CollectionEditForm
        header={header}
        collection={collection}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      {collection.id && collection.formType === FormType.RATE && (
        <FormRateList collectionId={collection.id} editable={!clone} />
      )}
      {collection.id && collection.formType === FormType.FEE && (
        <FormFeeList collectionId={collection.id} editable={!clone} />
      )}
      {collection.id && collection.formType === FormType.CRYPTO && (
        <CryptoFormList collectionId={collection.id} editable={!clone} />
      )}
    </div>
  );
}
