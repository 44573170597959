import { takeLatest, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import errorHandlerSlice from './slice';

const { actions } = errorHandlerSlice;

function* resetErrorSaga() {
  yield put(actions.resetState());
}

export default function* errorHandlerSaga() {
  yield takeLatest(LOCATION_CHANGE, resetErrorSaga);
}
