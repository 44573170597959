import { Table } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import { createFeeValue, FeeValue } from '../../../types/models/FeeValue';
import { SetState } from '../../../types/utility';
import useUpdate from '../../../hooks/useUpdate';
import useDelete from '../../../hooks/useDelete';
import useCreate from '../../../hooks/useCreate';
import FeeValueItem from './FeeValueItem';
import { ValidationErrors } from '../../../types/models/ValidationErrors';

interface FeeValuesCellProps {
  values: FeeValue[];
  setValues: SetState<FeeValue>;
  defaultCurrencyId: number;
  readOnly?: boolean;
  validationErrors?: ValidationErrors;
}

export default function FeeValuesCell({
  values,
  setValues,
  defaultCurrencyId,
  readOnly = false,
  validationErrors = {},
}: FeeValuesCellProps): JSX.Element {
  const updateValues = useUpdate<FeeValue>(setValues);
  const deleteValue = useDelete<FeeValue>(setValues);
  const createValue = useCreate<FeeValue>(setValues);

  const onAddValue = useCallback(() => {
    const feeValue = createFeeValue({ currencyId: defaultCurrencyId });
    createValue(feeValue);
  }, [createValue, defaultCurrencyId]);

  return (
    <Table.Cell className="form_fee_collect__fee_value_cell">
      {values.map((value, index) => (
        <FeeValueItem
          key={value.id}
          value={value}
          onChange={updateValues}
          onDelete={deleteValue}
          readOnly={readOnly}
          validationErrors={
            validationErrors[index] as ValidationErrors | undefined
          }
        />
      ))}
      {!readOnly && (
        <div>
          <a className="form_fee_collect__fee_value_add" onClick={onAddValue}>
            + Add additional fee
          </a>
        </div>
      )}
    </Table.Cell>
  );
}
