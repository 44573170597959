import isEmpty from 'lodash/isEmpty';
import { FeeValue, validateFeeValue } from './FeeValue';
import { ValidationErrors } from './ValidationErrors';
import { toNumber } from '../../utils/number';
import { AmountType } from '../enums/AmountType';

export interface CryptoBuySellRange {
  id: number;
  amountFrom: string;
  amountTo: string;
  rate: string;
  fees: FeeValue[];
  editedAt: Date;
}

let ID_SEQUENCE = 0;
export function createCryptoBuySellRange({
  amountFrom = '',
  amountTo = '',
  rate = '',
  fees = [],
  editedAt = new Date(),
}: Partial<CryptoBuySellRange> = {}): CryptoBuySellRange {
  ID_SEQUENCE -= 1;
  return {
    amountFrom,
    amountTo,
    rate,
    fees,
    editedAt,
    id: ID_SEQUENCE,
  };
}

export function validateCryptoBuySellRange(
  range: CryptoBuySellRange,
  currencyId: number | undefined,
  typeAmounts: keyof typeof AmountType,
): ValidationErrors {
  const amountFrom = toNumber(range.amountFrom, 0);
  const amountTo = toNumber(range.amountTo, Infinity);
  const errors: ValidationErrors = {};
  if (amountFrom < 0) {
    errors.amountFrom = 'The value must be greater than or equal to zero';
  }
  if (typeAmounts === AmountType.RANGE) {
    if (amountTo <= 0) {
      errors.amountTo = 'The value must be greater than zero';
    }
    if (amountFrom >= amountTo) {
      errors.amountTo = 'The value must be greater than Amount From';
    }
  }

  const feesErrors: ValidationErrors = {};
  const feesCanHaveZeroValue = range.fees.length === 1;
  range.fees.forEach((value, index) => {
    const err = validateFeeValue(
      value,
      feesCanHaveZeroValue,
      currencyId,
      amountTo,
    );
    if (!isEmpty(err)) {
      feesErrors[index] = err;
    }
  });
  if (!isEmpty(feesErrors)) {
    errors.feesErrors = feesErrors;
  }

  return errors;
}
