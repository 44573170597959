import { StrictDropdownItemProps } from 'semantic-ui-react';
import { titleCase } from './string';

export function enumToSelectOptions(
  sourceEnum: { [s: string]: string },
  {
    labels = {},
    toTitleCase = true,
  }: {
    labels?: { [s: string]: string };
    toTitleCase?: boolean;
  } = {},
): StrictDropdownItemProps[] {
  return Object.entries(sourceEnum).map(([key, value]) => ({
    key,
    value,
    text: labels[key] ?? (toTitleCase ? titleCase(key) : key),
    'data-qa': value,
  }));
}
