import React from 'react';
import { Form, SelectProps } from 'semantic-ui-react';
import { InOutDirection } from '../types/enums/InOutDirection';
import { enumToSelectOptions } from '../utils/components';

interface InOutDirectionSelectProps extends Omit<SelectProps, 'options'> {
  value?: keyof typeof InOutDirection | (keyof typeof InOutDirection)[];
}

const displayName = 'InOutDirectionSelect';
const options = enumToSelectOptions(InOutDirection);
export default Object.assign(
  React.memo((props: InOutDirectionSelectProps) => (
    <Form.Select options={options} {...props} />
  )),
  { displayName },
);
