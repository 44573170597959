import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import PROVIDED_RATE from '../../../constants/providedRate';

export default class PortalExampleControlled extends Component {
  handleSelect = (event) => {
    const { value } = event.target;
    const { name, onSelect, onClose } = this.props;
    onSelect(event, { name, value });
    onClose();
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Modal
        onClose={onClose}
        open={open}
        closeOnDocumentClick={false}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        data-qa="modal-dialog"
      >
        <Modal.Header>Rate or a Received Amount?</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Please confirm if you entered Rate or a Received Amount</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Rate"
            value={PROVIDED_RATE.DIRECT}
            onClick={this.handleSelect}
            data-qa="rate-type"
          />
          <Button
            content="Received Amount"
            value={PROVIDED_RATE.FROM_RECEIVED_AMOUNT}
            onClick={this.handleSelect}
            data-qa="amount-type"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
