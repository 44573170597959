import { gql } from '@apollo/client';

const FORM_FEE_FIELDS = gql`
  fragment CryptoFormEditFields on CryptoForm {
    id
    slug
    countryId
    collectionId
    organizationId
    researcherName
    clientType
    submittedAt
    transferDirection
    amounts
    organization {
      homeCountryId
    }
  }
`;

export const GET_FORM = gql`
  query GetCryptoForm($formId: Int) {
    form: cryptoForm(where: { id: $formId }) {
      ...CryptoFormEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;

export const CREATE_FORM = gql`
  mutation CreateCryptoForm(
    $slug: String!
    $countryId: Int
    $collectionId: Int!
    $organizationId: Int!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
    $amounts: [Float!]!
  ) {
    form: createCryptoForm(
      data: {
        slug: $slug
        countryId: $countryId
        collection: { connect: { id: $collectionId } }
        organizationId: $organizationId
        researcherName: $researcherName
        clientType: $clientType
        transferDirection: $transferDirection
        amounts: { set: $amounts }
      }
    ) {
      ...CryptoFormEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;

export const UPDATE_FORM = gql`
  mutation UpdateCryptoForm(
    $id: Int!
    $slug: String!
    $countryId: Int
    $collectionId: Int!
    $organizationId: Int!
    $researcherName: String!
    $clientType: ClientType!
    $transferDirection: TransferDirection!
    $amounts: [Float!]!
  ) {
    form: updateCryptoForm(
      data: {
        slug: { set: $slug }
        countryId: { set: $countryId }
        collection: { connect: { id: $collectionId } }
        organizationId: { set: $organizationId }
        researcherName: { set: $researcherName }
        clientType: { set: $clientType }
        transferDirection: { set: $transferDirection }
        amounts: { set: $amounts }
      }
      where: { id: $id }
    ) {
      ...CryptoFormEditFields
    }
  }
  ${FORM_FEE_FIELDS}
`;
