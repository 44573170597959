import React, { useCallback, useMemo } from 'react';
import trim from 'lodash/trim';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import copy from 'copy-to-clipboard';
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Label,
  Message,
} from 'semantic-ui-react';
import { collectFeeBaseUrl } from '../../constants/collectBaseUrl';
import saveTypes from '../../constants/saveTypes';
import CollectionSelect from '../../components/CollectionSelect';
import ClientTypeSelect from '../../components/ClientTypeSelect';
import OrganizationSelect from '../../components/OrganizationSelect';
import CountrySelect from '../../components/CountrySelect';
import TransferDirectionSelect from '../../components/TransferDirectionSelect';
import { FormType } from '../../types/enums/FormType';

export default function FormFeeEditForm(props) {
  const { header, data, onChange, onSubmit, changeSlug } = props;
  const hasSubmittedAlready = Boolean(data.submittedAt);
  const canBeSubmit = useMemo(() => {
    if (hasSubmittedAlready) {
      return false;
    }
    const requiredFields = [
      'collectionId',
      'researcherName',
      'clientType',
      'organizationId',
      'slug',
      'transferDirection',
    ];
    for (const fieldName of requiredFields) {
      const fieldValue = data[fieldName];
      if (!fieldValue) {
        return false;
      }
      if (typeof fieldValue === 'string' && trim(fieldValue).length === 0) {
        return false;
      }
      if (isArrayLike(fieldValue) && isEmpty(fieldValue)) {
        return false;
      }
    }
    return true;
  }, [data]);

  const onBlurInput = useCallback(
    (event) => {
      const { name, value } = event.target;
      onChange({ [name]: trim(value) });
    },
    [onChange],
  );

  const onChangeInput = useCallback(
    (event, inputProps) => {
      const { name, value } = inputProps;
      const changed = { [name]: value };
      if (name === 'organizationId') {
        changed.countryId = inputProps.homeCountryId;
      }
      onChange(changed);
    },
    [onChange],
  );

  const onGenerateSlug = useCallback(() => {
    if (hasSubmittedAlready) {
      return;
    }
    if (window.confirm('Do you want to generate a new URL for this form?')) {
      changeSlug();
    }
  }, [hasSubmittedAlready, changeSlug]);

  const copyLinkToClipboard = useCallback(() => {
    const fullUrl = collectFeeBaseUrl + data.slug;
    copy(fullUrl);
  }, [data.slug]);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(saveTypes.SAVE);
    },
    [onSubmit],
  );

  const onApply = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(saveTypes.APPLY);
    },
    [onSubmit],
  );

  return (
    <Form onSubmit={onApply} autoComplete="off">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">{header}</Header>
            <Form.Group widths="2">
              <CollectionSelect
                data-qa="collection-name"
                name="collectionId"
                value={data.collectionId}
                formType={FormType.FEE}
                onChange={onChangeInput}
                required
                search
                style={useMemo(() => ({ width: 'auto' }), [])}
              />
            </Form.Group>
            <br />
            {hasSubmittedAlready && (
              <>
                <Message
                  info
                  icon="inbox"
                  header="This form has now been submitted"
                  content="Changes are no longer possible."
                />
                <br />
              </>
            )}
            <Form.Group widths="2">
              <Form.Input
                data-qa="researcher-name"
                label="Researcher name"
                name="researcherName"
                value={data.researcherName}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                required
              />
              <ClientTypeSelect
                data-qa="client_type"
                label="Client type"
                name="clientType"
                value={data.clientType}
                onChange={onChangeInput}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Bank Name</label>
                <OrganizationSelect
                  data-qa="bank_name"
                  name="organizationId"
                  value={data.organizationId}
                  onChange={onChangeInput}
                />
              </Form.Field>
              <CountrySelect
                data-qa="country"
                label="Country"
                name="countryId"
                value={data.countryId}
                onChange={onChangeInput}
                clearable
              />
            </Form.Group>
            <Form.Group widths="2">
              <TransferDirectionSelect
                label="Sending or Receiving Fees"
                name="transferDirection"
                value={data.transferDirection}
                onChange={onChangeInput}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>URL</label>
                <Input
                  type="text"
                  value={data.slug}
                  action
                  labelPosition="left"
                  required
                >
                  <Label data-qa="url_label">{collectFeeBaseUrl}</Label>
                  <input data-qa="url_input" />
                  <Button
                    type="button"
                    color="red"
                    onClick={onGenerateSlug}
                    data-qa="gen-new-link"
                  >
                    Generate new{' '}
                  </Button>
                  <Button
                    type="button"
                    onClick={copyLinkToClipboard}
                    data-qa="copy-link"
                  >
                    Copy link
                  </Button>
                </Input>
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right" className="action-buttons">
            <Button
              type="submit"
              color="blue"
              onClick={onApply}
              disabled={!canBeSubmit}
              data-qa="apply-btn"
            >
              Apply
            </Button>
            <Button
              type="submit"
              color="blue"
              onClick={onSave}
              disabled={!canBeSubmit}
              data-qa="save-btn"
            >
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}
