import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { Form } from 'semantic-ui-react';

export default function MoneyInput(props) {
  const {
    name,
    value,
    placeholder,
    onChange,
    readOnly = true,
    ...inputProps
  } = props;

  const onChangeAmount = useCallback(
    (values) => {
      // eslint-disable-next-line prefer-const
      const { value } = values;
      onChange(null, { name, value });
    },
    [name, onChange],
  );

  return (
    <Form.Input {...inputProps}>
      <NumberFormat
        thousandSeparator=" "
        placeholder={placeholder}
        name={name}
        value={value}
        onValueChange={onChangeAmount}
        readOnly={readOnly}
      />
    </Form.Input>
  );
}
